@import "./fonts.scss";
@import "greenview-base-color";

// Bug on upgrade:
// $theme-colors doesn't inherit the default, so defining some terms without being exhaustive would
// clear the other default, e.g. defining primary only would clear secondary, success, etc. styling
$theme-colors: (
        "primary": $blue,
        "secondary": $secondary,
        "success": $success,
        "info": $info,
        "warning": $warning,
        "danger": $danger,
        "light": $light,
        "dark": $dark,
        "greenview": $green,
        "topnav-background": $topnav-background,
);

$grid-breakpoints: (
        xs: 0,
        sm: 576px,
        md: 720px,
        lg: 992px,
        xl: 1280px,
        xxl: 1440px
);
$spacer: 16px;

$topnav-height: 48px;
$offcanvas-horizontal-width: 700px;
$search-box-width: 300px;
$form-control-height: calc(
        1.5em + 0.75rem + 2px
); // using react bootstrap .form-control height

@import "~react-datasheet/lib/react-datasheet.css";

@import "~bootstrap/scss/bootstrap";

@import "./greenview-base-design.scss";

/* Add customisation on top of bootstrap after this line */

/* Breakpoints */
$breakpoint-xl: 1400px;
$breakpoint-l: 1200px;
$breakpoint-m: 991px;
/* End of Breakpoints */

/* Flex */
@mixin flex-fill {
  flex: 1 1 auto;
}

.flex-spacer {
  @include flex-fill;
}

/* End of Flex */

/* Card */
@mixin gv-card {
  border-radius: 4px;
  padding: 16px;
}

/* End of Card */

/* Form */

form input,
form textarea,
form select,
form .react-select {
  margin-bottom: 16px;
}

.form-row-spacer {
  margin-top: 16px;
}

input.form-control,
textarea.form-control,
select.form-select,
select.custom-select,
div.react-select__control {
  background-color: $gray-98;
  border-color: $gray-93;
}

input.form-control.warning {
  border: 1px solid $yellow;
}

.comment-tooltip-container-on-form-row {
  display: flex;
  align-items: center;
  justify-content: center;
  line-height: 1.5;
  padding: 0.375em 0;
  font-size: 1rem;
}

.form-group {
  margin-bottom: 24px;

  td & {
    margin-bottom: 0;
  }
}

.manual-warning-feedback {
  @extend .invalid-feedback;
  display: block;
  color: $dark-yellow;
}

.invalid-feedback {
  font-weight: 400;
  font-size: 12px;
  margin-top: 0.25rem;

  form & {
    margin-top: -0.75rem;
  }
}

.manual-invalid-feedback {
  @extend .invalid-feedback;
  display: block;

  &.checkbox-margin {
    margin-left: 1.7em;
  }
}

.form-select-styling {
  height: $form-control-height;
  margin-bottom: 16px;
  display: flex;
  align-items: center;
}

select:disabled {
  -moz-appearance: none;
  -webkit-appearance: none;
}

.react-select__control,
.react-select__value-container,
.react-select__indicators {
  height: $form-control-height;
}

.form-check-input[disabled] ~ .form-check-label,
.form-check-input:disabled ~ .form-check-label {
  color: $black;
}

.was-validated select.form-control:invalid,
select.form-control.is-invalid {
  background-image: none;
}

.advanced-or-and-filter__option-container {
  display: flex;
  flex-direction: row;
  margin-top: 10px;
  margin-bottom: 10px;
}

/* End of Form */

.navbar {
  padding: 0;
  align-items: stretch;
  height: $topnav-height;
  min-height: $topnav-height;

  .dropdown-menu {
    z-index: 2000;
  }
}

.nav-item {
  display: flex;
  align-items: center;
}

.label {
  border-radius: 2px;
  padding: 0.1rem 0.25rem;
  @extend .caption;
  font-weight: 700;
  text-transform: uppercase;
}

.red-label {
  color: $red;
  background-color: $light-red;
  @extend .label;
}

.green-label {
  background-color: $light-green;
  @extend .label;
}

.yellow-label {
  background-color: $light-yellow;
  @extend .label;
}

.blue-label {
  background-color: $light-blue;
  color: $blue;
  @extend .label;
}

.gray-label {
  background-color: $gray-93;
  color: $gray-56;
  @extend .label;
}


hr.thick-line {
  height: 3px;
  margin: 32px 16px;
}

.page-content {
  display: flex;
  flex-grow: 1;
  width: 100%;
}

.navbar-dark .top-nav-item {
  height: 100%;

  @media screen and (max-width: 991px) {
    height: fit-content;
  }

  .top-nav-icon-button {
    font-size: 32px;
  }

  .top-nav-help-button {
    font-size: 18px;
  }

  .nav-link {
    color: $white;
    font-size: 16px;
    margin: 0 4px;
    padding: 0 12px;
    display: flex;
    align-items: center;

    &.active {
      color: $green;

      -webkit-box-shadow: inset 0px -8px 0px -2px $topnav-active;
      -moz-box-shadow: inset 0px -8px 0px -2px $topnav-active;
      box-shadow: inset 0px -8px 0px -2px $topnav-active;

      @media screen and (max-width: 991px) {
        -webkit-box-shadow: inset 0px -4px 0px -2px $topnav-active;
        -moz-box-shadow: inset 0px -4px 0px -2px $topnav-active;
        box-shadow: inset 0px -8px 0px -2px $topnav-active;
      }
    }

    &:hover {
      background: lighten($topnav-background, 10%);
      color: $white;
      text-decoration: none;
    }

    &:focus {
      color: $white;
    }

    @media screen and (max-width: 1400px) {
      margin: 0;
      padding: 8px 6px 8px 6px;
    }

    @media screen and (max-width: 1200px) {
      font-size: 14px;
    }

    @media screen and (max-width: 991px) {
      background: $topnav-background;
      z-index: 1000;
      font-size: 16px;
      padding: 7px 7px 7px 7px;
    }
  }

  .profile-dropdown-toggle {
    margin: 0 10px 0 0;
    border: 0;
    background: transparent;
    box-shadow: none;
    height: 100%;

    @media screen and (max-width: 991px) {
      padding-right: 7px;
    }
  }

  .profile-circle__button {
    border: 2px solid white;
    background-color: #FFFFFF;
    height: 32px;
    border-radius: 50%;
    width: 32px;
    font-weight: 700;
    font-size: 12.68px;
    text-align: center;
    align-items: center;
    display: flex;
    justify-content: center;

    &:hover {
      border-color: $white;
    }

    &.red {
      background-color: $red;
      color: $white;
    }

    &.yellow {
      background-color: $yellow;
      color: $black;
    }

    &.green {
      background-color: $green;
      color: $black;
    }

    &.light-blue {
      background-color: $sky-blue;
      color: $black;
    }

    &.dark-blue {
      background-color: $dark-blue;
      color: $white;
    }

    &.purple {
      background-color: $purple;
      color: $white;
    }

    &.gray {
      background-color: $gray-36;
      color: $white;
    }

    &.light-green {
      background-color: $light-green;
      color: $white;
    }
  }

  .system-toolbox__button {
    width: 32px;
    height: 32px;
    padding: 0px;
    border-radius: 16px;
    font-weight: 700;
    font-size: 12.68px;
    text-align: center;
    background-color: $white;
    border-color: $white;
    align-items: center;
    display: flex;
    justify-content: center;

    &:hover {
      border-color: $white;
    }

    &.active {
      background-color: $light-green;
      border-color: $light-green;
    }

    .icon {
      font-size: 16px;
      color: $black;

      @media screen and (max-width: 991px) {
        font-size: 16px;
      }

      &.active {
        color: $green;
      }
    }
  }
}

.navbar-dark .navbar-toggler {
  border: none;
  box-shadow: none;
}

.navbar-dark .dropdown {
  @media screen and (max-width: 991px) {
    height: 100%;
  }
}

.navbar .nav-margin-right {
  @media screen and (max-width: 1400px) {
    margin-right: ($spacer * 0.5);
  }

  @media screen and (max-width: 991px) {
    margin-right: 0;
  }
}

.navbar .dropdown-item {
  color: $blue;
}

$side-nav-width: 180px;
.side-nav {
  flex-grow: 0;
  flex-shrink: 1;
  min-width: $side-nav-width;
  background: $white;

  .nav-link {
    font-size: 14px;
    line-height: 14px;
    color: $black;
    padding: 15px 25px;
    border-left: 5px solid transparent;

    &.active {
      font-size: 14px;
      line-height: 14px;
      font-weight: 700;
      background: $sidenav-background;
      border-left: 5px solid $sidenav-active;
    }

    &:hover {
      background: darken($sidenav-background, 10%);
    }
  }
}

.loading-spinner {
  width: 100px;
  height: 100px;
}

.center-content {
  height: 100%;
  width: 100%;

  display: flex;
  align-items: center;
  justify-content: center;

  &.left-aligned {
    justify-content: start;
  }
}

.unstyled-link {
  color: inherit !important;
  text-decoration: none;

  &:hover {
    text-decoration: none;
  }
}

.unstyled-btn {
  border: none;
  background: transparent;
  padding: 0;

  &:hover {
    background: $gray-90;
    border-radius: 5px;
  }
}

.unicode-icon {
  font-style: normal;
  font-weight: 400;
  font-size: 17px;
  line-height: 23px;
  letter-spacing: 0.01em;
}

.item-type-badge-main {
  margin: auto 12px;
  font-size: 0.6em;
}

.item-type-badge-list {
  margin: auto 12px;
}

.right-align-button {
  flex: 1;
  align-items: flex-end;
  text-align: right;
  padding: 10px 0;
}

.info-field {
  margin-bottom: 10px;
  font-size: 1.2em;
  font-weight: 600;
}

.body-1 {
  font-size: 14px;
  font-weight: 400;
}

.body-2 {
  line-height: 19px;
  font-size: 16px;
}

.body-2-bold {
  @extend .body-2;
  font-weight: 700;
}

.body-2-extra-bold {
  @extend .body-2;
  font-weight: 800;
  line-height: 21px;
}

.body-1-bold {
  @extend .body-1;
  font-weight: 700;
}

.body-1-extra-bold {
  @extend .body-1;
  font-weight: 800;
}

.caption {
  font-size: 13px;
  line-height: 19px;
  letter-spacing: 0.01em;
}

.caption-bold {
  @extend .caption;
  font-weight: bold;
}

.createCompanyName {
  width: 25%;
  position: fixed;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
}

.company-option__selected,
.user-option__selected,
.country-option__selected,
.metroArea-option__selected {
  border: 2px solid #21b891;
  border-radius: 8px;
  padding: 5px;
  text-align: center;
}

input.form-check-input:disabled:not(:checked) {
  background-color: $gray-56;
  border-color: $gray-56;
}

.checkbox-large {
  align-items: center;
  font-size: 16px;
  margin-bottom: 10px;
  line-height: 24px;

  .form-check-label::before,
  .form-check-label::after {
    top: 20%;
    width: 1em;
    height: 1em;
  }

  .form-check-label {
    line-height: normal;
  }

  input[type="checkbox"].form-check-input:checked:disabled {
    background-color: $gray-56;
    border-color: $gray-56;
  }

  input.form-check-input {
    margin-right: 8px;
  }
}

.badge {
  display: inline-block;
  background-color: $gray-36;
  border-radius: 4px;
  font-size: 12px;
  line-height: 19px;
  padding: 3px 6px;
  margin: 2px;
}

.badge[variant="primary"] {
  background-color: $gray-36 !important;
  color: $white;
}

.badge[variant="success"] {
  background-color: $green !important;
}

.badge[variant="danger"] {
  background-color: $red !important;
}

.link-danger {
  @extend .color-red;

  &:hover,
  &:focus,
  &:active {
    @extend .color-red;
  }

  &:focus {
    box-shadow: 0 0 0 0.2rem adjust-color($danger, $alpha: -0.75);
  }
}

.link-secondary {
  @extend .color-gray56;

  &:hover,
  &:focus,
  &:active {
    @extend .color-gray56;
  }

  &:focus {
    box-shadow: 0 0 0 0.2rem adjust-color($gray-56, $alpha: -0.75);
  }
}


/* SystemToolbox */
.subportfolio-survey-data-request__container {
  margin-top: 16px;
  margin-bottom: 16px;
  padding: 6px 24px;
  border: 1px solid $gray-93;
  max-height: 180px;
  overflow-y: scroll;
}

table.operational-meter-table {
  tr td {
    vertical-align: middle;
  }

  td.operational-meter-table__meter-name {
    span.caption {
      color: $gray-56;
    }
  }
}

/* Environmental Data Meter Details */
.meter-details__data_gaps {
  margin-bottom: 12px;

  svg path {
    fill: $gray-56;
  }

  .data-gap-bullet {
    .gvds-icon-container, svg {
      $icon-size: 8px;
      height: $icon-size;
      max-height: $icon-size;
      width: $icon-size;
      max-width: $icon-size;
    }
  }
}

.meter-small-btn {
  margin-top: -2px;
}

.update-meter-alerts {
  display: flex;
  flex-direction: row;
  align-items: center;
}

.meter-details__data_requests {
  svg path {
    fill: $red;
  }
}

/* Pagination */
.pagination-control-button {
  background-color: $gray-93;
  width: 24px;
  height: 24px;
  margin: 0.2rem;
  border-radius: 3px;
  display: flex;
  align-items: center;
  justify-content: center;

  &.active {
    background-color: $dark-gray;
    cursor: pointer;
  }

  svg path {
    fill: white;
  }
}

/* Mini Multi Select */
.mini-multi-select__trigger {
  color: black;
  background-color: $gray-98;
  cursor: pointer;
  display: inline-block;
  padding: 0.5rem;
  border-radius: 3px;
  border: 1px solid $gray-93;
  margin-right: 5px;
  white-space: nowrap;

  .mini-multi-select__number_of_selected {
    background-color: $blue;
    padding: 0.2rem 0.5rem;
    border-radius: 1rem;
    color: white;
    text-align: center;
    @extend .caption;
    font-weight: 700;
  }
}

.mini-multi-select {
  .mini-multi-select__option {
    color: black;
    background-color: white;
  }

  .mini-multi-select__menu {
    margin: 0 12px 10px 12px;
    padding-top: 10px;
  }
}

/* Month Range Selector */
.month-range-selector {
  .react-datepicker__input-container input {
    margin-right: 10px;
    width: 7.5rem;
    padding: 0.5rem;
  }

  .react-datepicker__input-container:after {
    margin-left: -2rem;
  }
}

.button-area {
  float: right;
}

.option-box {
  width: 50%;
}

.option-edit {
  margin-left: 1.1em;
}

.reference-data-table {
  border-collapse: collapse;
  width: 100%;
  table-layout: fixed;
}

.reference-data-table-entry {
  font-size: 14px;
}

.td-level {
  // border: 1px solid #dddddd;
  text-align: left;
  padding: 8px;
  font-size: 16px;
}

.th-level {
  border: 1px solid #dddddd;
  text-align: left;
  width: 100%;
  font-weight: 400;
  padding: 00.5em !important;
}

.list-category-table {
  font-family: arial, sans-serif;
  border-collapse: collapse;
  border: 1px solid #dddddd;
}

.cancel-option {
  margin-right: 3px;
}

/* missing data */
.missing-data {
  color: $red;
}

/* SMS */
.info-chapter-editor {
  max-width: 800px;

  .rtf-wrapper {
    margin-top: 0.5rem;
  }

  .rdw-editor-main {
    height: calc(100vh - 400px);
    margin-bottom: 0.5rem;
  }
}

.sms-action-details-overview-container {
  .sms-action-details-overview__outcomes {
    .sms-action-details-overview__outcome {
      margin-bottom: 0.5rem;

      input {
        margin-bottom: 0;
      }
    }
  }

  .sms-action-details-overview__steps-to-take-editor {
    height: 280px;

    .rdw-editor-wrapper {
      height: 80%;
    }
  }
}

.sms-action-details__move-modal-body {
  .custom-control {
    z-index: 0;
  }
}

/* End of SMS */

.yellow-exclamation-triangle {
  path {
    fill: $yellow;
  }
}

/* Risk Assessment Policy Mastersheet */
.policy-mastersheet-table {
  vertical-align: middle;

  .row-column {
    width: 60px;
  }

  .filename-column {
    min-width: 150px;
  }

  .uploaded-by-column {
    width: 200px;
  }

  .action-column {
    width: 200px;
  }
}

/* End of Risk Assessment Policy Mastersheet*/

/* User Admin */
.admin-team-detail__text {
  @include gv-text-14;
  margin-bottom: 1.5rem;
}

/* Site Subscription */

.site-subscription__site_features {
  .form-check {
    margin-left: 0.25em;
    margin-bottom: 0.25em;
  }
}

/* End of Site Subcription */

/* Contract Subscription */
.input-contract-subscription__main-container {
  display: flex;
  flex-direction: column;
  height: 100%;

  .input-contract-subscription__site-selection {
    .contract-term-summary__container {
      padding-bottom: 40px;

      .contract-term-summary__details {
        padding: 16px 24px;
        @include gv-text-16;

        .blank-comment {
          color: $gray-56;
        }
      }
    }

    .site-selection__container {
      .selected-sites__no-content {
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;
        @include gv-body-1;
        width: 100%;
        margin-top: 16px;

        .title {
          text-align: center;
          margin-top: 11px;
          @include gv-heading-3;
        }

        .sub-title {
          text-align: center;
          @include gv-text-14;
          color: $dark-gray;
          margin-top: 8px;
        }

        .selected-sites__no-content-icon {
          font-size: 64px;
          color: $gray-85;
        }
      }

      .filter-button {
        display: flex;
        flex-direction: row;
        align-items: center;
        margin-left: 8px;
      }

      .action-divider {
        border-left: 1px solid $gray-85;
        height: 24px;
        margin-left: 12px;
        margin-right: 12px;
      }

      .input-global-value {
        width: 97px;
        margin-right: 8px;
      }

      .site-subscription-table {
        margin-top: 12px;

        th {
          position: sticky;
          top: 0;
          z-index: 99;

          background-color: $gray-98;
          border: 1px solid $gray-93;
        }

        td {
          border: 1px solid $gray-93;
        }

        .row-column {
          width: 60px;

          div {
            display: flex;
            flex-direction: row;
            align-items: center;
          }
        }

        .site-name-column {
          width: 250px;
        }

        .payment-status-column {
          width: 150px;
        }

        .start-period-column {
          width: 180px;
        }

        .end-period-column {
          width: 180px;
        }

        .features-column {
          width: 180px;
        }

        .price-amount-column {
          width: 150px;

          .price-amount__container {
            display: flex;
            flex-direction: row;
            align-items: center;
          }
        }

        .action-buttons-column {
          width: 50px;
          text-align: center;
        }

        .different-subscription-cell {
          background-color: $light-yellow;
        }

        .deviant-icon {
          color: $yellow;
        }

        .follows-contract-term-cell {
          &:hover .follows-contract-term-button {
            visibility: visible;
          }
        }

        .follows-contract-term {
          display: flex;
          flex-direction: row;
          align-items: center;
          justify-content: space-between;

          .follows-contract-term-label {
            @include gv-text-12;

            color: $gray-56;
            margin-right: 8px;
          }

          .follows-contract-term-button {
            margin-right: 0;
            visibility: hidden;
          }
        }

        .unlink-contract-term {
          display: flex;
          flex-direction: row;
          align-items: center;

          .subscription-start-period-input {
            @include gv-text-14;
          }

          .subscription-end-period-input {
            @include gv-text-14;
          }

          .unlink-contract-term-button {
            margin-left: 4px;
            visibility: hidden;
          }

          &:hover .unlink-contract-term-button {
            visibility: visible;
          }
        }
      }
    }
  }
}

.contract-subscription__filter-column-table {
  @include gv-body-2;
  font-weight: 400;

  thead th {
    padding-bottom: 8px;
  }

  .label-column {
    width: 200px;
  }

  .value-column {
    width: 400px;
  }
}

.contract-subscription__select-site__modal-body {
  .contract-subscription__select-site__instruction-steps {
    li {
      margin-bottom: 8px;
    }
  }

  .reload-all-sites {
    margin-bottom: 4px;

    display: flex;
    align-items: center;
    justify-content: end;

    @include gv-text-12;
    color: $gray-56;
    font-weight: bold;
  }
}

.contract-subscription__bulk-select-sites__container {
  .contract-subscription__bulk-select-sites__instruction-container {
    @include gv-text-16;

    margin-bottom: 24px;
  }

  .contract-subscription__bulk-select-sites__instruction-steps {
    li {
      margin-bottom: 8px;
    }
  }

  .contract-subscription__bulk-select-sites__table {

    .contract-subscription__bulk-select-sites__datasheet-table {
      background: $gray-98;
      width: 100%;
      min-height: 10rem;
      overflow: auto;
      margin-bottom: 1rem;
      border: 1px solid $gray-93;
      height: 100%;

      table {
        width: 100%;

        thead th {
          position: sticky;
          top: 0;
          padding-left: 3px;
        }

        tbody td {
          padding-left: 3px;
        }

        td.cell.read-only,
        th.cell.read-only {
          background-color: $gray-98;
          color: $gray-56;
        }
      }

      .data-input-cell-data-error {
        border: 1px solid $red;
        background-color: $light-red;
        height: 100%;
      }
    }

    .data-grid-container .data-grid .cell > input {
      width: 100%;
      height: 100%;
      text-align: start;
    }

    .data-grid-container .data-grid .cell {
      background: white;
      color: black;
      text-align: start;
      vertical-align: middle;
      height: 30px;
    }

    .data-grid-container .data-grid .cell.editing {
      width: inherit;

      > div.react-datepicker-wrapper,
      > div > div.react-datepicker__input-container,
      > div > div.react-datepicker__input-container input {
        width: 99%;
      }
    }
  }
}

.contract-subscription__contract-features {
  .form-check {
    margin-left: 0.25em;
    margin-bottom: 0.25em;
  }
}

/* End of Contract Subscription */

/* System Toolbox Contracts */
.all-contracts-table {
  .col-index {
    width: 60px;
  }

  .col-name {
    min-width: 200px;
    width: 20%;
  }

  .col-status {
    width: 100px;
  }

  .col-admin {
    width: 150px;
  }

  .col-features {
    width: 200px;
  }

  .col-no-of-sites {
    width: 120px;
  }

  .col-end-date {
    width: 120px;
  }

  .col-comment {
    width: 120px;
  }

  td {
    vertical-align: middle;
  }
}

/* End of System Toolbox Contracts */

/* System Toolbox Sites */

.site-admin-avatar {
  margin-left: -5px;
}

/* End of System Toolbox Sites */

/* Onboarding Tool */
.onboarding-tool__text-blurb-container {
  @include gv-text-16;

  div {
    margin-bottom: 12px;
  }

  ul, ol {
    margin-top: 8px;
  }

  li {
    margin-bottom: 8px;

    ul, ol {
      margin-top: 4px;
      margin-bottom: 4px;
    }

    li {
      margin-bottom: 4px;
    }
  }
}

/* End of Onboarding Tool */

/* Lat-Long Finder */
.lat-long-finder__content-container {
  .action-bar {
    display: flex;
    flex-direction: row;
    align-items: center;
    margin-bottom: 8px;
  }

  .datasheet-table {
    background: $gray-98;
    width: 100%;
    min-height: 10rem;
    overflow: auto;
    margin-bottom: 1rem;
    border: 1px solid $gray-93;
    height: 100%;

    table {
      width: 100%;

      thead th {
        position: sticky;
        top: 0;
        padding-left: 3px;
      }

      tbody td {
        padding-left: 3px;
      }

      td.cell.read-only,
      th.cell.read-only {
        background-color: $gray-98;
        color: $gray-56;
      }
    }

    .action-cell {
      text-align: center;
      cursor: default;

      svg {
        fill: $red;
      }
    }

    .data-input-cell-data-error {
      border: 1px solid $red;
      background-color: $light-red;
      height: 100%;
    }

    .data-input-cell-possible-error {
      border: 1px solid $yellow;
      background-color: $light-yellow;
      height: 100%;
    }
  }

  .data-grid-container .data-grid .cell > input {
    width: 100%;
    height: 100%;
    text-align: start;
  }

  .data-grid-container .data-grid .cell {
    background: white;
    color: black;
    text-align: start;
    vertical-align: middle;
    height: 20px;
  }

  .data-grid-container .data-grid .cell.editing {
    width: inherit;

    > div.react-datepicker-wrapper,
    > div > div.react-datepicker__input-container,
    > div > div.react-datepicker__input-container input {
      width: 99%;
    }
  }
}

/* End of Lat-Long Finder */

// region Annual Survey Banner

.annual-survey-banner__container {
  z-index: 600;
  position: fixed;
  bottom: 16px;
  left: 16px;
}

.annual-survey-banner {
  $padding-size: 24px;

  position: absolute;
  inset: auto auto 0 0;
  max-height: 80vh;

  @include elevation-2;
  background-color: $white;
  padding-top: $padding-size;
  padding-bottom: $padding-size;
  border-radius: 8px;
  width: 400px;

  display: flex;
  flex-direction: column;

  .annual-survey-banner__header {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;

    padding-left: $padding-size;
    padding-right: $padding-size;
    margin-bottom: $padding-size;
  }

  .annual-survey-banner__content {
    padding-left: $padding-size;
    padding-right: $padding-size;
    overflow-y: auto;
  }

  .annual-survey-banner__footer {
    display: flex;
    flex-direction: row;
    padding-left: $padding-size;
    padding-right: $padding-size;
    margin-top: $padding-size;
  }
}

//endregion Annual Survey Banner
