// region Color Palette
$color--gray0: #FFFFFF;
$color--gray2: #F3F5F5;
$color--gray3: #E5EAEA;
$color--gray4: #D1DADA;
$color--gray6: #667879;
$color--gray9: #305050;
$color--gray10: #151D1D;

$gvds-color--tealSolid: #00919A;
$gvds-color--tealLight: #E4EDED;
$gvds-color--tealPale: #E0F2F3;
$gvds-color--tealLinear: linear-gradient(165deg, #2DB0B8 9.18%, #00919A 90.82%);
$gvds-color--alpha: rgba(0, 145, 154, 0.12);

$gvds-color--errorDark: #9C271F;
$gvds-color--error: #E84444;
$gvds-color--errorLight: #FFE9E9;
$gvds-color--warningDark: #724808;
$gvds-color--warning: #FFB906;
$gvds-color--warningLight: #FAF3C3;
$gvds-color--successDark: #05604A;
$gvds-color--success: #1B9376;
$gvds-color--successLight: #E6F7EA;
$gvds-color--infoDark: #043E75;
$gvds-color--info: #1E6FBB;
$gvds-color--infoLight: #BDD9FA;

$gvds-accent-color--purple: #8D5AB5;
$gvds-accent-color--pink: #AF4785;
$gvds-accent-color--navy: #3A3E9B;
$gvds-accent-color--lavender: #C7C3FF;
$gvds-accent-color--celeste: #C3ECE2;
$gvds-accent-color--lime: #D9F1B3;

$gvds-color--data1:#3B3F9E;
$gvds-color--data2:#2CB6A4;
$gvds-color--data3:#7A81FC;
$gvds-color--data4:#FCB446;
$gvds-color--data5:#3E8571;
$gvds-color--data6:#3ECEFF;
$gvds-color--data7:#B24888;
$gvds-color--data8:#A9CC58;
$gvds-color--data9:#8F5BB8;
$gvds-color--data10:#FE6D68;
// endregion

// region Old color variable to be resolved
$color-gray900: #101828;
$color-brand-green-dark: #1D8988;
$color-brand-green-light: $gvds-color--tealLight;
$color-white: #FFFFFF;
$color-disabled: $color--gray6;
$color-blue: rgba(209, 218, 252, 1);

$color-tealUltraLightBg:adjust-color($gvds-color--tealLight, $alpha: -0.8);
// endregion

// region Re-usable variable
$gvds-border-color--default: $color--gray3;
$gvds-border-color--table: $color--gray4;
$gvds-border-color--form: $color--gray4;

$gvds-background-color--success: $gvds-color--successLight;
$gvds-background-color--error: $gvds-color--errorLight;

$accordion-hover-bg: $gvds-color--alpha;

$color-banner-error: $gvds-color--errorLight;
$color-banner-success: $gvds-background-color--success;
$color-banner-warning: $gvds-color--warningLight;
$color-banner-informational: $gvds-color--infoLight;

$color-toast-error: $gvds-background-color--error;
$color-toast-success: $gvds-background-color--success;

$multi-select-bg-disabled: rgba(247, 249, 249, 1);

$link-color: $gvds-color--tealSolid;

$performance-report--light-green: rgba(31, 138, 137, 0.30);
$performance-report--dark-green: #1F8A89;
// endregion

// region Convenient classes
.gvds-color--gray6 {
  color: $color--gray6;
}

.gvds-color--tealSolid {
  color: $gvds-color--tealSolid;
}

.gvds-color--error {
  color: $gvds-color--error;
}

.gvds-color--success {
  color: $gvds-color--success;
}

// endregion
