.dropdown-toggle:after {
  display: none;
}

.dropdown-menu {
  z-index: $gvds-z-index--dropdown;

  border: none;
  border-radius: 4px;
  @include gvds-shadow--large;

  font-size: 14px;

  .dropdown-item {
    padding: $gvds-spacing--100 $gvds-spacing--150;
    display: flex;
    align-items: center;

    &:hover {
      text-decoration: none;
      background-color: $gvds-color--alpha;
    }

    .gvds-icon-container {
      height: 16px;
      min-height: 16px;
      width: 16px;
      min-width: 16px;
      margin-right: $gvds-spacing--75;
    }
  }
}

.other-action-dropdown-toggle {
  margin-left: $gvds-spacing--100;
  margin-right: $gvds-spacing--100;

  &:focus {
    box-shadow: inset 0 0 0 0.25rem rgb(22 103 196 / 25%); // from inspector bootstrap
  }

  + .dropdown-menu.show {
    .dropdown-item.danger {
      color: $red;
    }
  }
}
