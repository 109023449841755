.gvds-filter-button {
  @include gvds-text--inputField;

  border-radius: 8px;
  padding: $gvds-spacing--75 $gvds-spacing--150;
  margin: 0;
  border: 1px solid $color--gray4;
  cursor: pointer;
  background-color: $color--gray0;
  color: $color--gray10;
  user-select: none;

  &:hover {
    background-color: $gvds-color--alpha;
  }

  &.active {
    border: 1px solid $gvds-color--tealSolid;
    color: $gvds-color--tealSolid;
  }

  &.disabled {
    background-color: $color--gray2;
    cursor: default;
  }
}