.popover {
  --bs-popover-arrow-border: #{$gvds-color--tealSolid};

  border-color: $gvds-color--tealSolid;
  box-shadow: 0 0 3px $gvds-color--tealSolid;
  max-width: 400px;
}

.info-tooltip-icon {
  display: inline-flex;
  cursor: pointer;

  .gvds-icon-container {
    height: 14px;
    min-height: 14px;
    width: 14px;
    min-width: 14px;
    position: relative;
  }

  svg {
    height: 24px;
    min-height: 24px;
    width: 24px;
    min-width: 24px;
    position: absolute;
    top: 50%;
    transform: translateY(-50%);
  }
}