$form-search-icon: url('data:image/svg+xml,<svg xmlns="http://www.w3.org/2000/svg" class="icon icon-tabler icon-tabler-search" width="24" height="24" viewBox="0 0 24 24" stroke-width="2" stroke="currentColor" fill="none" stroke-linecap="round" stroke-linejoin="round"><path stroke="none" d="M0 0h24v24H0z" fill="none"/><path d="M10 10m-7 0a7 7 0 1 0 14 0a7 7 0 1 0 -14 0" /><path d="M21 21l-6 -6" /></svg>');
$icon-size: 1.2em;
$gvds-form__icon-field-bg-pos: top calc($gvds-spacing--50 + 0.45em) right calc($gvds-spacing--100);

input.filter-search-box {
  padding-right: calc($gvds-spacing--100 + $icon-size + $gvds-spacing--50);
  background-repeat: no-repeat;
  background-size: $icon-size $icon-size;

  background-position: $gvds-form__icon-field-bg-pos;
  background-image: $form-search-icon;
}