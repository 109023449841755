$pagination-min-margin-left: 80px;

.date-range-container {
  width: 250px;
}

.date-range__layout-container {
  display: flex;
  flex-direction: row;
  align-items: center;
  gap: $gvds-spacing--50;
  margin-left: $gvds-spacing--100;

  .date-range-inline-label {
    @include gvds-text--inputField;
  }
}


// region Container
.gvds-table-ctrl--container {
  display: flex;
  align-items: center;
  margin-bottom: $gvds-spacing--100;
  flex-wrap: wrap;
  row-gap: $gvds-spacing--50;

  & > *:not(:last-child) {
    margin-right: $gvds-spacing--75;
  }
}

// endregion

// region Pagination
.gvds-pagination__layout-container {
  margin-left: auto;
  display: flex;
  flex-direction: row-reverse;
}

.gvds-pagination__container {
  margin-left: 80px;
  display: flex;
  align-items: center;
}

.gvds-pagination-control-count {
  margin-right: $gvds-spacing--100;
  white-space: nowrap;
}

.gvds-pagination-control-button {
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;

  padding: $gvds-spacing--50;
  width: 30px;
  height: 36px;

  &.pagination-left {
    border-top-left-radius: 4px;
    border-bottom-left-radius: 4px;
  }

  &.pagination-right {
    border-top-right-radius: 4px;
    border-bottom-right-radius: 4px;
  }

  &.disabled {
    color: $color--gray4;
  }

  &:hover:not(.disabled) {
    background-color: $gvds-color--alpha;
  }
}

// endregion

// region Search Box
.gvds-table-ctrl--container input.filter-search-box {
  max-width: 300px;
  min-width: 200px;
  padding-right: calc($gvds-spacing--50 + $icon-size + $gvds-spacing--50);
}

// endregion

// region GVDSTableCtrlMultiSelect
.gvds-table-ctrl--multi-select {
  .gvds-table-ctrl--multi-select__trigger {
    cursor: pointer;
    display: flex;
    align-items: center;
    padding: $gvds-spacing--75 $gvds-spacing--50 $gvds-spacing--75 $gvds-spacing--75;
    border-radius: 4px;
    border: 1px solid transparent;

    white-space: nowrap;
    font-size: 14px;
    font-weight: 500;

    &:hover {
      background-color: $gvds-color--alpha;
    }

    &.is-filter-active {
      border: 1px solid $gvds-color--tealSolid;
      color: $gvds-color--tealSolid;
    }
  }

  &.show {
    .gvds-table-ctrl--multi-select__trigger {
      border: 1px solid $gvds-color--tealSolid;
      @include gvds-shadow--active;
    }
  }
}

.gvds-table-ctrl--multi-select__dropdown-menu {
  border: 1px solid $color--gray3;
  padding: 0;
  @include gvds-shadow--large;
}

.gvds-table-ctrl--multi-select__selector {
  .gvds-table-ctrl--multi-select__option {
    background-color: $color-white;
    cursor: pointer;
    text-transform: capitalize;

    &:hover {
      background-color: $gvds-color--alpha;
    }
  }

  .gvds-table-ctrl--multi-select__menu-header {
    margin: 0 12px 10px 12px;
    padding-top: 10px;
  }

  .gvds-table-ctrl--multi-select__menu-selection-ctrl {
    border-bottom: 1px solid $color--gray3;
    padding: $gvds-spacing--50 $gvds-spacing--50 $gvds-spacing--150 $gvds-spacing--150;

    .btn-link {
      font-size: 14px;
      font-weight: 600;
      color: $gvds-color--tealSolid;
    }
  }
}

// endregion

// region Bulk Action Bar
.gvds-table-ctrl--bulk-action-bar__container {
  padding: $gvds-spacing--100 $gvds-spacing--200;
  display: flex;
  align-items: center;
  background-color: $color-white;

  .selected-rows-count {
    margin-right: $gvds-spacing--150;
  }
}

// endregion Bulk Action Bar
