.workspace-info--summary {
  margin-bottom: $gvds-spacing--200;
}

.workspace-info--feature-header {
  margin-bottom: $gvds-spacing--100;

  color: $color--gray9;
  font-size: 14px;
  font-weight: 600;
}

.workspace-info--feature-info {
  margin-bottom: $gvds-spacing--100;
}

.workspace-info--feature-list {
  & > div {
    margin-bottom: $gvds-spacing--50;
  }
}
