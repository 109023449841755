.feature-lock {
  @include gvds-empty-content;

  min-height: 10rem;
  max-height: 80%;
  padding: $gvds-spacing--200;
}

.feature-lock-message {
  text-align: center;
  width: 100%;
  word-wrap: break-word;
}