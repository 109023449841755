$default-bg-color: $color-white;
$default-border-color: $color--gray4;
$default-placeholder-color: $color--gray6;
$focus-border-color: #00948E;
$warning-border-color: #FFB906;
$error-border-color: #E84444;
$disabled-bg-color: $color--gray3;
$disabled-text-color: $color--gray6;

$form-font-size: 14px;
$form-line-height: 20px;
$error-icon-size: 1.2em;
$form-warning-icon: url("data:image/svg+xml,%3Csvg%20xmlns=%22http://www.w3.org/2000/svg%22%20height=%221em%22%20viewBox=%220%200%20512%20512%22%3E%3C!--!%20Font%20Awesome%20Free%206.4.2%20by%20@fontawesome%20-%20https://fontawesome.com%20License%20-%20https://fontawesome.com/license%20(Commercial%20License)%20Copyright%202023%20Fonticons,%20Inc.%20--%3E%3Cpath%20d=%22M256%2032c14.2%200%2027.3%207.5%2034.5%2019.8l216%20368c7.3%2012.4%207.3%2027.7%20.2%2040.1S486.3%20480%20472%20480H40c-14.3%200-27.6-7.7-34.7-20.1s-7-27.8%20.2-40.1l216-368C228.7%2039.5%20241.8%2032%20256%2032zm0%20128c-13.3%200-24%2010.7-24%2024V296c0%2013.3%2010.7%2024%2024%2024s24-10.7%2024-24V184c0-13.3-10.7-24-24-24zm32%20224a32%2032%200%201%200%20-64%200%2032%2032%200%201%200%2064%200z%22/%3E%3C/svg%3E");
$form-error-icon: url("data:image/svg+xml,%3csvg xmlns=%27http://www.w3.org/2000/svg%27 viewBox=%270 0 12 12%27 width=%2712%27 height=%2712%27 fill=%27none%27 stroke=%27%23000000%27%3e%3ccircle cx=%276%27 cy=%276%27 r=%274.5%27/%3e%3cpath stroke-linejoin=%27round%27 d=%27M5.8 3.6h.4L6 6.5z%27/%3e%3ccircle cx=%276%27 cy=%278.2%27 r=%27.6%27 fill=%27%23000000%27 stroke=%27none%27/%3e%3c/svg%3e");

$single-select-control-height: calc(
        1.5em + 0.75rem + 2px
); // using react bootstrap .form-control height
$single-select-selected-icon: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' width='20' height='20' viewBox='0 0 20 20' fill='none'%3E%3Cpath d='M16.6673 5L7.50065 14.1667L3.33398 10' stroke='%231D8988' stroke-width='1.66667' stroke-linecap='round' stroke-linejoin='round'/%3E%3C/svg%3E");

.gvds-form__warning-message {
  margin-top: 4px;
  font-size: 11px;
  color: $gvds-color--warningDark;
}


.gvds-form__error-message {
  margin-top: 4px;
  font-size: 11px;
  color: $gvds-color--errorDark;
}

@mixin gvds-form__error-icon-shared {
  padding-left: calc($gvds-spacing--100 + $error-icon-size + $gvds-spacing--50);
  background-repeat: no-repeat;
  background-size: $error-icon-size $error-icon-size;
}

@mixin gvds-form__radio-checkbox-shared {
  width: 18px;
  height: 18px;
  background-color: $default-bg-color;
  border: 1px solid $default-border-color;
  cursor: pointer;
}

$gvds-form__error-icon-textarea-bg-pos: top calc($gvds-spacing--50 + 0.2em) left calc($gvds-spacing--100);
$gvds-form__error-icon-field-bg-pos: top calc($gvds-spacing--50 + 0.45em) left calc($gvds-spacing--100);
$gvds-form__error-icon-single-select-bg-pos: top calc($gvds-spacing--50 + 0.25em) left calc($gvds-spacing--100);

/* Override existing styles */
form input {
  margin-bottom: 0;
}

.form-group {
  margin-bottom: $gvds-spacing--200;
}

.gvds-form__field-with-char-count__container {
  position: relative;

  .gvds-form__char-count {
    position: absolute;
    top: -25px;
    right: 0;
    font-weight: 400;
    font-size: 14px;
    color: $color--gray9;
  }
}

// region Form Label
.form-label {
  @include gvds-text--formLabel;
  margin-bottom: $gvds-spacing--50;
}

.form-label-row {
  display: flex;
  flex-direction: row;
  align-items: center;
  margin-bottom: $gvds-spacing--50;

  .form-label {
    margin-bottom: 0;
  }
}

.optional-form-label {
  font-weight: 400;
  color: $color--gray9;
}

.form-label-description {
  @include gvds-text--caption;

  margin-bottom: $gvds-spacing--50;
}

// endregion

.form-check {
  &.gvds-form__error {
    input[type=checkbox] {
      border-color: $error-border-color;

      &:checked {
        background-color: $gvds-color--error;
        border-color: $error-border-color;
      }
    }

    input[type=radio] {
      border-color: $error-border-color;

      &:checked {
        background: radial-gradient($gvds-color--error 0%, $gvds-color--error 40%, transparent 50%, transparent);
        border-color: $error-border-color;
      }
    }
  }

  .form-check-input[type=checkbox] {
    @include gvds-form__radio-checkbox-shared;

    &:disabled:not(:checked) {
      background-color: $color--gray2;
      border-color: $color--gray4;
    }

    &:disabled:checked {
      background-color: $color--gray4;
      border-color: $color--gray4;
    }

    &:checked {
      background-color: $gvds-color--tealSolid;
      border-color: $gvds-color--tealSolid;
    }

    &:focus {
      outline: none;
      box-shadow: none;
    }

    &:hover {
      box-shadow: 0 1px 2px rgba(21, 29, 29, 0.06), 0 1px 3px rgba(21, 29, 29, 0.10);
    }
  }

  .form-check-input[type=radio] {
    @include gvds-form__radio-checkbox-shared;

    &:disabled:not(:checked) {
      background-color: $color--gray2;
      border-color: $color--gray4;
    }

    &:disabled:checked {
      background: radial-gradient($color--gray4 0%, $color--gray4 40%, transparent 50%, transparent);
      border-color: $color--gray4;
    }

    &:checked {
      border-color: $gvds-color--tealSolid;
      background: radial-gradient($gvds-color--tealSolid 0%, $gvds-color--tealSolid 40%, transparent 50%, transparent);
    }

    &:focus {
      outline: none;
      box-shadow: none;
    }

    &:hover {
      box-shadow: 0 1px 2px rgba(21, 29, 29, 0.06), 0 1px 3px rgba(21, 29, 29, 0.10);
    }

    &:hover:not(:checked) {
      box-shadow: 0 1px 2px rgba(21, 29, 29, 0.06), 0 1px 3px rgba(21, 29, 29, 0.10);
    }
  }

  .form-check-label {
    font-weight: 500;
    font-size: 14px;
    padding-top: 3px;
    color: $color--gray10;
    margin-left: 4px;
    cursor: pointer;

    &:disabled {
      color: $color--gray6;
      cursor: default;
    }
  }
}

.form-check-input-indeterminate {
  .form-check-input[type=checkbox] {
    background-color: $gvds-color--tealSolid;
    border-color: $gvds-color--tealSolid;
    background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 20 20'%3e%3cpath fill='none' stroke='%23fff' stroke-linecap='round' stroke-linejoin='round' stroke-width='3' d='M6 10h8'/%3e%3c/svg%3e");
  }
}

.form-switch {
  // Copied from original react bootstrap to replace colour within svg
  $switch-focus-icon: url("data:image/svg+xml,%3csvg xmlns=%27http://www.w3.org/2000/svg%27 viewBox=%27-4 -4 8 8%27%3e%3ccircle r=%273%27 fill=%27%2300919A%27/%3e%3c/svg%3e");

  .form-check-label {
    padding-top: 0;
  }

  .form-check-input[type=checkbox] {
    width: 2em;
    height: 1em;
    background-color: $default-bg-color;
    border: 1px solid $default-border-color;
    cursor: pointer;

    &:disabled:not(:checked) {
      background-color: $color--gray2;
      border-color: $color--gray4;
    }

    &:disabled:checked {
      background-color: $color--gray4;
      border-color: $color--gray4;
    }

    &:checked {
      background-color: $gvds-color--tealSolid;
      border-color: $gvds-color--tealSolid;
    }

    &:focus:not(:checked) {
      outline: none;
      box-shadow: none;

      background-image: $switch-focus-icon;
    }

    &:hover {
      border: 1px solid $gvds-color--tealSolid;
      @include gvds-shadow--active;
      //box-shadow: 0 1px 2px rgba(21, 29, 29, 0.06), 0 1px 3px rgba(21, 29, 29, 0.10);
    }
  }
}

input.gvds-form__field {
  background-color: $default-bg-color;
  border-color: $default-border-color;
  border-radius: 4px;
  font-size: $form-font-size;
  line-height: $form-line-height;
  font-weight: 500;
  padding: $gvds-spacing--100;

  &::placeholder {
    color: $default-placeholder-color;
  }

  &:focus {
    border: 1px solid $focus-border-color;

    box-shadow: 0 0 3px 0 $focus-border-color;

    &.gvds-form__error {
      box-shadow: 0 0 3px 0 $error-border-color;
    }
  }

  &:disabled {
    color: $disabled-text-color;
    background-color: $disabled-bg-color;
  }

  &.gvds-form__warning {
    @include gvds-form__error-icon-shared;
    background-position: $gvds-form__error-icon-field-bg-pos;
    background-image: $form-warning-icon;
  }

  &.gvds-form__error {
    @include gvds-form__error-icon-shared;
    background-position: $gvds-form__error-icon-field-bg-pos;
    border-color: $error-border-color;
    background-image: $form-error-icon;
  }
}

textarea.gvds-form__textarea {
  background-color: $default-bg-color;
  border-color: $default-border-color;
  border-radius: 4px;
  font-weight: 500;
  font-size: $form-font-size;
  padding: $gvds-spacing--50 $gvds-spacing--100;

  &::placeholder {
    color: $default-placeholder-color;
  }

  &:focus {
    border: 1px solid $focus-border-color;

    box-shadow: 0 0 3px 0 $focus-border-color;

    &.gvds-form__error {
      box-shadow: 0 0 3px 0 $error-border-color;
    }
  }

  &:disabled {
    color: $disabled-text-color;
    background-color: $disabled-bg-color;
  }

  &.gvds-form__warning {
    @include gvds-form__error-icon-shared;
    background-position: $gvds-form__error-icon-textarea-bg-pos;
    background-image: $form-warning-icon
  }

  &.gvds-form__error {
    @include gvds-form__error-icon-shared;
    background-position: $gvds-form__error-icon-textarea-bg-pos;
    border-color: $error-border-color;
    background-image: $form-error-icon
  }
}

@mixin unstyled-textarea {
  resize: none;
  border: none;
  padding-top: $gvds-spacing--100;

  &:focus {
    box-shadow: none;
  }
}

// region single select

$single-select-selected-icon-width: 1.5em;

.gvds-form__single-select {
  div.gvds-single-select__control {
    background-color: $default-bg-color;
    border-color: $default-border-color;
    height: $single-select-control-height;
    font-size: $form-font-size;
    font-weight: 500;

    &:hover {
      cursor: pointer;
      border-color: $default-border-color;
    }

    &.gvds-single-select__control--is-focused {
      border-color: $focus-border-color;
      box-shadow: 0 0 3px 0 $focus-border-color;
    }

    &.gvds-single-select__control--is-disabled {
      color: $disabled-text-color;
      background-color: $disabled-bg-color;

      .gvds-single-select__dropdown-indicator {
        color: $disabled-text-color;
      }
    }

    .gvds-single-select__placeholder {
      color: $default-placeholder-color;
    }

    .gvds-single-select__indicator-separator {
      display: none;
    }

    .gvds-single-select__dropdown-indicator {
      color: $color--gray9;
    }
  }

  &.gvds-form__warning {
    div.gvds-single-select__control {
      .gvds-single-select__value-container {
        @include gvds-form__error-icon-shared;
        background-position: $gvds-form__error-icon-single-select-bg-pos;
        background-image: $form-warning-icon;
      }
    }
  }

  &.gvds-form__error {
    div.gvds-single-select__control {
      border-color: $error-border-color;

      &.gvds-single-select__control--is-focused {
        box-shadow: 0 0 3px 0 $error-border-color;
      }

      .gvds-single-select__value-container {
        @include gvds-form__error-icon-shared;
        background-position: $gvds-form__error-icon-single-select-bg-pos;
        border-color: $error-border-color;
        background-image: $form-error-icon
      }
    }
  }
}

.gvds-single-select__option {
  font-weight: 500;

  &:hover {
    cursor: pointer;
    background-color: $color--gray3 !important;
  }

  &.gvds-single-select__option--is-focused {
    background-color: $color--gray2;
  }

  &.gvds-single-select__option--is-selected {
    background-color: $gvds-color--alpha;
    color: $color--gray10;
    font-weight: 600;
    position: relative;
    padding-right: calc(#{$single-select-selected-icon-width} + #{$gvds-spacing--150});

    &:after {
      content: "";
      display: block;
      height: 1em;
      width: $single-select-selected-icon-width;
      background-size: 1.2em 1.2em;
      background-image: $single-select-selected-icon;
      background-repeat: no-repeat;
      position: absolute;
      right: $gvds-spacing--100;
      top: 50%;
      transform: translateY(-60%);
    }
  }
}

// endregion

// region multiselect
$multi-select-component-height: 24px;
$multi-select-component-width: 24px;

.gvds-form__multi-select {

  .option-color-- {
    &celeste {
      background-color: $gvds-accent-color--celeste;

      .gvds-multi-select__multi-value__remove:hover {
        background-color: darken($gvds-accent-color--celeste, 10%);
      }
    }

    &lime {
      background-color: $gvds-accent-color--lime;

      .gvds-multi-select__multi-value__remove:hover {
        background-color: darken($gvds-accent-color--lime, 10%);
      }
    }

    &lavender {
      background-color: $gvds-accent-color--lavender;

      .gvds-multi-select__multi-value__remove:hover {
        background-color: darken($gvds-accent-color--lavender, 10%);
      }
    }

  }

  div.gvds-multi-select__control {
    background-color: $default-bg-color;
    border-color: $default-border-color;
    min-height: $single-select-control-height;
    font-size: $form-font-size;


    &:hover {
      cursor: pointer;
      border-color: $default-border-color;
    }

    .gvds-multi-select__value-container {
      padding: $gvds-spacing--50 $gvds-spacing--100;
      gap: $gvds-spacing--50;
    }

    &.gvds-multi-select__control--is-focused {
      border-color: $focus-border-color;
      box-shadow: 0 0 3px 0 $focus-border-color;
    }

    &.gvds-multi-select__control--is-disabled {
      background: $multi-select-bg-disabled;

      border: 1px solid $color--gray3;

      .gvds-multi-select__dropdown-indicator {
        color: $default-placeholder-color;
      }
    }

    .gvds-multi-select__placeholder {
      color: $default-placeholder-color;
      font-weight: 500;
    }

    .gvds-multi-select__indicator-separator {
      display: none;
    }

    .gvds-multi-select__dropdown-indicator {
      color: $color--gray9;
    }

    .selected-option-container {
      border-radius: 4px;

      .gvds-multi-select__multi-value {
        background-color: transparent;
        gap: $gvds-spacing--50;
        height: $multi-select-component-height;
        margin: 0;

        .gvds-multi-select__multi-value__label {
          font-size: 12px;
          font-weight: 500;
          padding: $gvds-spacing--50 $gvds-spacing--50 $gvds-spacing--50 $gvds-spacing--100;
        }

        .gvds-multi-select__multi-value__remove {
          height: $multi-select-component-height;
          width: $multi-select-component-width;
          color: $color--gray10;
          padding-left: 0;

          svg {
            height: $gvds-spacing--200;
            width: $gvds-spacing--200;
            stroke-width: 2.4px;
          }

          &:hover {
            border-radius: 4px;
          }
        }

        &--is-disabled {
          .gvds-multi-select__multi-value__remove {
            display: none;
          }
        }
      }
    }

    .gvds-multi-select__clear-indicator {
      border-right: 2px solid $color--gray3;
      padding-top: 2px;
      padding-bottom: 2px;

    }
  }

  .gvds-multi-select__menu {
    z-index: $gvds-z-index--form-in-modal !important;
  }

  &.gvds-form__warning {
    div.gvds-multi-select__control {
      .gvds-multi-select__value-container {
        @include gvds-form__error-icon-shared;
        background-position: $gvds-form__error-icon-single-select-bg-pos;
        background-image: $form-warning-icon;
      }
    }
  }

  &.gvds-form__error {
    div.gvds-multi-select__control {
      border-color: $error-border-color;

      &.gvds-multi-select__control--is-focused {
        box-shadow: 0 0 3px 0 $error-border-color;
      }

      .gvds-multi-select__value-container {
        @include gvds-form__error-icon-shared;
        background-position: $gvds-form__error-icon-single-select-bg-pos;
        border-color: $error-border-color;
        background-image: $form-error-icon
      }
    }
  }
}

.gvds-multi-select__option {
  font-weight: 500;

  .gvds-tag {
    margin-bottom: 0;
  }

  &:hover {
    cursor: pointer !important;
    background-color: $color--gray3 !important;
  }

  &.gvds-multi-select__option--is-focused {
    background-color: $color--gray2;
  }

  &.gvds-multi-select__option--is-selected {
    background-color: $gvds-color--alpha;
    color: $color--gray10;
    font-weight: 600;
    display: flex;

    &:after {
      content: "";
      display: block;
      height: 1em;
      width: 1.5em;
      background-size: 1.2em 1.2em;
      background-image: $single-select-selected-icon;
      background-repeat: no-repeat;
      position: absolute;
      right: $gvds-spacing--100;
    }
  }

  &.gvds-multi-select__option--is-disabled {
    opacity: 0.6;
  }
}

.section-box {
  article {
    & > :last-child .gvds-form__multi-select {
      margin-bottom: 0 !important;
    }
  }
}

form .gvds-form__multi-select {
  margin-bottom: $gvds-spacing--200;
}


// endregion

// region Quickfill
.quickfill-container {
  display: flex;
  align-items: center;

  &.on-form-label {
    height: 21.5px;
  }

  &.with-form-label-height {
    height: 15px;
  }

  .quickfill-label {
    @include gvds-text--label;
    color: $color--gray6;
  }

  button.quickfill-input {
    margin-left: 1em;
    margin-right: 0;

    &:disabled {
      &:hover {
        color: $color--gray6;
      }
    }
  }
}

// endregion Quickfill
