@mixin upload-box-container {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
  height: 141px;
  border: 1px dashed $color--gray4;
  border-radius: 4px;
  margin-bottom: $gvds-spacing--100;

  &:hover {
    cursor: pointer;
    border: 1px solid $gvds-color--tealSolid;
  }

  .upload-box--content {
    display: flex;
    flex-direction: column;
    align-items: center;

    .upload-box--text {
      margin-top: $gvds-spacing--100;
      text-align: center;
      font-weight: 500;
    }

    .upload-box--caption {
      @include gvds-text--caption;
      font-weight: 400;
    }

    .upload-box--text-button {
      @include gvds-text--link;
      font-weight: 600;

      &:disabled {
        color: $color--gray4;
      }
    }
  }
}

.upload-box {
  @include upload-box-container;
}

.upload-box--drag {
  @include upload-box-container;
  background-color: $gvds-color--alpha;
  border: 1px solid $gvds-color--tealSolid;
}

.upload-box--disabled {
  @include upload-box-container;
  pointer-events: none;
  color: $color--gray4;
  background-color: $color--gray2;

  .gvds-link {
    color: $color--gray4;
  }
}

.uploaded-files-container {
  margin-bottom: $gvds-spacing--100;

  .uploaded-files {
    display: flex;
    border: 1px solid $color--gray4;
    border-radius: 4px;
    padding: $gvds-spacing--50;
    justify-content: space-between;
    text-align: left;


    .uploaded-files-item-display {
      color: $color--gray10;
      font-weight: 500;
      display: flex;
      align-items: center;
      gap: $gvds-spacing--50;
    }

    .uploaded-files-delete__button {
      padding: 0;
      display: flex;
      align-items: center;
    }
  }
}




