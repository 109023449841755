.nav-tabs {
  margin-bottom: 16px;

  .nav-item {
    margin: 0 0.25rem;

    -webkit-box-shadow: none;
    -moz-box-shadow: none;
    box-shadow: none;
    transition: box-shadow 0.05s linear;

    &:has(.active) {
      -webkit-box-shadow: inset 0px -4px 0px -2px $gvds-color--tealSolid;
      -moz-box-shadow: inset 0px -4px 0px -2px $gvds-color--tealSolid;
      box-shadow: inset 0px -4px 0px -2px $gvds-color--tealSolid;
      border-color: transparent;
      background-color: transparent;
    }

    &:first-child {
      margin-left: 0;
    }
  }

  .nav-link {
    @include gvds-text--button;
    color: $color--gray10;
    padding: 0.25rem 0.5rem;
    margin: 2px 0; // vertical margin should match box shadow of nav-item
    border-width: 0;
    border-radius: 4px;

    &.active {
      color: $gvds-color--tealSolid;
      border-color: transparent;
      background-color: transparent;
    }

    &:hover:not(.active) {
      color: $gvds-color--tealSolid;
    }

    &.disabled, &.disabled:hover {
      pointer-events: auto;
      color: $color--gray6;
    }
  }
}
