$stepper-colour: $gvds-color--tealSolid;

.horizontal-stepper__container {
  display: flex;
  flex-wrap: wrap;
}

@mixin horizontal-stepper__item {
  flex: 1;
  display: flex;
  flex-direction: column;

  &:after {
    content: "";
    position: relative;
    top: 1.25rem;
    left: 36px;
    width: calc(100% - 46px);
    height: 4px;
    background-color: $stepper-colour;
    border-radius: 25px;
    order: -1;
  }
}

.horizontal-stepper__item {
  @include horizontal-stepper__item
}

.horizontal-stepper__item_with-pointer {
  cursor: pointer;
}

@mixin horizontal-stepper__inactive-step {
  display: flex;
  align-items: center;
  justify-content: center;
  --size: 28px;
  z-index: 1;
  width: var(--size);
  height: var(--size);
  border-radius: 50%;
  margin: 4px 0 0;
  background-color: $color--gray4;

  div {
    color: $white;
    text-align: center;
  }
}

.horizontal-stepper__inactive-step {
  @include horizontal-stepper__inactive-step;
}

.horizontal-stepper__completed-step {
  @include horizontal-stepper__inactive-step;
  background-color: $stepper-colour;

  .gvds-icon-container, svg {
    $icon-size: 14px;
    height: $icon-size;
    min-height: $icon-size;
    width: $icon-size;
    min-width: $icon-size;
  }
}

.horizontal-stepper__current-step {
  @include horizontal-stepper__inactive-step;
  --size: 34px;
  width: var(--size);
  height: var(--size);
  margin: 0 0 0 -4px;
  background-color: $stepper-colour;
  padding: 2px;
  background-clip: content-box;
  border: 1px solid $stepper-colour;

}

.horizontal-stepper__disabled-item {
  @include horizontal-stepper__item;
}

.horizontal-stepper__disabled-item:after {
  background-color: $color--gray4;
}
