$green: #25c292;
$light-green: #E4FAF3;
$dark-green: #215e4c;
$red: #D4382A;
$light-red: #FBECEA;
$light-red-2: #eda6a6;
$blue: #1667c4;
$light-blue: #deebff;
$sky-blue: #7ce4f2;
$dark-blue: #104D93;
$yellow: #F9D146;
$light-yellow: #FDF5D9;
$dark-yellow: #7A6000;
$purple: #6f59da;
$gray-36: #324a5d;
$gray-56: #79858e;
$gray-85: #d1d4d9;
$gray-93: #e4e8ed;
$gray-98: #f5f7fa;
$black: #000000;
$white: #FFFFFF;

// colours not in active component library - https://www.figma.com/file/Az72UE1phcs4aVweg4C6SQ/ACTIVE-GV-3.ON3-Component-Library?type=design&node-id=5-3&mode=design&t=61dCZ1Ymr9joQjOh-4
$gray-90: #e5e5e5;
$gray-95: #ebeef2;
$dark-gray: #203342;
$disabled-gray: #ccc;

$background-color: #f7fbff;
$topnav-background: $gray-36;
$topnav-active: $green;
$sidenav-background: $gray-98;
$sidenav-active: $green;
$card-shadow: #d4d7dc;
$section-border-color: $gray-90;

// Refer to for original colors https://getbootstrap.com/docs/4.4/getting-started/theming/#available-variables
$primary: $blue;
$secondary: #6c757d;
$success: $green;
$info: #17a2b8;
$warning: $yellow;
$danger: $red;
$light: #f8f9fa;
$dark: #343a40;

$key-light-shadow: rgba(0, 0, 0, 0.1);
$ambient-light-shadow: rgba(0, 0, 0, 0.2);
