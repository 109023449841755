@mixin gvds-shadow--large {
  box-shadow: 0 4px 12px rgba(0, 0, 0, 0.16), 0 0 20px rgba(0, 0, 0, 0.08);

}

@mixin gvds-shadow--small {
  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.06), 0 0 16px rgba(0, 0, 0, 0.08);
}

@mixin gvds-shadow--active {
  box-shadow: 0 0 3px 0 #00948E;
}