.gvds-top-nav {
  height: $top-nav-height;
  display: flex;
  flex-direction: row;
  align-items: center;

  padding: $gvds-spacing--100 $gvds-spacing--400;

  background-color: $top-nav-bg-color;
  border-bottom: 1px solid $top-nav-border-bottom-color;

  .gvds-top-nav--item {
    display: flex;
    align-items: center;

    margin-left: 4px;
    margin-right: 4px;

    border-radius: 4px;
    border: none;

    font-size: 14px;
    font-weight: 700;
    color: $text-on-nav-color;
    background-color: rgba(0, 0, 0, 0);

    &:hover {
      color: $text-on-nav-color;
    }

    &:active {
      color: $text-on-nav-color;
      background-color: rgba(0, 0, 0, 0);
    }

    .gvds-top-nav--item--text {
      @include gvds-text--heading4;
      margin-left: 4px;
    }
  }

  .workspace-info--full-feature {
    color: $gvds-color--tealSolid;
  }

  .gvds-top-nav--workspace-interactable, .gvds-top-nav--item {
    cursor: pointer;
    border-radius: 4px;
    padding: $gvds-spacing--50 $gvds-spacing--100;

    &:hover {
      background-color: $gvds-color--alpha;
    }

    &.icon-button {
      padding: $gvds-spacing--50;
    }
  }

  .gvds-top-nav--workspace-interactable {
    margin-left: -$gvds-spacing--150;
  }

  .gvds-top-nav--workspace-static {
    margin-left: -$gvds-spacing--50;
  }
}

.workspace-nav--current-workspace-display {
  display: flex;
  flex-direction: row;
  align-items: center;

  font-size: 14px;
  font-weight: 600;

  .name-display {
    @include gvds-text--heading4;
    margin-left: $gvds-spacing--50;
    margin-right: $gvds-spacing--100;
  }
}

.workspace-nav--selector-modal-backdrop {
  position: fixed;
  z-index: $gvds-z-index--workspace-nav-selector-backdrop;
  top: 0;
  left: 0;
  width: 100vw;
  height: 100vh;

  background-color: $color--gray6;
  opacity: 0.25;
  transition: opacity 0.15s linear;
}

.workspace-nav--selector-modal-component {
  z-index: $gvds-z-index--workspace-nav-selector-modal;
}

.workspace-nav--selector-modal-dialog {
  $modal-fade-transform: none;
  $modal-show-transform: none;

  .modal.fade & {
    transform: $modal-fade-transform;
  }

  .modal.show & {
    transform: $modal-show-transform;
  }
}

.workspace-nav--selector-modal-content {
  position: fixed;
  top: $top-nav-height;
  left: $side-nav-width + $gvds-spacing--300;

  &.minimised-side-nav {
    left: $side-nav-width-minimised  + $gvds-spacing--300;
  }

  width: 493px;
  max-height: 80vh;
  overflow-y: hidden;
  padding: $gvds-spacing--300;
  border-radius: 4px;
  border: none;

  @include gvds-shadow--large;
  background-color: white;

  .modal-body {
    padding: 0;
    max-height: 100%;
    height: 100%;
    overflow-y: hidden;
    display: flex;
    flex-direction: column;

    .tab-content {
      overflow-y: auto;
    }
  }

  .modal-dialog & {
    transition: transform 0.3s ease-out;

    $modal-fade-transform: translate(0, -30px);
    $modal-show-transform: none;

    .modal.fade & {
      transform: $modal-fade-transform;
    }

    .modal.show & {
      transform: $modal-show-transform;
    }
  }
}

// region Contract Selector
.contract-selector--toggle {
  margin-left: $gvds-spacing--100;
  margin-bottom: $gvds-spacing--100;
  display: flex;
  align-items: center;
  width: fit-content;

  font-size: 12px;
  font-weight: 600;
  color: $color--gray9;

  cursor: pointer;

  .gvds-icon-container {
    margin-left: $gvds-spacing--50;
    height: 18px;
  }
}

.contract-selector--dropdown-menu {
  min-width: 300px;
  max-width: 300px;
}

.contract-selector__select__control {
  min-height: unset !important;
}

.contract-selector__select__menu {
  box-shadow: none;
  border: none;
}

.contract-selector__select__option {
  padding: $gvds-spacing--100 $gvds-spacing--200;
  background-color: $color-white !important;
  cursor: pointer !important;

  &:active {
    color: $color--gray10;
  }

  &:hover {
    background-color: $gvds-color--alpha !important;
  }

  &.selected {
    background-color: $gvds-color--alpha !important;
    color: $color--gray10;
    font-weight: 600;

    .gvds-icon-container {
      color: $gvds-color--tealSolid;
      height: 14px;
      min-height: 14px;
      max-height: 14px;

      position: relative;

      svg {
        position: absolute;
      }
    }
  }
}

// endregion Contract Selector

@mixin action-button-size {
  height: 24px;
  width: 24px;
}

@mixin search-box-positioning {
  position: sticky;
  top: 0;
}

.workspace-portfolio-selector {
  .search-box {
    margin-bottom: $gvds-spacing--100;
    @include search-box-positioning;
  }

  .subportfolios-header {
    font-size: 12px;
    font-weight: 600;
    color: $color--gray6;

    padding: $gvds-spacing--100 $gvds-spacing--150 $gvds-spacing--50 $gvds-spacing--150;
  }

  .portfolio-item {
    display: flex;
    flex-direction: row;
    align-items: center;

    padding: $gvds-spacing--100;

    cursor: pointer;

    & > .gvds-icon-container {
      margin-right: $gvds-spacing--100;
    }

    .name {
      white-space: nowrap;
      overflow: hidden;
      text-overflow: ellipsis;
    }

    .action-button {
      visibility: hidden;
      margin-left: $gvds-spacing--50;

      @include action-button-size;
    }

    &:hover {
      background-color: $gvds-color--alpha;

      .action-button {
        visibility: visible;
      }
    }

    &.selected {
      background-color: $gvds-color--alpha;
      font-weight: 600;

      .selected-icon {
        color: $gvds-color--tealSolid;
      }
    }
  }

  .create-subportfolio-container {
    margin-top: $gvds-spacing--300;
    position: sticky;
    bottom: 0;
    background-color: $color-white;
  }
}

.workspace-site-selector {
  .search-box {
    margin-bottom: $gvds-spacing--100;
    @include search-box-positioning;
  }

  .subportfolio-filter-display {
    margin-bottom: $gvds-spacing--100;

    font-size: 14px;
    font-weight: 400;

    display: flex;
    flex-direction: row;
    align-items: baseline;
    flex-wrap: wrap;

    .subportfolio-filter-display__name {
      display: inline;
      margin-left: $gvds-spacing--50;

      .gvds-icon-container {
        margin-right: -$gvds-spacing--50;
        height: 14px;
        min-height: 14px;
        width: 20px;
        min-width: 20px;
        transform: translate(0px, 5px);
      }
    }
  }

  .site-list-container {
    overflow-y: auto;
  }

  .site-item {
    display: flex;
    flex-direction: row;
    align-items: center;

    padding: $gvds-spacing--100;

    cursor: pointer;

    & > .gvds-icon-container {
      margin-right: $gvds-spacing--100;
    }

    .name {
      white-space: nowrap;
      overflow: hidden;
      text-overflow: ellipsis;
    }

    .action-button {
      @include action-button-size;
    }

    &:hover {
      background-color: $gvds-color--alpha;
    }

    &.selected {
      background-color: $gvds-color--alpha;
      font-weight: 600;

      .selected-icon {
        color: $gvds-color--tealSolid;
      }
    }
  }

  .facility-list-container {
    border-left: 2px solid $color--gray4;
    margin-left: $gvds-spacing--250;

    .facility-item {
      display: flex;
      flex-direction: row;
      align-items: center;

      padding: $gvds-spacing--100;
      margin-left: $gvds-spacing--200;

      cursor: pointer;

      &:hover {
        background-color: $gvds-color--alpha;
      }

      &.selected {
        background-color: $gvds-color--alpha;
        font-weight: 600;

        .selected-icon {
          color: $gvds-color--tealSolid;
        }
      }
    }
  }
}
