$color--focus-shadow: #00948E;

@mixin gvds-form__shared-picker-input-style {
  outline: none;

  &:disabled {
    background-color: $color--gray2;
  }

  &.on-focus {
    border: 1px solid $gvds-color--tealSolid;
    box-shadow: 0 0 3px 0 $color--focus-shadow;

    &.gvds-form__warning-border {
      border: 1px solid $gvds-color--tealSolid;
    }

    &.gvds-form__error-border {
      box-shadow: 0 0 3px 0 $error-border-color;
    }
  }

  &.gvds-form__warning-border {
    border: 1px solid $color--gray4;
  }

  &.gvds-form__warning-icon {
    @include gvds-form__error-icon-shared;
    background-position: $datepicker__error-icon-field-bg-pos;
    background-image: $form-warning-icon;
  }

  &.gvds-form__error-border {
    border-color: $error-border-color;
  }

  &.gvds-form__error-icon {
    @include gvds-form__error-icon-shared;
    background-position: $datepicker__error-icon-field-bg-pos;
    background-image: $form-error-icon;
  }
}

@mixin gvds-form__shared-calendar-content-style {
  border: 1px solid $color--gray4;
  background-color: $color--gray2;
  display: inline-flex;
  justify-content: center;
  align-items: center;

  width: 62px;
  height: 29px;
  padding: 6px 8px;
  text-align: center;
  margin: $gvds-spacing--50;
  border-radius: 4px;
  font-size: 14px;
  font-weight: 500;
}

@mixin gvds-form__start-end-picker-container-style {
  display: grid;
  grid-template-columns: 1fr 1fr;
}

.invisible-placeholder {
  visibility: hidden;
}

.react-datepicker__navigation {

  .react-datepicker__navigation-icon::before {
    border-color: $color--gray10;
    border-width: 2px 2px 0 0;
  }
}

.react-datepicker-wrapper {
  width: 100%;
}

.react-datepicker-popper {
  z-index: $gvds-z-index--form-in-modal !important;
}

.react-datepicker__input-container {
  display: flex;
  flex-direction: row;
  height: calc(36px + 2 * 1px);
  border-color: $color--gray4;
  min-width: 100px;

  input {
    cursor: pointer;
    border-radius: 4px;
    border: 1px solid $color--gray4;
    padding: 5px;
    width: 100%;
    background-color: $color-white;
    color: $color--gray10;
    font-weight: 500;

    &:disabled {
      color: $color--gray6;
      background-color: $color--gray3;
    }

    &.start-date {
      border-radius: 4px 0 0 4px;

      &:not(.on-focus) {
        border-right: none;
      }
    }

    &.end-date {
      border-radius: 0 4px 4px 0;
    }
  }

  &:after {
    content: url(../assets/icons/gvds-icon--calendar-event.svg);
    margin-left: -32px;
    margin-top: $gvds-spacing--75;
    border-top: none;
    pointer-events: none;
  }

  &:has(.gvds-form__single-datepicker-input:disabled),
  &:has(.gvds-form__single-monthpicker-input:disabled),
  &:has(.gvds-form__single-yearpicker-input:disabled) {
    &:after {
      content: url(../assets/icons/gvds-icon--calendar-event-disabled.svg);
      margin-left: -32px;
      margin-top: $gvds-spacing--75;
      border-top: none;
    }
  }
}

.react-datepicker__day--keyboard-selected,
.react-datepicker__month-text--keyboard-selected,
.react-datepicker__quarter-text--keyboard-selected,
.react-datepicker__year-text--keyboard-selected {
  border-radius: 50%;
  background-color: $color-white;
  border: none;
}