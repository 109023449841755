.status-label {
  display: inline-flex;
  align-items: center;

  padding: $gvds-spacing--50 $gvds-spacing--100;
  border-radius: 4px;

  @include gvds-text--label;
  color: $color--gray10;

  ~ .status-label {
    margin-left: $gvds-spacing--100;
  }

  .gvds-icon-container {
    margin-right: $gvds-spacing--50;
  }

  &.label- {
    &green {
      background-color: $gvds-color--successLight;
      color: $gvds-color--successDark;

      .gvds-icon-container {
        color: $gvds-color--success;
      }
    }

    &yellow {
      background-color: $gvds-color--warningLight;
      color: $gvds-color--warningDark;

      .gvds-icon-container {
        color: $gvds-color--warning;
      }
    }

    &red {
      background-color: $gvds-color--errorLight;
      color: $gvds-color--errorDark;

      .gvds-icon-container {
        color: $gvds-color--error;
      }
    }

    &gray {
      background-color: $color--gray3;
      color: $color--gray10;
    }

    &blue {
      background-color: $gvds-color--infoLight;
      color: $gvds-color--infoDark;

      .gvds-icon-container {
        color: $gvds-color--info;
      }
    }
    &lavender {
      background-color: $gvds-accent-color--lavender;
      color: $color--gray10;
    }
    &lime {
      background-color: $gvds-accent-color--lime;
      color: $color--gray10;
    }
  }
}