@mixin card-style {
  border-radius: 4px;
  border: 1px solid $color--gray4;
  background-color: $color-white;
  font-weight: 500;
}

.filter-card {
  @include card-style;

  &:hover {
    background-color: $gvds-color--alpha;
  }

  &.active {
    border: 1px solid $gvds-color--tealSolid;
    color: $gvds-color--tealSolid;
  }

  &.disabled {
    background-color: $color--gray2;
    cursor: default;
  }
}