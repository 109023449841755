$primary-text-color: #FFFFFF;
$primary-text-color-disabled: $color--gray4;
$primary-bg: $gvds-color--tealSolid;
$primary-bg-disabled: $color--gray2;
$primary-bg-hover: $gvds-color--tealLinear;

$secondary-text-color: $color--gray10;
$secondary-text-color-disabled: $color--gray4;
$secondary-text-color-active: $color--gray10;
$secondary-bg: $gvds-color--tealLight;
$secondary-bg-disabled: $color--gray2;
$secondary-bg-hover: $color--gray4;

$tertiary-text-color: $color--gray9;
$tertiary-bg-hover: $gvds-color--tealLight;
$tertiary-bg-disabled: $color--gray2;
$tertiary_text-color-disabled: $color--gray4;

$destructive-primary: $color--gray0;
$destructive-primary-disabled: $color--gray4;
$destructive-primary-bg: $gvds-color--error;
$destructive-primary-bg-disabled: $color--gray2;
$destructive-primary-bg-hover: $gvds-color--errorDark;

$destructive-tertiary: $gvds-color--error;
$destructive-tertiary-disabled: $color--gray4;
$destructive-tertiary-bg-disabled: $color--gray2;
$destructive-tertiary-bg-hover: $gvds-color--errorLight;

$icon-button-secondary: $color--gray10;
$icon-button-secondary-bg: $gvds-color--tealLight;
$icon-button-secondary-bg-hover: $color--gray4;
$icon-button-secondary-disabled: $color--gray4;
$icon-button-secondary-bg-disabled: $color--gray2;
$icon-button-tertiary: $color--gray9;
$icon-button-tertiary-bg: rgba(0, 0, 0, 0);
$icon-button-tertiary-bg-hover: $gvds-color--tealLight;
$icon-button-tertiary-disabled: $color--gray4;
$icon-button-tertiary-bg-disabled: $color--gray2;

$icon-button-popover: #F7F9F9;
$icon-button-popover-bg: #151D1D;

$icon-button-destructive: $gvds-color--error;
$icon-button-destructive-bg: rgba(0, 0, 0, 0);
$icon-button-destructive-bg-hover: $gvds-color--errorLight;
$icon-button-destructive-disabled: $color--gray4;
$icon-button-destructive-bg-disabled: $color--gray2;

$button-height: 36px;
$button-group-width: 76px;

@mixin gvds-button__shared-style {
  @include  gvds-text--button;
  text-align: center;
  white-space: nowrap;

  height: $button-height;
  padding: $gvds-spacing--50 $gvds-spacing--150;

  display: inline-flex;
  justify-content: center;
  align-items: center;
}

@mixin gvds-button__disabled {
  border-radius: 4px;
  border: none;
  opacity: 1;
  pointer-events: initial;
}

.gvds-button + .gvds-button {
  margin-left: $gvds-spacing--100;
}

.gvds-button-is-loading.gvds-button.gvds-button__ {
  &primary:disabled {
    background-color: $gvds-color--tealSolid;
  }

  &secondary:disabled {
    background-color: $gvds-color--tealLight;
  }

  &destructive-primary:disabled {
    background-color: $gvds-color--error;
  }
}

.gvds-button.gvds-button__primary {
  @include gvds-button__shared-style;

  border-radius: 4px;
  background: $primary-bg;
  color: $primary-text-color;
  border: none;
  transition: none;

  &:disabled {
    @include gvds-button__disabled;

    background: $primary-bg-disabled;
    color: $primary-text-color-disabled;
  }

  &:not([disabled]):hover {
    background: $primary-bg-hover;
    color: $primary-text-color;
    transition: none;
  }

  &.with-leading-icon {
    padding-left: $gvds-spacing--100;
  }

  &.with-trailing-icon {
    padding-right: $gvds-spacing--100;
  }
}

.gvds-button.gvds-button__secondary {
  @include gvds-button__shared-style;

  border-radius: 4px;
  background-color: $secondary-bg;
  color: $secondary-text-color;
  border: none;

  &:disabled {
    @include gvds-button__disabled;

    background-color: $secondary-bg-disabled;
    color: $secondary-text-color-disabled;
  }

  &:not([disabled]):hover {
    background-color: $secondary-bg-hover;
    color: $secondary-text-color;
  }

  &.with-leading-icon {
    padding-left: $gvds-spacing--100;
  }

  &.with-trailing-icon {
    padding-right: $gvds-spacing--100;
  }
}

.gvds-button.gvds-button__tertiary {
  @include gvds-button__shared-style;

  border-radius: 4px;
  color: $tertiary-text-color;
  background-color: rgba(0, 0, 0, 0);
  border: none;

  &:disabled {
    @include gvds-button__disabled;

    background-color: $tertiary-bg-disabled;
    color: $tertiary-text-color-disabled;
  }

  &:not([disabled]):hover {
    color: $tertiary-text-color;
    background-color: $tertiary-bg-hover;
  }

  &.with-leading-icon {
    padding-left: $gvds-spacing--100;
  }

  &.with-trailing-icon {
    padding-right: $gvds-spacing--100;
  }
}

.gvds-button.gvds-button__destructive-primary {
  @include gvds-button__shared-style;

  border-radius: 4px;
  color: $destructive-primary;
  background-color: $destructive-primary-bg;
  border: none;

  &:disabled {
    @include gvds-button__disabled;

    color: $destructive-primary-disabled;
    background-color: $destructive-primary-bg-disabled;
  }

  &:not([disabled]):hover {
    color: $destructive-primary;
    background-color: $destructive-primary-bg-hover;
  }

  &.with-leading-icon {
    padding-left: $gvds-spacing--100;
  }

  &.with-trailing-icon {
    padding-right: $gvds-spacing--100;
  }
}

.gvds-button.gvds-button__destructive-tertiary {
  @include gvds-button__shared-style;

  border-radius: 4px;
  color: $destructive-tertiary;
  background-color: rgba(0, 0, 0, 0);
  border: none;

  &:disabled {
    @include gvds-button__disabled;

    color: $destructive-tertiary-disabled;
    background-color: $destructive-tertiary-bg-disabled;
  }

  &:not([disabled]):hover {
    color: $destructive-tertiary;
    background-color: $destructive-tertiary-bg-hover;
  }

  &.with-leading-icon {
    padding-left: $gvds-spacing--100;
  }

  &.with-trailing-icon {
    padding-right: $gvds-spacing--100;
  }
}

.gvds-button__loading-spinner {
  padding: $gvds-spacing--50 $gvds-spacing--300;
  height: 100%;
  width: 100%;

  display: flex;
  align-items: center;
  justify-content: center;
}

.gvds-button__icon {
  height: 24px;
  width: 24px;
  margin-right: $gvds-spacing--50;
  padding: $gvds-spacing--25;

  display: flex;
  justify-content: center;
  align-items: center;
}

$icon-size: 24px;
$icon-button-padding: $gvds-spacing--50;
$icon-button-size: $button-height;

@mixin gvds-icon-button__shared-style {
  width: $icon-button-size;
  height: $icon-button-size;
  padding: $icon-button-padding;

  border-radius: 4px;
  border: none;
}

.gvds-button.gvds-icon-button__secondary {
  @include gvds-button__shared-style;
  @include gvds-icon-button__shared-style;

  color: $icon-button-secondary;
  background-color: $icon-button-secondary-bg;

  &:hover {
    color: $icon-button-secondary;
    background-color: $icon-button-secondary-bg-hover;
  }

  &:disabled {
    @include gvds-button__disabled;

    color: $icon-button-secondary-disabled;
    background-color: $icon-button-secondary-bg-disabled;
  }
}

.gvds-button.gvds-icon-button__tertiary {
  @include gvds-button__shared-style;
  @include gvds-icon-button__shared-style;

  color: $icon-button-tertiary;
  background-color: $icon-button-tertiary-bg;

  &:hover {
    color: $icon-button-tertiary;
    background-color: $icon-button-tertiary-bg-hover;
  }

  &:disabled {
    @include gvds-button__disabled;

    color: $icon-button-tertiary-disabled;
    background-color: $icon-button-tertiary-bg-disabled;
  }
}

.gvds-button.gvds-icon-button__destructive {
  @include gvds-button__shared-style;
  @include gvds-icon-button__shared-style;

  color: $icon-button-destructive;
  background-color: $icon-button-destructive-bg;

  &:hover {
    color: $icon-button-destructive;
    background-color: $icon-button-destructive-bg-hover;
  }

  &:disabled {
    @include gvds-button__disabled;

    color: $icon-button-destructive-disabled;
    background-color: $icon-button-destructive-bg-disabled;
  }
}

// region Button Group
.btn-group {
  &.big-btn-group {
    button {
      @extend .body-2-bold;
      padding: 7px 60px;
    }
  }
}

.gvds-button.gvds-button__tertiary.gvds-button-group--selected {
  border: 1px solid $performance-report--dark-green;
  background-color: $performance-report--light-green;
  color: $performance-report--dark-green;
  height: $button-height;
  min-width: $button-group-width;

  &:hover {
    color: $performance-report--dark-green;
    background-color: $performance-report--light-green;
  }
}

.gvds-button.gvds-button__tertiary.gvds-button-group--not-selected {
  border: 1px solid $performance-report--dark-green;
  color: $performance-report--dark-green;
  height: $button-height;
  min-width: $button-group-width;

  &:hover {
    background-color: $performance-report--dark-green;
    color: $color-white;
    box-shadow: none;
  }

  &:focus {
    box-shadow: none;
  }
}

// endregion

// region Text Button
.gvds-text-button {
  @include gvds-text--button;
  border: none;
  padding: $gvds-spacing--50 0;

  display: inline-flex;
  align-items: center;

  &:hover {
    text-decoration: none;
  }
}

// endregion

.dropdown-button-icon {
  margin-left: $gvds-spacing--150;
}

// region Icon Button in Table
table {
  .gvds-button.gvds-icon-button__secondary,
  .gvds-button.gvds-icon-button__tertiary,
  .gvds-button.gvds-icon-button__destructive {
    &:hover {
      @include gvds-shadow--small;
    }
  }
}
// endregion