html,
body,
#root {
  height: 100%;
  font-family: "Inter", sans-serif;
  color: $color--gray10;
}

body {
  @include gvds-text--body;
  overflow-y: clip; // to prevent glitching by tooltip
}

h1 {
  @include gvds-text--heading1;
}

h2 {
  @include gvds-text--heading2;
}

h3 {
  @include gvds-text--heading3;
}

h4 {
  @include gvds-text--heading4;
}

a,
.btn-link {
  @include gvds-text--link;
  text-decoration: none;

  &:hover,
  &:active,
  &:visited,
  &:focus {
    color: $link-color;
  }

  &:hover {
    text-decoration: underline;
  }
}

ul, ol {
  padding-left: 1em;
}