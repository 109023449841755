.gvds-avatar {
  width: 32px;
  min-width: 32px;
  height: 32px;
  min-height: 32px;
  padding: 0;
  border-radius: 16px;
  border-color: $color-white;
  border-width: 2px;
  font-weight: 700;
  font-size: 12.68px;
  text-align: center;

  &:hover {
    border-color: $color-white;
    cursor: default;
  }

  &.accent1 {
    background-color: $gvds-accent-color--purple;
    color: $color-white;
  }

  &.accent2 {
    background-color: $gvds-accent-color--pink;
    color: $color-white;
  }

  &.accent3 {
    background-color: $gvds-accent-color--navy;
    color: $color-white;
  }

  &.accent4 {
    background-color: $gvds-accent-color--lavender;
    color: $color--gray10;
  }

  &.accent5 {
    background-color: $gvds-accent-color--celeste;
    color: $color--gray10;
  }

  &.accent6 {
    background-color: $gvds-accent-color--lime;
    color: $color--gray10;
  }
}
