$datepicker__error-icon-field-bg-pos: top calc($gvds-spacing--50 + 0.4em) left calc($gvds-spacing--100);

//Override existing style
.react-datepicker {
  border: none;
  box-shadow: 0 0 5px 0 $color--gray4;

  .react-datepicker__triangle {
    transform: translate3d(120px, 0px, 0px) !important;
  }

  .react-datepicker__month-container {
    .react-datepicker__header {
      background-color: $color--gray2;
      border-bottom: none;

      .react-datepicker__day-names {
        background-color: $color-white;
        padding-top: 5px;
      }
    }

    .react-datepicker__month {
      max-width: 230px;
      font-size: 14px;
      font-weight: 500;

      .react-datepicker__day {
        &:hover {
          border-radius: 50%;
          background-color: $color-brand-green-light;
        }

        &--outside-month {
          color: $color--gray6;
        }

        &--highlighted {
          border-radius: 50%;
          background-color: $gvds-color--alpha;
          color: $color--gray10;
        }

        &--selected {
          border-radius: 50%;
          background-color: $color-brand-green-dark;
          color: $color-white;

          &:hover {
            background-color: $color-brand-green-dark;
          }
        }

        &--disabled {
          pointer-events: none;
          color: $color-disabled;
        }
      }
    }
  }
}

.react-datepicker-popper[data-placement^=bottom] {
  .react-datepicker__triangle::before {
    border-bottom-color: $color--gray2;
  }
}

.react-datepicker-popper[data-placement^=top] {
  .react-datepicker__triangle::before {
    border-top-color: $color--gray2;
  }
}

//End of override

.gvds-datepicker__header-container {
  display: flex;
  justify-content: start;
  padding-bottom: 8px;

  .datepicker__dropdown-menu {
    max-height: 200px;
    overflow-y: auto;
  }

  .datepicker__dropdown-item {
    display: flex;
    align-items: center;
    flex-direction: row;
    text-decoration: none;

    &:hover {
      background-color: $color--gray3;
    }

    &.selected {
      background-color: $gvds-color--alpha;
    }
  }

  .gvds-datepicker__header-dropdown {
    display: flex;
    align-items: center;
    color: $color--gray10;
    font-size: 14px;
    font-weight: 600;


    &:hover,
    &:focus {
      color: $color--gray10;
      text-decoration: none;
    }
  }
}

.selected-item-check {
  color: $color-brand-green-dark;
}

.gvds-form__single-datepicker-input {
  @include gvds-form__shared-picker-input-style;
}

.gvds-form__start-end-datepicker__container {
  @include gvds-form__start-end-picker-container-style;

  .start-date__container {
  }

  .end-date__container {
  }
}


