$content-max-width: 1000px;
$logo-width-buffer: 140px;
$responsive-max-width: $content-max-width + 2 * $logo-width-buffer;

.page-layout--global-page {
  display: flex;
  flex-direction: row;
  overflow-y: hidden;

  .logo {
    position: fixed;
    top: 0;
    left: 0;

    height: $top-nav-height;
    display: flex;
    align-items: center;
    justify-content: flex-start;

    img {
      width: auto;
      height: 24px;
      margin-left: $gvds-spacing--200;
      margin-top: $gvds-spacing--100;
    }
  }

  .gutter {
    flex-grow: 0;
    flex-shrink: 0;
    flex-basis: calc((100vw - $content-max-width) / 2);
    background-color: $gvds-color--alpha;
  }

  .content-container {
    flex-grow: 1;
    background-color: $color-white;
    max-width: $content-max-width;
    padding: $gvds-spacing--200 $gvds-spacing--500;
    overflow-y: auto;
  }

  @media (max-width: $responsive-max-width) {
    flex-direction: column;

    .logo {
      position: relative;
      margin-left: $gvds-spacing--400;

      img {
        margin-top: 0;
        margin-left: 0;
      }
    }

    .gutter {
      flex-basis: auto;
    }

    .content-container {
      max-width: 100%;
    }
  }
}