$question-card--left-border: 8px;

@mixin question-card__container {
  background-color: $color-white;
  border-left: $question-card--left-border solid $color--gray4;
  border-right: 1px solid $color--gray4;
  background: linear-gradient($color--gray4, $color--gray4) bottom/100% 1px no-repeat,
  linear-gradient($color--gray4, $color--gray4) top/100% 1px no-repeat;
  border-radius: 4px;

  padding: $gvds-spacing--200;
}

@mixin question-card__container-active {
  @include question-card__container;

  border-left: $question-card--left-border solid $gvds-color--tealSolid;
}