@mixin gvds-empty-content {
  border-radius: 4px;
  border: 1px solid $color--gray3;
  background: $color--gray2;

  color: $color--gray9;

  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;

  width: 100%;
  text-align: center;
}

.gvds-empty-content {
  @include gvds-empty-content;

  min-height: 200px;
  padding: $gvds-spacing--200;
}

.gvds-empty-content--title {
  font-size: 18px;
  font-weight: 500;
  text-align: center;

  margin-bottom: $gvds-spacing--100;
}

.gvds-empty-content--icon {
  color: $color--gray9;
  margin-bottom: $gvds-spacing--100;

  svg {
    height: $gvds-spacing--400;
    min-height: $gvds-spacing--400;
    width: $gvds-spacing--400;
    min-width: $gvds-spacing--400;
  }
}

.table__no_content {
  @include gvds-empty-content;

  height: 16rem;
}