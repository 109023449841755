.gvds-icon-container {
  display: inline-flex;
  height: 24px;
  min-height: 24px;
  width: 24px;
  min-width: 24px;
  align-items: center;
  justify-content: center;
}

.gvds-icon-container--custom svg {
  height: 100%;
  width: 100%;
}