.landing-page__container {
  .top-section__container {
    .top-section__content {
      display: flex;
      flex-direction: row;
      margin-top: $gvds-spacing--100;

      .landing-page-header__message-container {
        .landing-page-header__message-welcome {
          font-size: 14px;
          font-weight: 600;
          margin-bottom: $gvds-spacing--100;
        }

        .landing-page-header__message-portal {
          font-size: 22px;
          font-weight: 600;
        }
      }

      .landing-page-header__illustration-container {
        text-align: right;
        margin-right: 40px;
        max-width: calc(100vw - $side-nav-width-minimised - 360px);

        .landing-page-header-illustration {
          height: 120px;
          width: auto;
          transform: translate(0px, 1px);
        }
      }
    }
  }

  .content-section__container {
    min-height: 100vh;

    .content-section__content {
      position: relative;

      .landing-page__tabs {
        position: absolute;
        top: -29px;
      }

      .tab-content {
        padding-top: $gvds-spacing--300;
        border-top: 1px solid $color--gray4;
      }
    }
  }
}

// region Widget Layout
.landing-page__row-widget-container {
  margin-bottom: $gvds-spacing--300;
}

.landing-page__row-widget-multi-col-container > * {
  margin-bottom: $gvds-spacing--300;
}

// endregion Widget Layout

// region Base Widget Styling
$widget-background-colour: $color--gray2;

.base-widget__container {
  background-color: $widget-background-colour;
  border-radius: 4px;
  padding: $gvds-spacing--300 $gvds-spacing--300 $gvds-spacing--200 $gvds-spacing--300;

  height: 100%;
  display: flex;
  flex-direction: column;

  > div:not(.base-widget__heading):not(.base-widget__heading-icon) {
    flex-grow: 1;
  }

  // TODO below shouldn't be in base
  .progress {
    min-width: 50%;
    width: 50%;
  }

  &.landing-page__box__survey-box,
  &.landing-page__box__data-requests-box {
    min-height: 0;
  }

  .landing-page__table {
    height: 80%;
    overflow-y: auto;

    table {
      th {
        color: $gray-56;
        border: none;
        padding: 10px 0 10px 0;
        background-color: white;
        position: sticky;
        top: 0;
      }

      th:nth-child(2),
      td:nth-child(2) {
        text-align: right;
        padding-right: 15px;
      }

      td {
        padding: 10px 0 10px 0;
      }
    }
  }

  .landing-page__user-role-label {
    @extend .caption;
    border-radius: 38px;
    padding: 4px 8px;

    &.admin {
      color: $blue;
      background-color: $light-blue;
    }

    &.contributor,
    &.viewer {
      color: $dark-gray;
      background-color: $gray-85;
    }
  }

  .landing-page__box__single-report-box {
    width: 270px;
    border: 1px solid $gray-93;
    border-radius: 2px;
    padding: 12px;
    margin-right: 10px;
    cursor: pointer;
    transition: 0.3s;
    box-shadow: 0 3px 5px $card-shadow;

    .landing-page__box__single-report-box__report-link {
      @extend .body-1;
      color: $blue;
    }

    &:hover {
      box-shadow: 0 8px 16px 0 $card-shadow;
    }
  }

  .landing-page__box__breakdown-details {
    width: calc(100% - 160px);
    position: relative;
    z-index: 99;
  }

  .landing-page__box__breakdown-image {
    color: white;
    background-color: $light-blue;
    width: 120px;
    height: 120px;
    border-radius: 60px;
    font-size: 90px;
    display: flex;
    flex-direction: column-reverse;
    align-items: center;
    position: absolute;
    top: 15px;
    right: 0;
    z-index: 1;
  }
}

.base-widget__container-clickable {
  @extend .base-widget__container;

  cursor: pointer;
  transition: 0.3s;

  &:hover,
  &.active {
  }
}

.base-widget__heading-icon {
  margin-bottom: $gvds-spacing--150;
  color: $gvds-color--tealSolid;

  .gvds-icon-container, .gvds-icon-container svg {
    height: 32px;
    min-height: 32px;
    width: 32px;
    min-width: 32px;
  }
}

.base-widget__heading {
  font-size: 18px;
  font-weight: 600;

  margin-bottom: $gvds-spacing--200;
  display: flex;
  align-items: center;
  flex-direction: row;

  .base-widget__heading__title-text {
    display: flex;
    align-items: center;
    flex-direction: row;
  }

  .base-widget__heading__subheader {
    display: inline;
    font-size: 14px;
    font-weight: 500;
  }

  .base-widget__heading-time-period, .base-widget__heading-period-control {
    margin-left: auto;
    color: $color--gray9;
    font-size: 14px;
    font-weight: 500;
  }

  .base-widget__heading-period-control__container {
    @include clickable;
    width: max-content;
    display: flex;
    flex-direction: row;

    border: 1px solid $color--gray4;
    padding: $gvds-spacing--50 $gvds-spacing--100 $gvds-spacing--50 $gvds-spacing--50;
    border-radius: 4px;

    &:hover {
      background-color: $color--gray3;
    }

    .base-widget__heading-period-control__period {
      margin-left: $gvds-spacing--50;
      line-height: 24px;
    }
  }

}

.base-widget__heading-period-control-dropdown__menu {
  padding: $gvds-spacing--300;
  border-radius: 4px;

  .base-widget__heading-period-control-dropdown__content {
    min-width: 400px;
  }

  .base-widget__heading-period-control-dropdown__footer {
    display: flex;
    margin-top: $gvds-spacing--300;
  }
}

@media screen and (max-width: $breakpoint-desktop) {
  .base-widget__heading {
    .base-widget__heading-time-period {
      margin-left: 0;
    }
  }
}

.base-widget__info-icon {
  margin-left: $gvds-spacing--50;
  align-self: center;
  cursor: pointer;
}

.base-widget-empty {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;

  width: 100%;
  height: 10rem;
  max-height: 80%;

  .base-widget-empty__icon {
    display: flex;
    align-content: center;
    font-size: 64px;
    margin-bottom: 11px;
    color: $gray-85;
  }

  .base-widget-empty__title {
    font-size: 18px;
    font-weight: 500;
    text-align: center;
    margin-bottom: $gvds-spacing--100;
  }

  .base-widget-empty__subtitle {
    font-size: 14px;
    font-weight: 400;
    text-align: center;
  }
}

.base-widget__generic-summary__layout-container {
  display: flex;
  flex-direction: column;
  justify-content: space-around;

  .base-widget__generic-summary__container:not(:last-child) {
    //padding-bottom: $gvds-spacing--200;
    //margin-bottom: $gvds-spacing--200;
    //border-bottom: 1px solid $color--gray4;
  }

  .base-widget__generic-summary__separator {
    border-bottom: 1px solid $color--gray4;
    margin-top: $gvds-spacing--100;
    margin-bottom: $gvds-spacing--100;
  }
}

.base-widget__generic-summary__container {
  display: flex;
  flex-direction: row;
  align-items: center;
}

.base-widget__generic-summary__icon {
  background-color: $gvds-color--tealLight;
  border-radius: 4px;
  padding: 7px;
  margin-right: $gvds-spacing--200;

  display: flex;
  align-items: center;

  .gvds-icon-container, svg {
    $icon-size: 40px;
    width: $icon-size;
    min-width: $icon-size;
    height: $icon-size;
    min-height: $icon-size;
  }

  svg path {
    stroke: $gvds-color--tealSolid;
  }
}

.base-widget__generic-summary__text-content {
  display: flex;
  flex-direction: column;
}

.base-widget__generic-summary__label {
  @include gvds-text--heading4;
  line-height: normal;
  margin-bottom: $gvds-spacing--50;
}

.base-widget__generic-summary__value-container {
  display: flex;
  flex-direction: row;
  align-items: center;
}

.base-widget__generic-summary__value-number {
  color: $gvds-color--tealSolid;
  font-size: 27px;
  font-weight: 600;
  line-height: normal;
}

.base-widget__generic-summary__unit {
  font-size: 14px;
  font-weight: 400;
  margin-left: $gvds-spacing--50;
}

// endregion Base Widget Styling

// region Overview Widgets
.widget--shortcut__content {
  display: grid;
  gap: 0 $gvds-spacing--300;
  grid-template-columns: 1fr 1fr;
  margin-left: -$gvds-spacing--150;

  a,
  a:link,
  a:visited,
  a:hover,
  a:focus,
  a:active {
    text-decoration: none;
  }

  .widget-shortcut__item {
    padding: $gvds-spacing--100 $gvds-spacing--75;
    border-radius: 4px;

    cursor: pointer;
    transition: 0.3s;
    color: $color--gray10;

    display: flex;
    align-items: center;

    .widget-shortcut__icon {
      margin-right: $gvds-spacing--100;
    }

    &:hover,
    &.active {
      background-color: $gvds-color--alpha;
    }
  }
}

.widget--helpful-tips__content {
  margin-left: -$gvds-spacing--100;

  .widget--helpful-tips__item {
    padding: $gvds-spacing--100 $gvds-spacing--75;
    border-radius: 4px;
    color: $color--gray10;

    display: flex;
    align-items: center;

    &:hover,
    &.active {
      background-color: $gvds-color--alpha;
    }
  }
}

.widget--new-look__container {
  background-color: $gvds-color--alpha;
  display: flex;
  flex-direction: column;
}

// endregion Overview Widgets

//region To Do Widgets
.widget-task-management__container {
  height: 362px;
  background-color: transparent;;
  padding: 0;
}

.only-my-task-switch {
  display: flex;

  span {
    @include gvds-text--inputField;
    margin-left: $gvds-spacing--50;
  }
}

.task-assignee_avatar {
  margin-left: -5px;
}

.task-management__container {
  flex-grow: 1;
  overflow: hidden;

  display: flex;
  flex-direction: column;

  .btn-create-task {
  }
}

.task-management__no-content {
  @include gvds-empty-content;
  flex-grow: 1;
  padding: $gvds-spacing--150;
}

.task-management__table-container {
  flex-grow: 1;
  overflow: auto;
  border-radius: 4px;
  border: 1px solid $gvds-border-color--table;

  .gvds-table-container .gvds-table-viewport {
    border: none;
    border-radius: 0;
  }

  .gvds-table-container tbody tr:last-child td {
    border-bottom: 1px solid $gvds-border-color--table;
  }
}

.task-management__table {
  vertical-align: middle;

  .task-column {
    min-width: 150px;
  }

  .task-column__content {
    // reference: https://stackoverflow.com/a/11877033
    max-width: 0;
  }

  .task-name__content {
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
  }

  .status-column {
    width: 135px;
  }

  .deadline-column {
    width: 130px;
  }

  .assignees-column {
    width: 130px;
  }

  .creator-column {
    width: 100px;
  }

  .action-column {
    width: 100px;
  }
}

.landing-page-widget__list__container {
  height: 180px;
  overflow-y: auto;

  display: flex;
  flex-direction: column;

  .landing-page-widget__list-item__container {
    display: flex;
    flex-direction: column;
    padding: $gvds-spacing--150;
    border-bottom: 1px solid $color--gray4;

    &:hover {
      background-color: $color--gray3;
    }

    .landing-page-widget__list-item__header {
      display: flex;
      flex-direction: row;

      .landing-page-widget__list-item__header-text {
        flex-grow: 1;
      }

      .landing-page-widget__list-item__header-date {
        text-align: right;
        white-space: nowrap;
      }
    }

    .landing-page-widget__list-item__subheader {
    }

    .landing-page-widget__list-item__progress {
      padding-top: $gvds-spacing--100;
      white-space: nowrap;
    }
  }
}

//endregion To Do Widgets

//region Performance Widgets
.widget--environmental-metrics__container {
  width: 100%;
}

.widget--environmental-metrics__content {
  width: 100%;
  display: grid;
  grid-template-columns: 1fr 1fr 1fr;
  gap: $gvds-spacing--300;

  @media screen and (max-width: $breakpoint-laptop) {
    grid-template-columns: 1fr 1fr;
  }

  .widget--environmental-metrics__performance_group__container {
    cursor: pointer;
    transition: 0.3s;

    &:hover,
    &.active {
    }
  }
}

.widget--environmental-metrics__box {
  background: $color-white;
  border: 1px solid $color--gray4;
  border-radius: 4px;
  padding: $gvds-spacing--200;

  display: flex;
  flex-direction: column;
  height: 100%;
  min-height: 190px;

  .widget--environmental-metrics__box__label {
    font-size: 14px;
    font-weight: 600;
    margin-bottom: $gvds-spacing--150;
  }

  .widget--environemntal-metrics__box__selector-container {
    margin-top: $gvds-spacing--200;
  }

  .widget--environmental-metrics__box__value-container {
    display: flex;
    flex-direction: row;
    align-items: center;
    margin-top: $gvds-spacing--200;
    margin-bottom: $gvds-spacing--150;
  }

  .widget--environmental-metrics__box__value-number {
    font-size: 34px;
    font-weight: 700;
    max-width: 300px;
  }

  .widget--environmental-metrics__box__unit {
    font-size: 14px;
    font-weight: 500;
    margin-left: $gvds-spacing--50;
  }

  .widget--environmental-metrics__box__status-container {
    display: flex;
    flex-direction: row;
    align-items: center;
    height: 24px;

    .widget--environmental-metrics__box__metric-variance-status {
      padding-left: 0;
    }

    .status-label {
      height: 24px;
      padding: $gvds-spacing--50;
    }

    .widget--environmental-metrics__box__variance-icon {
      .icon-placeholder {
        width: $gvds-spacing--50;
      }

      .gvds-icon-container {
        margin-right: 0;
      }

      &.green svg {
        stroke: $gvds-color--tealSolid;
      }

      &.red svg {
        stroke: $gvds-color--error;
      }
    }

    .widget--environmental-metrics__box__variance-value {
      color: $color--gray10;
    }
  }
}

.widget--environmental-metrics__manage-site-details-box {
  height: 100%;
  display: flex;
  flex-direction: column;

  padding: $gvds-spacing--200;
  background-color: $gvds-color--alpha;
  border-radius: 4px;

  .widget--environmental-metrics__manage-site-details-box__icon-container {
    margin-bottom: $gvds-spacing--100;

    .gvds-icon-container, svg {
      height: $gvds-spacing--400;
      min-height: $gvds-spacing--400;
      width: $gvds-spacing--400;
      min-width: $gvds-spacing--400;
    }

    svg {
      stroke: $gvds-color--tealSolid;
    }
  }
}

.widget--social-impact__content {
  @include clickable;
}

.widget--target__content {
}

.widget--initiatives__content {
  @include clickable;

  display: flex;
  flex-direction: column;
  align-items: stretch;
  justify-content: space-around;

  .widget--initiatives__subtopic-summary__container {
    display: flex;
    flex-direction: column;

    &:not(:last-child) {
      padding-bottom: $gvds-spacing--200;
      margin-bottom: $gvds-spacing--200;
      border-bottom: 1px solid $color--gray4;
    }
  }

  .widget--initiatives__subtopic-summary__values-container {
    display: grid;
    grid-template-columns: 1fr 1fr 1fr 1fr;

    > div {
      display: flex;
      align-items: center;
    }
  }

  .widget--initiatives__subtopic-summary__subtopic {
    font-size: 14px;
    font-weight: 600;
  }

  .widget--initiatives__subtopic-summary__total-container {
    justify-content: flex-start;
  }

  .widget--initiatives__subtopic-summary__breakdown-container {
    justify-content: flex-end;
  }

  .widget--initiatives__subtopic-summary__total-value {
    color: $gvds-color--tealSolid;
    font-size: 14px;
    font-weight: 700;
    margin-right: $gvds-spacing--50;
  }

  .widget--initiatives__subtopic-summary__breakdown-value {
    font-size: 14px;
    font-weight: 700;
    margin-right: $gvds-spacing--50;
  }
}

.widget--best-practice__container {
  .widget--best-practice__content {
    display: grid;
    grid-template-columns: 1fr 1fr 1fr 1fr;
    gap: $gvds-spacing--300;

    @media screen and (max-width: $breakpoint-desktop) {
      grid-template-columns: repeat(auto-fit, minmax(80px, 1fr));
    }

    &.no-content {
      grid-template-columns: 1fr;
    }
  }

  .widget--best-practice__category-display__container {
    display: flex;
    flex-direction: column;
    align-items: baseline;
    margin-bottom: $gvds-spacing--50;
  }

  .widget--best-practice__category-display__icon {
    @extend .base-widget__generic-summary__icon;
    margin-bottom: $gvds-spacing--150;
  }

  .widget--best-practice__category-display__label {
    @extend .base-widget__generic-summary__label;
  }

  .widget--best-practice__category-display__value-container {
    @extend .base-widget__generic-summary__value-container;
  }

  .widget--best-practice__category-display__value-number {
    @include gvds-text--heading3;
    color: $gvds-color--tealSolid;
  }
}

//endregion Performance Widgets

//region Homepage Announcement

$homepage-announcement-icon-size: 32px;

.widget--homepage-announcement__container {
  position: relative;
  height: 100%;

  .widget--homepage-announcement__nav {
    position: absolute;
    top: $gvds-spacing--500;
    right: $gvds-spacing--150;
    transform: translateY(-50%);
    z-index: 10;

    .widget--homepage-announcement__nav__container {
      display: flex;
      align-items: center;
      justify-content: center;

      .gvds-icon-button__tertiary:disabled {
        background-color: transparent;
      }

      .widget--homepage-announcement__nav-dots {
        display: flex;
        flex-direction: row;
        gap: $gvds-spacing--100;
        margin-left: $gvds-spacing--50;
        margin-right: $gvds-spacing--50;

        .widget--homepage-announcement__nav-dots__item {
          width: 8px;
          height: 8px;
          border-radius: 50%;
          background-color: $color--gray4;
          border: none;
          padding: 0;
          cursor: pointer;
        }

        .widget--homepage-announcement__nav-dots__item.active {
          background-color: $gvds-color--tealSolid;
        }
      }

    }
  }
}


.widget--homepage-announcement__display__container {
  background-color: $gvds-color--tealPale;
  padding: $gvds-spacing--300;
  height: 300px;
  display: flex;
  flex-direction: column;

  .widget--homepage-announcement__display__heading {
    margin-bottom: $gvds-spacing--200;

    .widget--homepage-announcement__display__heading-title {
      @include gvds-text--heading3;
    }

    .widget--homepage-announcement__display__heading-icon {
      margin-bottom: $gvds-spacing--150;
      color: $gvds-color--tealSolid;
      height: $homepage-announcement-icon-size;

      .gvds-icon-container, .gvds-icon-container svg {
        height: $homepage-announcement-icon-size;
        min-height: $homepage-announcement-icon-size;
        width: $homepage-announcement-icon-size;
        min-width: $homepage-announcement-icon-size;
      }
    }
  }

  .widget--homepage-announcement__display__content {
    flex-grow: 1;
    overflow: auto;
    min-height: 50px;
    margin-bottom: $gvds-spacing--200;
  }

  .widget--homepage-announcement__display__cta-buttons {
    display: flex;
    flex-direction: column;
    gap: $gvds-spacing--100;
    align-items: flex-start;

    .gvds-button {
      width: auto;
      max-width: 100%;
      margin-left: 0;
    }

    .gvds-button + .gvds-button {
      margin-left: 0;
    }
  }
}

.homepage-announcement-popup {
  .homepage-announcement-popup__title-icon {
    color: $gvds-color--tealSolid;
    height: $homepage-announcement-icon-size;

    .gvds-icon-container, .gvds-icon-container svg {
      height: $homepage-announcement-icon-size;
      min-height: $homepage-announcement-icon-size;
      width: $homepage-announcement-icon-size;
      min-width: $homepage-announcement-icon-size;
    }
  }

  .homepage-announcement-popup__content {
    background-color: $gvds-color--tealPale;

    .homepage-announcement-popup__content-title {
      @include gvds-text--heading3;
      margin-bottom: $gvds-spacing--200;
    }

    .homepage-announcement-popup__display__content {
      flex-grow: 1;
      overflow: auto;
      min-height: 50px;
      margin-bottom: $gvds-spacing--50;
    }
  }
}

.homepage-announcement-modal .modal-footer > * {
  margin: 0;
}

.homepage-announcement-popup .modal-footer {
  display: flex;
  flex-direction: column;
  padding: 0 $gvds-spacing--300 $gvds-spacing--250 $gvds-spacing--300;
  margin: 0;

  .gvds-button {
    margin-left: 0;
    width: 100%;
  }

  .gvds-button + .gvds-button {
    margin-left: 0;
    margin-top: $gvds-spacing--100;
  }
}

//endregion Homepage Announcement

