// region Links

.btn-link.btn-sm {
  padding: 0 2px;
  margin-right: 8px;
  line-height: 1em;
  font-size: 1em;
  text-align: left;
}

.text-button {
  @include clickable;

  color: $link-color;
  text-decoration: none;

  &:hover {
    color: $link-color;
    text-decoration: underline;
  }
}

.btn-group {
  button {
    @extend .body-1-bold;
    padding: 2px 25px;
  }
}

.text-tooltip-trigger {
  color: $link-color;
  cursor: pointer;

  &:hover {
    text-decoration: underline;
  }
}

// endregion

.border-gray {
  border-color: $color--gray4;
}

.text-vertical-center {
  display: flex;
  align-items: center;
}

.visibility-hidden {
  visibility: hidden;
}

.custom-range-slider-container {
  input[type="range"] {
    -webkit-appearance: none;
    appearance: none;
    background: transparent;
    cursor: pointer;
    width: 15rem;
  }

  input[type="range"]:focus {
    outline: none;
  }

  input[type="range"]:disabled {
    cursor: default;

    &::-webkit-slider-runnable-track {
      background: $color--gray4;
    }

    &::-webkit-slider-thumb {
      background-color: $color--gray6;

      &:hover {
        box-shadow: none;
      }
    }
  }

  input[type="range"]::-webkit-slider-runnable-track {
    background: $gvds-color--alpha;
    border-radius: 2px;
    height: 4px;
  }

  input[type="range"]::-webkit-slider-thumb {
    -webkit-appearance: none;
    appearance: none;
    background-color: $gvds-color--tealSolid;
    margin-top: -8px;
    height: 20px;
    width: 20px;
    border-radius: 10px;

    &:hover {
      box-shadow: 0 0 0 10px $gvds-color--alpha;
    }
  }

  input[type="range"]::-moz-range-track {
    background: $gvds-color--alpha;
    border-radius: 2px;
    height: 4px;
  }

  input[type="range"]::-moz-range-thumb {
    border: none;
    background-color: $gvds-color--tealSolid;
    height: 20px;
    width: 20px;
    border-radius: 10px;

    &:hover {
      box-shadow: 0 0 0 10px $gvds-color--alpha;
    }
  }
}

.collapse-toggle {
  color: $gvds-color--tealSolid;
  font-style: italic;
  cursor: pointer;
}

.container-for-item-in-form-table {
  @extend .form-control-plaintext;
  margin-bottom: 0;
  padding: 0;
  line-height: normal;
  background-color: transparent;
  border: 0 solid transparent;
}

// region Colors
.color-black {
  color: $black;
}

.color-blue {
  color: $gvds-color--info;
}

.color-yellow {
  color: $gvds-color--warning;
}

.color-dark-yellow {
  color: $dark-yellow;
}

.color-gray85 {
  color: $gray-85;
}

.color-gray56 {
  color: $gray-56;
}

.color-gray36 {
  color: $gray-36;
}

.color-green {
  color: $gvds-color--tealSolid;
}

.color-dark-green {
  color: $dark-green;
}

.color-red {
  color: $gvds-color--error !important;

  .disabled & {
    opacity: 0.65;
  }
}
// endregion

// region section box
.section-box {
  border-bottom: 1px solid $gvds-border-color--default;
  margin-bottom: $gvds-spacing--300;

  header {
    h2 {
      margin: 0;
    }
  }

  article {
    padding: $gvds-spacing--100 0 $gvds-spacing--200 ;

    h3 {
      margin-top: $gvds-spacing--100;
    }
  }

  article.compact {
    padding: $gvds-spacing--100 0;

    .form-group {
      margin-bottom: 0;
    }
  }
}

// endregion
