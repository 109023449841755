//Override existing style
.react-datepicker__month {
  max-width: 210px;

  .react-datepicker__month-text {
    @include gvds-form__shared-calendar-content-style;

    &:hover {
      background-color: $color-brand-green-light;
    }

    &--disabled {
      pointer-events: none;
    }

    &--selected {
      @include gvds-form__shared-calendar-content-style;
      color: $white;
      background-color: $color-brand-green-dark;

      &:hover {
        background-color: $color-brand-green-dark;
      }
    }
  }
}

//End of override

.gvds-form__single-monthpicker-input {
  @include gvds-form__shared-picker-input-style;
}

.gvds-form__start-end-monthpicker__container {
  @include gvds-form__start-end-picker-container-style;

  .start-month__container {
  }

  .end-month__container {
  }

  .invisible-month-label {
    visibility: hidden;
  }
}


