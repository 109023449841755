$title-color: $color--gray10;

// region Override base Modal for any custom Modal needed
.modal-header {
  border-bottom: none;
}

.modal-footer {
  border-top: none;
}

// endregion

.modal-backdrop {
  z-index: $gvds-z-index--modal-backdrop;
}

.gvds-modal {
  z-index: $gvds-z-index--modal;

  .gvds-modal-size-- {
    &small {
      max-height: 48vh;

      @media (min-width: $breakpoint-laptop) {
        width: 59.7vw;
        max-width: 59.7vw;
      }
      @media (min-width: $breakpoint-desktop) {
        width: 30.4vw;
        max-width: 30.4vw;
      }
      @media (min-width: $breakpoint-large-desktop) {
        width: 30.4vw;
        max-width: 30.4vw;
      }
    }

    &medium {
      max-height: 72vh;

      @media (min-width: $breakpoint-laptop) {
        width: 82.9vw;
        max-width: 82.9vw;
      }
      @media (min-width: $breakpoint-desktop) {
        width: 46.5vw;
        max-width: 46.5vw;
      }
      @media (min-width: $breakpoint-large-desktop) {
        width: 46.5vw;
        max-width: 46.5vw;
      }
    }

    &large {
      max-height: 72vh;

      @media (min-width: $breakpoint-laptop) {
        width: 82.9vw;
        max-width: 82.9vw;
      }
      @media (min-width: $breakpoint-desktop) {
        width: 66vw;
        max-width: 66vw;
      }
      @media (min-width: $breakpoint-large-desktop) {
        width: 66vw;
        max-width: 66vw;
      }
    }

    &xl {
      max-height: 90vh;

      @media (min-width: $breakpoint-laptop) {
        width: 82.9vw;
        max-width: 82.9vw;
      }
      @media (min-width: $breakpoint-desktop) {
        width: 82.9vw;
        max-width: 82.9vw;
      }
      @media (min-width: $breakpoint-large-desktop) {
        width: 82.9vw;
        max-width: 82.9vw;
      }
    }
  }

  .modal-content {
    box-shadow: 0 4px 6px -2px rgba(21, 29, 29, 0.03), 0px 12px 16px -4px rgba(21, 29, 29, 0.08);
    border: none;
    border-radius: 4px;
  }

  .modal-header {
    padding: $gvds-spacing--300 $gvds-spacing--300 $gvds-spacing--200 $gvds-spacing--300;
  }

  .modal-title {
    color: $title-color;
    font-size: 22px;
    font-style: normal;
    font-weight: 600;
    line-height: normal;
  }

  .modal-body {
    padding: 0 $gvds-spacing--300;

    &:last-child {
      // this means no footer
      padding-bottom: $gvds-spacing--300;
    }
  }

  .modal-footer {
    padding: $gvds-spacing--200 $gvds-spacing--300;

    .gvds-button ~ .gvds-button {
      margin-left: $gvds-spacing--100;
    }
  }
}
