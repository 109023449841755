.star-display {
  color: $gvds-color--warning;
}

// region Terms and Conditions

.btn-terms_condition {
  width: 100%;
}

.terms-dialog .terms-of-use-container {
  overflow-y: scroll;
  max-height: 320px;
  padding: $gvds-spacing--150;

  border: 1px solid $color--gray10;
  border-radius: 4px;
}

// endregion Terms and Conditions

// region Help Center
.help-center-subtitle-icon {
  width: 30px;
  flex-shrink: 0;
}

.help-center-article-show-more-btn {
  color: $color--gray6;
  display: flex;
  align-items: center;
}

.help-center__container {
  .article-search-box {
    max-width: 800px;
  }
}

.help-center__language-selector {
  display: flex;
  align-items: center;
  margin-left: $gvds-spacing--100;
}

.help-center__language-divider {
  margin-right: $gvds-spacing--100;
  font-weight: bold;
}

.help-center__articles-in-other-languages-info {
  margin: $gvds-spacing--100;
}

.help-center__v-spacing-before-content {
  margin-bottom: $gvds-spacing--300;
}

.help-center-category-name {
  @include gvds-text--heading3;
}

.help-center-article {
  margin-bottom: 0.25rem;

  display: flex;
  flex-direction: row;
  align-items: center;

  .help-center-article-icon {
    color: $gvds-color--tealSolid;
    margin-right: $gvds-spacing--50;
  }

  .help-center-article-link.btn.btn-link.btn-sm {
    @include gv-text-16;
  }
}

// endregion Help Center

// region Help Guides
$help-article-max-width: 80%;
$help-article-side-width: 20%;
.help-article {
  flex-grow: 1;
  padding: 0 $gvds-spacing--200 0 0;
  max-width: $help-article-max-width;
}

.help-article-side-info {
  @extend .gvds-info-card;
  width: $help-article-side-width;
  max-width: $help-article-side-width;
  overflow-wrap: break-word;
  max-height: 90vh;
  overflow-y: scroll;
  margin-bottom: 0;

  ul {
    padding-inline-start: 1rem;
  }

  li {
    margin-top: 3px;
    margin-bottom: 3px;
  }
}

.help-article-pdf-container {
  width: 100%;
  height: 100%;
  overflow-y: scroll;
}

.help-article-video-container {
  width: 100%;

  video {
    width: 100%;
  }
}

.help-article__no-content {
  margin-top: 80px;
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;

  h3 {
    margin-top: 11px;
  }
}

.help-article-type-display {
  padding: 0 $gvds-spacing--75 0 0;

  .help-article-type-display-icon {
    svg {
      width: 14px;
      height: 14px;
    }
  }
}

// endregion Help Guides

// region Bulk Input
.bulk-input-ctrl__container {
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  margin-bottom: $gvds-spacing--100;
}

.bulk-input-ctrl__data-ctrl {
  display: flex;
  align-items: center;

  .bulk-input-ctrl__row-count {
    margin-left: $gvds-spacing--100;
    white-space: nowrap;
  }
}

.bulk-input-ctrl__action-ctrl {
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  justify-content: flex-end;
  margin-left: auto;

  > .gvds-button {
    margin-left: $gvds-spacing--50;
  }
}

.bulk-input-cell__icon {
  display: inline-block;
  vertical-align: 0.15em;
  width: 1em;
  margin-right: $gvds-spacing--25;

  svg {
    transform: translate(-4px, 0);
  }
}

.bulk-date-input__container {
  white-space: nowrap;

  .gvds-icon-container {
    min-height: 15px;
    min-width: 15px;
  }

  svg {
    width: 15px;
    height: 15px;
  }
}

.bulk-data-input__datasheet {
  height: calc(100vh - 4rem);

  .bulk-data-input__datasheet-table {
    background: $gray-98;
    width: 100%;
    min-height: 10rem;
    overflow: auto;
    margin-bottom: 1rem;
    border: 2px solid #ddd;
    height: 100%;

    table {
      width: 100%;

      thead th {
        box-shadow: inset 0 1px $gray-90, 0 1px $gray-90;
        position: sticky;
        top: 0;
      }

      td.cell.read-only,
      th.cell.read-only {
        background-color: $gray-98;
        color: $gray-56;
      }
    }

    .bulk-data-input-datasheet-action-cell {
      text-align: center;
      color: $gvds-color--error;
    }

    .data-input-cell-data-error {
      border: 1px solid $red;
      background-color: $light-red;
      height: 100%;
    }

    .data-input-cell-possible-error {
      border: 1px solid $yellow;
      background-color: $light-yellow;
      height: 100%;
    }
  }

  .data-grid-container .data-grid .cell > input {
    width: 100%;
    height: 100%;
  }

  .data-grid-container .data-grid .cell {
    background: white;
    color: black;
  }

  .data-grid-container .data-grid .cell.editing {
    width: inherit;

    > div.react-datepicker-wrapper,
    > div > div.react-datepicker__input-container,
    > div > div.react-datepicker__input-container input {
      width: 99%;
    }
  }
}

.data-grid-container .data-grid .cell {
  text-align: left;
  vertical-align: top;
}

// endregion

// region Initiatives
.initiative-input__subtopics-container {
  display: flex;
  align-items: stretch;

  .initiative-input-form__box {
    @extend .filter-card;

    padding: 8px 16px 16px;
    flex: 1 1 0; // for same width
    min-height: 85px;
    margin-right: 20px;
    cursor: pointer;

    &.disabled {
    }

    .subtopic-name {
      @include gvds-text--heading4;
      margin-top: 8px;
      margin-bottom: 4px;
    }

    .subtopic-description {
      @include gvds-text--body;
    }
  }
}

.initiative__locked-content {
  @extend .table__no_content;
  height: 100px;
}

.initiative__tracking-criterion-container {
  border: 1px solid $gvds-border-color--default;
  background-color: $color-tealUltraLightBg;
  padding: $gvds-spacing--200;
  margin-top: $gvds-spacing--200;
  margin-bottom: $gvds-spacing--200;
}

.tracking-criteria-record-label {
  text-transform: uppercase;
  color: $color--gray9;
  font-weight: 700;
  margin-right: 5px;
}


.initiative__name-column-display {
  text-align: start;
  padding: 0;
  margin-top: -4px;
}

.initiative__name-column-display__name {
  @include table-data__main-text;
}

.initiative__name-column-display__story {
  @include table-data__description-text;

  // from https://stackoverflow.com/a/19049457
  overflow: hidden;
  text-overflow: ellipsis;
  display: -webkit-box;
  -webkit-line-clamp: 3;
  -webkit-box-orient: vertical;
}

.initiative__action-column {
  text-align: center;
  vertical-align: middle;
}

// endregion Initiatives

// region Reports
.best-practice-report-config {
  .gvds-table-container .gvds-table-viewport {
    border-radius: 0;
  }

  .gvds-tag {
    margin-bottom: $gvds-spacing--75;
  }

  .report-config-footer {
    border-radius: 0 0 4px 4px;
    border: 1px solid $color--gray4;
    border-top: none;
  }
}

// endregion

// region Goals and Targets

.goal-target-model-illustration {
  max-height: 200px;
  height: auto;
  max-width: 80%;
  width: auto;

  margin-bottom: 16px;
}

.view-all-goals-table__container {
  .view-all-goals-table__row-container {
    display: flex;
    align-items: center;
    padding-right: $gvds-spacing--100;

    .view-all-goals-table__row__index {
      min-width: 3em;
      text-align: right;
      margin-right: $gvds-spacing--300;
    }

    .view-all-goals-table__row__name {
      flex-grow: 1;
      margin-right: $gvds-spacing--300;
    }
  }
}

.view-all-goals__no-content {
  @include gvds-empty-content;

  height: 10rem;

  h3 {
    margin-top: 11px;
  }

  div {
    width: 400px;
    text-align: center;
  }

  .view-all-goals__no-content-icon {
    @extend .gvds-empty-content--icon;
  }
}

.target-details__container {
  .target-summary-box {
    border-radius: 4px;
    border: 1px solid $default-border-color;
    padding: $gvds-spacing--150;
    min-height: 120px;
    height: 100%;

    .gvds-icon-container {
      width: 20px;
      min-width: 20px;
      margin-right: $gvds-spacing--50;
    }

    &.target-summary-box--with-medal {
      border: 1px solid $gvds-color--success;
      box-shadow: 4px 4px 0 $gvds-color--success;
    }

    h1 {
      margin-bottom: 0;
    }

    .target-value__container {
      max-width: 100%;
      display: flex;
      align-items: baseline;
      flex-wrap: wrap;
    }

    .target-value__value {
      display: inline-block;
      max-width: 100%;
    }
  }

  .target-details__run-report-container {
    border-radius: 4px;
    border: 1px solid $gvds-border-color--default;
    text-align: center;
    display: flex;
    flex-direction: column;
    align-items: center;
    padding: $gvds-spacing--300;
    margin: $gvds-spacing--150 0 0 $gvds-spacing--100;

    h3 {
      margin-top: -37px;
      background-color: $color-white;
      width: 345px;
      max-width: fit-content;
    }
  }

  .target-details__market-purchase-message {
    @extend .caption;
    padding: 12px 10px 0 10px;
    margin-top: 5px;
  }

  .target-details__progress {
    display: flex;
    align-items: center;

    .progress {
      display: flex;
      align-items: center;
      height: 1rem;
      background-color: $gvds-color--tealLight;
      border-radius: 0.5rem;
      padding: 0.1rem;
      width: 75%;
      min-width: 75%;

      .progress-bar {
        height: 0.75rem;
        border-radius: 0.375rem;
        background-color: $gvds-color--tealSolid;
      }
    }
  }

  .target-details__comment-container {
    height: 150px;
    overflow: auto;
  }

  .target-details__un-sdgs-container {
    display: flex;
    flex-wrap: wrap;

    .target-details__un-sdgs-container__pill {
      background-color: $gray-36;
      border-radius: 3px;
      padding: 4px 6px;
      color: $color-white;
      margin-right: 5px;
    }
  }

  .view-target-details__no-meter-setup {
    @extend .view-all-goals__no-content;
    height: 16rem;
  }
}

.create-target-form__stepper {
  margin-left: $gvds-spacing--300;
  margin-right: $gvds-spacing--300;
}

.create-target-form {
  $form-height: 80vh;

  .input-target-modal__layout {
    display: grid;
    grid-template-columns: 3fr 2fr;
    column-gap: $gvds-spacing--250;
  }

  .create-target-form__steps {
    height: $form-height;
    overflow: auto;
  }

  .create-target-form__preview {
    max-height: $form-height;
    overflow-x: auto;
  }

  .create-target-form__items-container {
    display: grid;
    grid-template-columns: 1fr 1fr 1fr;
    column-gap: $gvds-spacing--200;

    .create-target-form__box {
      @extend .filter-card;

      cursor: pointer;

      padding: $gvds-spacing--100;
      height: 100%;
      min-height: 135px;

      .target-topic-name {
        @include gvds-text--heading4;
        margin-bottom: $gvds-spacing--100;
      }

      .target-topic-description {
      }
    }
  }

  .create-target-form__portfolio-steps {
    width: 100%;
  }

  .create-target-form__locked-content {
    @extend .table__no_content;
    height: 100px;
  }

  input {
    margin-bottom: 0;
  }

  .dropdown-menu {
    padding: 0;
  }

  .create-target-form__collective-target-value-instruction {
    margin-bottom: 16px;
  }

  .create-target-form__collective-target-preview-name {
    font-size: 18px;
    font-weight: 600;
    margin-bottom: 48px;
  }

  .create-target-form__quick-fill-label {
    @include gvds-text--formLabel;
  }

  .create-target-form__target-value-input-table {
    border-collapse: separate;
    border-spacing: 0;

    th {
      border: 1px solid $gvds-border-color--table;
      background: $color-tealUltraLightBg;
      padding: 3px;
      height: 36px;
      vertical-align: middle;
    }

    td {
      border: 1px solid $gvds-border-color--table;
      background: $color-tealUltraLightBg;
      padding: 3px;
      vertical-align: middle;
    }

    input {
      background: none;
      border: none;

      &:focus {
        background-color: $color-white;
        // follow GVDS form field
        border: 1px solid $focus-border-color;
        box-shadow: 0 0 3px 0 $focus-border-color;
      }
    }

    tr td:last-child {
      background: $color-white;

      &:focus-within {
        border: 1px solid $focus-border-color;
        background-color: $color-white;
      }
    }
  }

  .create-target-form__collective-target-value__container {
    padding: 16px;
  }
}

.target-filter__collective-target-only__container {
  display: flex;
  margin-left: $gvds-spacing--50;

  span {
    @include gvds-text--inputField;
  }
}

.collective-target-details__container {
  @extend .target-details__container;

  .collective-target-details__un-sdgs-container {
    @extend .target-details__un-sdgs-container;

    .collective-target-details__un-sdgs-container__pill {
      @extend .target-details__un-sdgs-container__pill;
    }
  }
}

.collective-target-details__title-label {
  height: fit-content;
  margin-top: 10px;
}

.target-preview-container {
  background-color: $gvds-color--tealLight;
  border-radius: 4px;
  width: 100%;
  padding: 25px;

  .target-preview__header-title {
    @include gvds-text--heading4;
  }

  .target-preview__name {
    margin-top: $gvds-spacing--100;
    @include gvds-text--heading3;
  }

  .target-preview-container__no-preview {
    height: 100px;
    margin-top: $gvds-spacing--150;
  }

  .target-preview-table-container {
    margin-top: $gvds-spacing--250;

    .target-preview-table {
      margin-bottom: 0;

      th {
        font-weight: bold;
        font-size: 16px;
        line-height: 21px;
      }

      th,
      td {
        padding: 2px;
        border-top: none;
      }

      tr:first-child th {
        border-bottom: 4px double $color--gray10;
      }

      th:nth-child(2),
      td:nth-child(2) {
        text-align: right;
      }
    }
  }
}

@mixin target-box--content-padding {
  padding-left: $gvds-spacing--200;
  padding-right: $gvds-spacing--200;
}

$target-box--height: 180px;
$target-summary--height-on-preview: 124px;

.view-all-targets-container {
  display: grid;
  grid-template-columns: 1fr 1fr 1fr;
  gap: $gvds-spacing--250;

  @media screen and (max-width: $breakpoint-desktop) {
    grid-template-columns: 1fr 1fr;
  }

  .target-box {
    @include card-style;

    cursor: pointer;
    padding-top: $gvds-spacing--200;
    padding-bottom: $gvds-spacing--200;
    height: $target-box--height;

    transition: all ease 0.3s;

    &:hover {
      @include gvds-shadow--large;
    }

    &.target-box--open {
      height: fit-content;

      .target-box--summary {
        min-height: $target-summary--height-on-preview;

        .target-box--summary__target-name {
          overflow: initial;
          text-overflow: initial;
          word-break: break-word;
          display: -webkit-box;
          -webkit-line-clamp: unset;
        }
      }
    }

    .target-box--content-container {
      display: flex;
      flex-direction: column;
      height: 100%;
    }

    .goal-name {
      width: 100%;
      margin-bottom: $gvds-spacing--100;
      @include target-box--content-padding;

      font-size: 12px;
      font-weight: 400;
    }

    .target-box--summary {
      display: flex;
      flex-direction: column;
      height: 100%;

      .target-box--summary__target-name {
        font-weight: 500;
        font-size: 14px;
        margin-bottom: $gvds-spacing--100;
        @include target-box--content-padding;

        overflow: hidden;
        text-overflow: ellipsis;
        word-break: break-word;
        display: -webkit-box;
        -webkit-line-clamp: 4;
        -webkit-box-orient: vertical;
      }
    }

    .status-container {
      display: flex;
      flex-direction: row;
      align-items: center;
      margin-bottom: $gvds-spacing--100;
      @include target-box--content-padding;

      .gvds-icon-container {
        height: 16px;
        min-height: 16px;
        width: 16px;
        min-width: 16px;
      }
    }

    .preview-control {
      margin-top: auto;
      @include target-box--content-padding;
      padding-top: $gvds-spacing--100;
      display: flex;
      align-items: center;

      .btn-link {
        color: $gvds-color--tealSolid;
        font-size: 14px;
        font-weight: 600;
      }
    }

    .target-box--preview-container {
      border-top: 1px solid $color--gray4;
      margin-top: $gvds-spacing--200;
      padding-top: $gvds-spacing--200;
      @include target-box--content-padding;

      .achieve-status--container {
        margin-bottom: $gvds-spacing--200;
      }

      .target-values-container {
        display: flex;
        justify-content: space-between;
        margin: 10px 0 5px 0;

        .target-value-box {
          width: 49%;
          border: 1px solid $gray-85;
          border-radius: 4px;
          padding: 10px 15px;
          min-height: 90px;

          .target-value-box__header {
            @extend .body-1;
            display: flex;
            justify-content: space-between;
            align-content: center;
          }

          h2 {
            margin-bottom: 0;
          }
        }
      }

      .target-preview--subheader {
        color: $color--gray9;
        font-size: 14px;
        font-weight: 500;
        margin-bottom: $gvds-spacing--75;
        display: flex;
      }

      .target-preview--target-value {
        margin-bottom: $gvds-spacing--200;
      }

      .target-preview--current-value {
        margin-bottom: $gvds-spacing--100;
      }

      .target-preview--difference-value {
        color: $color--gray9;
        font-size: 12px;
        font-weight: 400;

        display: flex;

        &.achieved {
          color: $gvds-color--tealSolid;
        }

        .target-preview--difference--value-text {
          overflow: hidden;
          text-overflow: ellipsis;
        }

        .target-preview--difference--unit-text {
          margin-left: $gvds-spacing--50;
        }
      }

      .target-preview--value-container {
        display: flex;
        align-items: baseline;

        .target-preview--value-text {
          color: $color--gray10;
          font-size: 22px;
          font-weight: 600;
          overflow: hidden;
          text-overflow: ellipsis;
        }

        .target-preview--unit-text {
          margin-left: $gvds-spacing--50;
          font-size: 14px;
          font-weight: 500;
        }
      }

      .data-status-message--container {
        padding: $gvds-spacing--75;
        margin-top: $gvds-spacing--100;
        margin-bottom: $gvds-spacing--100;
        border-radius: 4px;

        background-color: rgba(255, 185, 6, 0.12);

        display: flex;
        align-items: center;

        .data-status-message--icon-container {
          margin-right: $gvds-spacing--50;
          display: flex;
          align-items: center;
        }

        .data-status-message--text {
          font-size: 12px;
          font-weight: 500;
        }
      }
    }
  }
}

.view-all-targets__no-content {
  @extend .view-all-goals__no-content;
  height: 11rem;
  margin: $gvds-spacing--100 0;
}

.target-value-error-message {
  color: $red;
}

.portfolio-targets__container {
  margin-top: 32px;
}

.portfolio-targets__no-content {
  @extend .view-all-goals__no-content;
  height: 16rem;
}

// endregion Goals and Targets

// region GSTC
$gstc-content-height: calc(100vh - 12rem);

.gstc__page-container {
  background-color: $color-white;
  width: 100%;
  height: 100%;

  display: flex;
  flex-direction: column;
}

.gstc__content-container {
  flex: 1 1 auto;

  display: flex;
  margin-top: $gvds-spacing--100;
  width: 100%;
}

.gstc__sidebar_container {
  width: 35%;
  min-width: 400px;
  max-width: 550px;
  padding-right: $gvds-spacing--200;

  height: $gstc-content-height;
  overflow: hidden;

  display: flex;
  flex-direction: column;
}

.gstc-chapters__list-container {
  padding: 0;
  border-radius: 0 0 4px 4px;
  border: 1px solid $color--gray4;
  border-top: none;

  overflow-y: auto;

  .chapter-name-search-box__container {
    position: sticky;
    top: 0;

    padding: $gvds-spacing--100;

    background-color: $color-white;
    border-bottom: 1px solid $color--gray4;
  }

  $gstc-list-bg-color: $color--gray2;

  .gstc-chapters__list {
    background-color: $gstc-list-bg-color;

    > *:last-child {
      border-bottom: none;
    }
  }

  .gstc-chapters__chapter-container {
    font-size: 14px;
    font-weight: 700;

    background-color: $gstc-list-bg-color;
    cursor: pointer;
    transition: 0.3s;

    padding: 8px 16px;
    border-bottom: 1px solid $color--gray4;

    &:hover {
      background-color: $color--gray3;
    }

    &.unselectable {
      cursor: default;

      &:hover {
        background-color: transparent;
      }
    }

    &.selected {
      &.card-header {
        background-color: $gvds-color--alpha;
      }
    }

    &.locked {
      display: flex;
      flex-direction: row;

      &.card-header {
        cursor: default;
      }
    }
  }

  .gstc-chapters__action-item {
    font-size: 14px;
    font-weight: 400;
    line-height: 17px;

    display: flex;
    flex-direction: row;
    align-items: center;
    padding: $gvds-spacing--100 $gvds-spacing--150 $gvds-spacing--100 $gvds-spacing--400;
    border-bottom: 1px solid $color--gray4;
    background-color: $gstc-list-bg-color;
    cursor: pointer;
    transition: 0.3s;

    &:hover {
      background-color: $color--gray3;
    }

    &.selected {
      background-color: $gvds-color--alpha;
    }

    .action-number {
      margin-right: 0.25rem;
    }

    .action-name {
    }

    .action-score {
      margin-left: auto;
      display: flex;
      align-items: center;
    }

    .action-score-pill {
      display: flex;
      flex-direction: row;
      padding: $gvds-spacing--25 $gvds-spacing--100;
      border-radius: 4px;

      background-color: $color-white;
      @include gvds-text--formLabel;

      .action-score-complete {
        margin-left: -$gvds-spacing--50;
      }
    }
  }
}

.gstc-score__container {
  border: 1px solid $gvds-border-color--default;
  border-radius: 4px 4px 0 0;
  padding: $gvds-spacing--100 $gvds-spacing--200;

  display: flex;
  flex-direction: column;

  .gstc-score__display {
    @include flex-fill;

    .progress {
      height: 10px;
      border-radius: 5px;

      padding: 1px;
      background-color: $gvds-color--alpha;

      .progress-bar {
        height: 8px;
        border-radius: 4px;
        background-color: $gvds-color--tealSolid;
      }
    }
  }

  .gstc-score__text {
  }

  .gstc-score-download-container {
    margin-left: auto;
  }
}

.gstc-item-details__container {
  display: flex;
  justify-content: center;
  width: 100%;
  height: $gstc-content-height;
  overflow-y: auto;

  .gstc-item-details__content {
    width: 100%;
    min-width: 65%;
    max-width: 1200px;
    padding-left: $gvds-spacing--150;
    padding-right: $gvds-spacing--150;

    .gstc-action-details__content {
      background-color: $color-white;
      padding: 0 $gvds-spacing--100;
      margin-left: -8px; // to allow box shadow in content
      overflow: auto;

      h3 {
        margin-top: 0;
      }

      a {
        font-size: 16px;
      }

      .gstc-action-details__satisfactory-outcome-container {
        margin-bottom: $gvds-spacing--400;
      }

      .gstc-action-details__satisfactory-outcome-info {
        border-bottom: 1px solid $gvds-border-color--default;
        padding: $gvds-spacing--100 $gvds-spacing--150;
        display: flex;
        font-size: 16px;

        div {
          width: 20px;
          text-align: right;
          flex-shrink: 0;
          margin-right: 5px;
        }

        h3 {
          margin-left: -12px;
          margin-bottom: 0;
        }
      }

      .gstc-action-details__satisfactory-outcome-status {
        display: flex;
        align-items: center;

        padding-top: $gvds-spacing--100;
        padding-bottom: $gvds-spacing--100;

        font-weight: 600;

        border-left: 1px solid $gvds-border-color--default;
        border-bottom: 1px solid $gvds-border-color--default;

        div {
          text-align: center;
          flex: 1 1;
          display: flex;
          justify-content: center;
          align-items: center;
        }
      }

      .gstc-action-details__steps-to-take {
        margin-bottom: $gvds-spacing--400;

        p,
        ul,
        ol {
          font-size: $gvds-spacing--200;
        }

        li {
          margin-bottom: $gvds-spacing--100;
        }
      }

      .gstc-related-criteria__container {
        margin-bottom: $gvds-spacing--250;
      }

      .gstc-related-criterion {
        border: 1px solid $gvds-border-color--default;
        background: $color-white;
        display: inline-block;
        padding: $gvds-spacing--150;
        margin-right: $gvds-spacing--200;
        margin-bottom: $gvds-spacing--150;
        font-size: 16px;

        border-radius: 4px;

        &:hover {
          @include gvds-shadow--small;
        }
      }

      .gstc-action-details__downloadable-resource {
        margin-bottom: $gvds-spacing--400;
      }

      .gstc-action-details__relevant-portal-features {
        margin-bottom: $gvds-spacing--400;
      }

      .gstc-action-details__relevant-guidance-articles {
        margin-bottom: $gvds-spacing--400;
      }

      .gstc-action-details-comment__section-label {
        @include gvds-text--heading4;
        align-self: center;
      }

      .gstc-action-details__edit-comment-btn {
        margin-left: -4px;
        margin-top: 14px;
      }

      .gstc-action-details__comment-text {
        margin-left: $gvds-spacing--250;
        margin-bottom: $gvds-spacing--100;
        padding: 0 $gvds-spacing--200;
        border-left: 4px solid $color--gray4;
        position: relative;
      }
    }
  }
}

.gstc-action-details-comment-modal {
  width: 620px;

  .gstc-action-details-comment-modal__form-group {
    flex-grow: 1;
    margin-top: $gvds-spacing--150;
    margin-bottom: 0;


    .gstc-action-details-comment-modal__comment-textarea {
      height: 300px;
    }
  }
}

.gstc-criteria-filter {
  @extend .gvds-table-ctrl--multi-select;

  margin-top: $gvds-spacing--150;

  .gvds-table-ctrl--multi-select__trigger {
    border: 1px solid $gvds-border-color--table;

    .display-text {
      overflow: hidden;
      text-overflow: ellipsis;
    }
  }

  .gvds-icon-container {
    margin-left: auto;
  }

  .leading-icon {
    margin-left: 0;
    color: $gvds-color--tealSolid;
  }
}

.gstc-criteria-filter__dropdown-menu {
  @extend .gvds-table-ctrl--multi-select__dropdown-menu;

  max-width: 360px;
}

// endregion GSTC

// region Answer Save Status
.answer-save-status {
  @include gvds-text--caption;
  margin-top: $gvds-spacing--50;
  display: flex;
  align-items: center;

  .gvds-icon-container {
    $container-height: 14px;
    height: $container-height;
    min-height: $container-height;
  }
}

// endregion

// region Survey
.survey-sticky-top {
  $horizontal-spacing: $gvds-spacing--400;

  @include sticky-top;
  z-index: 200;

  background-color: $color-white;
  margin-left: -$horizontal-spacing;
  margin-right: -$horizontal-spacing;
  padding: $gvds-spacing--200 0;
  margin-bottom: $gvds-spacing--200;

  .survey-sticky-top-content {
    margin-left: $horizontal-spacing;
    margin-right: $horizontal-spacing;
  }
}

.survey-sticky-top-with-shadow {
  @extend .survey-sticky-top;
  @extend .shadow-sm;
}

// endregion

// region Best Practice - Best Practice Questions
.best-practice-question-display {
  display: flex;
  flex-direction: row;

  @include question-card__container;
  margin-bottom: $gvds-spacing--200;
}

.best-practice-question-display.answered {
  @include question-card__container-active;
}

.best-practice-question-text {
  @include gvds-text--heading4;
}

.best-practice-question__adoption-lvl {
  border-radius: 50%;
  height: 32px;
  width: 32px;

  display: flex;
  align-items: center;
  justify-content: center;

  &.green {
    background-color: $light-green;
  }

  &.yellow {
    background-color: $light-yellow;
  }

  &.red {
    background-color: $light-red;
  }

  &.gray {
    background-color: $gray-93;
  }

  &.empty {
    border: 2px dashed $gray-85;
  }
}

.best-practice-question-relevant-articles-container {
  text-align: right;
  width: 200px;
}

.best-practice-question-comment__container {
  display: flex;
  flex-direction: row;
  align-items: center;
  font-size: 13px;
  margin-bottom: $gvds-spacing--100;

  .best-practice-question-comment {
    margin-right: $gvds-spacing--100;
  }
}

.best-practice-question-comment {
  display: block;
  max-width: 300px;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}

.best-practice-category-progress-container {
  display: grid;
  grid-template-columns: 1fr 1fr 1fr 1fr 1fr 1fr 1fr 1fr;
  gap: $gvds-spacing--200;

  @media screen and (max-width: $breakpoint-desktop) {
    grid-template-columns: 1fr 1fr 1fr 1fr;
  }

  margin-bottom: $gvds-spacing--200;
}

.best-practice-category-progress {
  @extend .filter-card;

  display: flex;
  flex-direction: column;
  justify-content: space-between;
  cursor: pointer;

  border-radius: 4px;
  padding: $gvds-spacing--100;
  margin: 0;

  transition: 0.3s;

  .progress {
    height: 1rem;
    border-radius: 0.5rem;
    padding: 0.1rem;
    background-color: $gvds-color--alpha;
    width: 100%;

    .progress-bar {
      height: 0.75rem;
      border-radius: 0.375rem;
      background-color: $gvds-color--tealSolid;
    }
  }
}

.best-practice-category-card__header {
  display: flex;
  flex-direction: row;
  align-items: center;
  flex-grow: 1;

  &.with-progress {
    margin-bottom: $gvds-spacing--100;
  }

  .best-practice-category-card__name {
    font-weight: 500;
    text-transform: capitalize;
  }
}

.best-practice-adoption-chart__container {
  margin: $gvds-spacing--150 0 $gvds-spacing--250;
  padding: $gvds-spacing--200;

  border: 1px solid $color--gray4;
  border-radius: 4px;

  display: flex;
  flex-direction: column;
}

.best-practice-adoption-chart__header {
  display: flex;
  align-items: center;
  margin-bottom: $gvds-spacing--50;
}

.best-practice-adoption-chart__total-question {
  margin-left: $gvds-spacing--50;
  margin-right: $gvds-spacing--75;

  @include gvds-text--heading4;
}

.best-practice-adoption-chart__progress {
  width: 100%;
  margin-bottom: $gvds-spacing--100;

  $best-practice-adoption-chart-height: 16px;
  $best-practice-adoption-chart-height-halved: $best-practice-adoption-chart-height /
      2;

  .progress {
    height: $best-practice-adoption-chart-height;
    border-radius: $best-practice-adoption-chart-height-halved;
    padding: 0.25 * $best-practice-adoption-chart-height-halved;
    background-color: $color--gray4;

    .progress-bar {
      height: 0.75 * $best-practice-adoption-chart-height;

      &.green {
        background-color: $gvds-color--success;
      }

      &.yellow {
        background-color: $gvds-color--warning;
      }

      &.red {
        background-color: $gvds-color--error;
      }
    }

    .progress-bar:first-child {
      border-top-left-radius: 0.75 *
          $best-practice-adoption-chart-height-halved;
      border-bottom-left-radius: 0.75 *
          $best-practice-adoption-chart-height-halved;
    }

    .progress-bar:last-child {
      border-top-right-radius: 0.75 *
          $best-practice-adoption-chart-height-halved;
      border-bottom-right-radius: 0.75 *
          $best-practice-adoption-chart-height-halved;
    }
  }
}

.best-practice-adoption-chart__breakdown {
  display: flex;
  flex-direction: row;
  align-items: center;
  flex-wrap: wrap;

  .status-label {
    cursor: pointer;
  }

  .adoption-level__label {
  }

  .adoption-level__number {
    margin-right: $gvds-spacing--50;
  }
}

.best-practice-portfolio-category {
  @extend .best-practice-category-progress;
  min-height: 3.5em;
}

.best-practice-list {
  .gvds-tag {
    margin-bottom: $gvds-spacing--75;
  }
}

// endregion

// region Best Practice - Guidance Library List
.guidance-library__article-list-display {
  @include question-card__container;

  cursor: pointer;
  margin-bottom: $gvds-spacing--200;
  transition: 0.3s;

  &.bookmarked {
    @include question-card__container-active;
  }

  &:hover {
    @include gvds-shadow--large;
  }
}

.guidance-library__article-title {
  display: flex;
  align-items: center;
  font-size: 18px;
}

.guidance-library__article__label {
  @include gvds-text--caption;
  margin-bottom: $gvds-spacing--100;
}

.guidance-library__article__bookmark-container {
  height: 100%;
  display: flex;
  align-items: center;
}

.guidance-library__category-filter-display {
  @extend .best-practice-portfolio-category;
}

.guidance-library__article-list {
  .gvds-tag {
    margin-bottom: $gvds-spacing--75;
  }
}

// endregion

// region Best Practice - Guide View
.guidance-article ul li {
  margin-bottom: 8px;
}

.guidance-article h3 {
  margin-bottom: 7px !important;
}

$guidance-article-max-width: 80%;
$guidance-article-img-max-width: 100%;
$guidance-article-side-width: 20%;
.guidance-article-h3 {
  margin: 25px 0 0 0 !important;
  border-bottom: 1px solid $black;
  font-weight: 600;
  color: $black !important;
}

.guidance-article {
  flex-grow: 1;
  padding: 16px;
  max-width: $guidance-article-max-width;

  h3 {
    @extend .guidance-article-h3;
  }

  h3:first-child {
    margin-top: 0 !important;
  }

  img {
    max-width: $guidance-article-img-max-width;
    display: block;
    margin-left: auto;
    margin-right: auto;
  }
}

.guidance-article-pdf-container {
  width: 100%;
  min-height: 75vh;
  overflow-y: scroll;
  margin-right: 16px;
}

.guidance-article-video-container {
  width: 100%;
  margin-right: 16px;

  video {
    width: 100%;
  }
}

.guidance-article-side-info {
  @extend .gvds-info-card;
  width: $guidance-article-side-width;
  overflow-wrap: break-word;
  max-height: calc(90vh - $top-nav-height);
  overflow-y: scroll;
  margin-bottom: 0;

  ul {
    padding-inline-start: 1rem;
  }
}

// endregion

// region Survey - Shared
.survey-progress {
  display: flex;
  align-items: center;
  justify-content: space-between;

  .progress {
    height: 10px;
    background-color: $gvds-color--alpha;
    border-radius: 5px;
    padding: 1px;
    width: 65%;

    .progress-bar {
      height: 8px;
      border-radius: 4px;
      background-color: $gvds-color--tealSolid;
    }
  }
}

// endregion

// region Survey Management
.survey-question-container {
  display: flex;
  flex-direction: column;

  background-color: $color-white;
  border: 1px solid $color--gray4;
  border-radius: 4px;
  margin-bottom: $gvds-spacing--200;
}

.survey-question-content.nested-question {
  padding-left: $gvds-spacing--600;
}

.survey-question-content {
  display: flex;
  padding: $gvds-spacing--200;
  border-bottom: 1px solid $color--gray4;
}

.survey-question-number {
  margin-right: $gvds-spacing--300;
  font-weight: 800;
}

.survey-question-detail-and-options {
  display: flex;
  flex-direction: column;
  width: 100%;

  & > div {
    margin-bottom: $gvds-spacing--150;
  }
}

.survey-question-detail {
  display: flex;
  flex-direction: row;
  width: 100%;
}

.survey-question-nested-actions {
  display: flex;
  justify-content: flex-end;
}

.survey-question-footer {
  display: flex;
}

.survey-duplicate-dialog__label {
  // TODO typography update
  @include gv-body-2;
  font-weight: 800;
  color: $gray-36;
  margin-bottom: $gvds-spacing--100;
  margin-top: $gvds-spacing--300;
}

.survey-bpq-updated-question__container {
  margin-top: $gvds-spacing--100;
}

.survey-bpq-updated-question__row {
  display: flex;
  background-color: $color--gray2;
  border-radius: 4px;
  padding: $gvds-spacing--150 $gvds-spacing--100;
  border: 1px solid $color--gray4;
  flex-direction: row;
  align-items: start;
  margin-bottom: 8px;

  .question-number {
    // TODO typography update
    @include gv-text-14;
    font-weight: 700;
    min-width: 14.36px;
    margin-right: $gvds-spacing--100;
  }

  .question-text {
    // TODO typography update
    @include gv-text-14;
    margin-right: $gvds-spacing--100;
    margin-top: $gvds-spacing--50;
  }
}

// endregion

// region Survey Assessment
.survey-question-display {
  display: flex;
  flex-direction: row;

  @include question-card__container;
  margin-bottom: $gvds-spacing--200;
}

.survey-question-display.answered {
  @include question-card__container-active;
}

.survey-question-text {
  font-weight: 600;
  font-size: 18px;
}

.survey-question-comment__container {
  display: flex;
  flex-direction: row;
  align-items: center;
  font-size: 13px;
  margin-top: 16px;
  margin-bottom: 10px;
}

.survey-question-comment {
  display: block;
  max-width: 300px;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}

.survey-question-best-practice-prepopulate-container {
  @extend .filter-card;
  padding: 24px;

  &:hover {
    background-color: white;
  }
}

// endregion

// region Admin - Subscription Details
.subscription-details__container {
  .feature-info {
    margin-bottom: $gvds-spacing--100;
  }

  .subscription-details__subscribed-features {
    line-height: 1.6em;
  }
}

// endregion

// region Admin - Tags
.empty-tag-list-placeholder {
  @include gvds-empty-content;

  padding: $gvds-spacing--300;
  max-height: 80%;
  text-align: center;
}

.about-tag-example__label {
  display: flex;
  flex-direction: row;
  align-items: baseline;
}

.tag-description-column__caption {
  @include gvds-text--caption;
  margin: 0;
  color: $color--gray6;
}

// endregion

// region Admin - Team
.no-approved-domain__caption {
  @include gvds-text--caption;
  color: $color--gray6;
}

// endregion

// region Admin - Site Details
.gvds-site-facility-content-box {
  border: 1px solid $color--gray4;
  border-radius: 4px;
  padding: $gvds-spacing--100 $gvds-spacing--200 0;
}

// endregion

// region Admin - Billing

.gvds-button.view-pricing-plans-button {
  margin-left: $gvds-spacing--150;
}

.billing-subscription__name-display {
  @include gvds-text--body;

  display: flex;
  flex-direction: row;
  align-items: center;
  margin-bottom: $gvds-spacing--100;
}

.billing-payment__details {
  display: flex;
  flex-direction: row;

  .mastercard-icon {
    width: 24px;
    height: 24px;
  }

  .visa-icon {
    width: 32px;
    height: 32px;
  }

  .powered-by-stripe-icon {
    margin-left: $gvds-spacing--100;
    width: 120px;
    height: 32px;
  }

  span.billing-email {
    color: $gvds-color--tealSolid;
  }
}

.billing-subscription__cancel-subscription__container {
  margin-bottom: $gvds-spacing--100;

  .header-text {
    margin-bottom: $gvds-spacing--150;
  }

  ul {
    margin-left: $gvds-spacing--150;
    margin-bottom: $gvds-spacing--25;
  }

  .footer-text {
    margin-top: $gvds-spacing--150;
  }
}

.billing-subscription__action-buttons {
  @include gvds-text--body;

  display: flex;
  flex-direction: column;
  align-items: flex-end;
  margin-bottom: $gvds-spacing--100;
}

.billing-plan-change-display__plan-box {
  border-radius: 4px;
  border: 1px solid $gvds-border-color--table;
  padding: $gvds-spacing--200;

  &.dark {
    background-color: $color--gray2;
  }

  .billing-plan-change-display__plan-details {
    display: grid;
    grid-template-columns: 1fr 4fr;
    row-gap: $gvds-spacing--100;
    margin-top: $gvds-spacing--100;
  }
}

// endregion

// region Subscription shared styles
.view-all-subscription__no-content {
  @include gvds-empty-content;

  margin-top: $gvds-spacing--100;
  height: 10rem;

  div {
    width: 400px;
    text-align: center;
    margin-top: 11px;
  }
}

.subscription__joined-bullet {
  font-size: 5px;
  margin: 0 $gvds-spacing--100;
  min-width: 5px;
  width: 5px;
}

.subscription-box__container {
  border: 1px solid $gvds-border-color--default;
  border-radius: 4px;
  margin-bottom: $gvds-spacing--200;

  header {
    @include gvds-text--heading4;

    padding: $gvds-spacing--200 $gvds-spacing--300;
    border-bottom: 2px solid $gvds-border-color--default;

    .contract-subscription-indicator {
      font-weight: 400;
      font-size: 14px;
      line-height: 150%;

      display: flex;
      flex-direction: row;
      margin-left: $gvds-spacing--100;
    }
  }

  article {
    padding: $gvds-spacing--200 $gvds-spacing--300;
  }

  .next-payment-message {
    margin-bottom: $gvds-spacing--75;
  }

  .payment-due-message {
    margin-bottom: $gvds-spacing--75;
  }
}

.subscription__select-price {
  .form-check {
    margin-left: 0.25em;
    margin-bottom: 0.25em;
  }

  label {
    @include gv-text-16;
  }

  strong {
    font-weight: 700;
  }
}

.subscription-content-display {
  width: 100%;

  td {
    @include gvds-text--body;
    color: $color--gray9;
    padding-bottom: 10px;
  }

  td:first-child {
    @include gvds-text--inputField;
    color: $color--gray10;
  }

  .label-column {
    width: 40%;
  }

  .value-column {
    width: 60%;
  }

  .comment-section {
    vertical-align: top;
  }
}

.subscription-features-icon {
  color: $color--gray10;
}

.show-subscription-feature__container {
  display: flex;
  flex-direction: row;
  margin-left: $gvds-spacing--50;

  color: $gvds-color--tealSolid;
}

.select-subscription-feature__container {
  padding: 24px;
}

.subscription__action-dropdown {
  @include elevation-1;
  border: none;
}

.subscription-site-count {
  display: flex;
  align-items: center;

  .subscription-site-count__deviant-site {
    display: inherit;
    align-items: inherit;

    .gvds-icon-container {
      margin-left: $gvds-spacing--50;
    }
  }

  .subscription-site-count__not-available {
    color: $color--gray6;
  }
}


.subscription-no-discount-code {
  color: $color--gray6;
}

.subscription__joined-bullet-container {
  display: flex;
  flex-direction: row;
  align-items: center;
  flex-wrap: wrap;
}

.subscription__site-count-container {
  display: flex;
  flex-direction: row;
  align-items: center;
  flex-wrap: wrap;
  font-weight: 400;
  margin-left: $gvds-spacing--100;

  .subscription__site-count__deviant-site {
    display: inherit;
    align-items: inherit;

    .gvds-icon-container {
      margin-left: $gvds-spacing--50;
    }
  }
}

.subscription__no-data-available {
  @include gvds-text--body;
  color: $color--gray6
}

.subscription__timezone-display {
  color: $color--gray6;
}

.subscription__auto-renews {
  display: flex;
  flex-direction: row;
  margin-top: $gvds-spacing--50;

  .auto-renews-label {
    color: $color--gray10;
    margin-left: $gvds-spacing--50;
  }
}

.subscription__billing-cycle__container {
  margin-left: $gvds-spacing--300;
}

.subscription__comment__container {
  margin-bottom: $gvds-spacing--300;
}

.subscription__tax-switch__container {
  display: flex;
  flex-direction: row;
  align-items: center;
  margin-bottom: $gvds-spacing--100;

  .tax-switch-label {
    @include gvds-text--inputField;
  }

  .tax-switch-caption {
    @include gvds-text--caption;
  }

  .tax-switch {
    margin-top: -4px;
    margin-left: $gvds-spacing--100;
    margin-right: $gvds-spacing--50;
  }
}

.status-label.subscription__payment_type__display {
  margin-left: 0;
  margin-top: $gvds-spacing--50;
}

// endregion

// region System Toolbox - GSTC
.sms-action-details-header__item-id {
  font-size: 12px;
  font-weight: 400;
}

// endregion

// region Performance Report config
.performance-report-config {
  .performance-report-config__input-with-preview-container {
    display: grid;
    grid-template-columns: 7fr minmax(220px, 3fr);
  }

  .performance-report-config__title {
    @include gvds-text--heading3;

    margin: $gvds-spacing--200 0 $gvds-spacing--200 $gvds-spacing--150
  }

  .performance-report-config__config-container {
    display: flex;
    flex-direction: row;
    justify-content: space-evenly;
    flex-wrap: nowrap;

    @media (max-width: $breakpoint-desktop) {
      flex-direction: column;
      align-items: center;
    }
  }

  .performance-report-config__config-options-container {
    flex-grow: 1;
    width: 100%;
    min-width: 350px;
    max-width: 600px;
  }

  .performance-report-config__nested-dropdowns__container {
    margin-bottom: $gvds-spacing--50;
    margin-left: $gvds-spacing--300;

    & > *:not(:last-child) {
      margin-bottom: $gvds-spacing--50;
    }
  }

  .performance-report-config__preview__container {
    background-color: $color--gray2;
  }

  .year-month-toggle-button-group {
    margin-top: $gvds-spacing--50;
    min-width: 225px;
  }

  .year-month-selected-details {
    @include gvds-text--caption;

    width: 14rem;
    color: $color--gray6;
    margin-top: $gvds-spacing--50;
  }

  .performance-report-config__footer {
    @include sticky-bottom;

    z-index: $gvds-z-index--sticky-page-container;
    background-color: white;
    padding: $gvds-spacing--100;
    margin-right: calc(-0.5 * var(--bs-gutter-x));
    margin-left: calc(-0.5 * var(--bs-gutter-x));
    border-top: 1px solid $color--gray4;

    display: flex;
    flex-direction: row;
  }
}

.performance-report-config__preview-content {
  margin-bottom: $gvds-spacing--300;

  .performance-report-config__status-icon {
    border-radius: 50%;
    height: 32px;
    width: 32px;
    min-width: 32px;

    display: flex;
    align-items: center;
    justify-content: center;

    &.available {
      background-color: $light-green;
      color: $gvds-color--tealSolid;
    }

    &.unavailable {
      background-color: $light-red;
      color: $gvds-color--error;
    }

    &.insufficient_chsb_data {
      background-color: $light-red;
      color: $gvds-color--error;
    }

    &.empty {
      border: 2px dashed $color--gray4;
      background-color: $color--gray0;
    }
  }

  .performance-report-config__table-view {
    margin-bottom: $gvds-spacing--300;

    .performance-report-config__table-view__title {
      @include gvds-text--formLabel;

      display: flex;
      flex-direction: row;
      align-items: center;
    }

    .performance-report-config__table-view__caption {
      @include gvds-text--caption;

      color: $color--gray9;
    }
  }

  .performance-report-config__chart-view {
    margin-bottom: $gvds-spacing--300;

    .performance-report-config__chart-view__title {
      @include gvds-text--formLabel;

      display: flex;
      flex-direction: row;
      align-items: center;
    }

    .performance-report-config__chart-view__caption {
      @include gvds-text--caption;

      color: $color--gray9;
    }
  }

  .performance-report-config__benchmark-view {
    margin-bottom: $gvds-spacing--300;

    .performance-report-config__benchmark-view__title {
      @include gvds-text--formLabel;

      display: flex;
      flex-direction: row;
      align-items: center;
      margin-right: $gvds-spacing--50;
    }

    .performance-report-config__benchmark-view__caption {
      @include gvds-text--caption;

      color: $color--gray6;
      margin-bottom: $gvds-spacing--100;
    }

    .performance-report-config__benchmark-checklist-item__container {
      margin-right: $gvds-spacing--50;

      .performance-report-config__benchmark-checklist-item {
        @include gvds-text--caption;

        color: $color--gray9;
        display: flex;
        align-items: center;
      }
    }
  }
}

.performance-report-config__preview-box {
  height: 100%;
  border: 1px solid $color--gray4;
  border-radius: 4px;
  box-shadow: 0 2px 4px rgba(32, 51, 66, 0.2);
  padding: $gvds-spacing--200 0 $gvds-spacing--200 $gvds-spacing--200;

  .performance-report-config__preview_box__content {
    font-size: 14px;
    font-weight: 400;
    height: 100px;

    &.slim {
      height: 40px;
    }

    .performance-report-config__benchmark-checklist-item {
      .performance-report-config__benchmark-checkmark-icon {
        width: 20px;
      }
    }
  }

  .gray-label {
    width: 5rem;
    height: 1.3rem;
  }

  h3 {
    margin-top: 0;
  }
}

.performance-report__multi-year-calendar-container {
  border: 1px solid $color--gray4;
  border-radius: 4px;
  background-color: $color-white;
  width: 225px;

  .multi-year-calendar__header {
    background-color: $color--gray2;
    padding: 3px 0;

    .title {
      font-weight: bold;
      font-size: 0.944rem;
    }

    .pagination-control-button {
      background-color: $color--gray2;
      cursor: pointer;

      &.previous {
        margin-left: $gvds-spacing--100;

        &.disabled {
          visibility: hidden;
        }
      }

      &.next {
        margin-right: $gvds-spacing--100;

        &.disabled {
          visibility: hidden;
        }
      }
    }
  }

  .multi-year-calendar__year_cell__container {
    margin: 0.4rem;
    text-align: center;

    .multi-year-calendar__year_cell {
      @include gvds-form__shared-calendar-content-style;

      cursor: pointer;

      &:hover {
        background-color: $color-brand-green-light;
      }

      &.selected {
        @include gvds-form__shared-calendar-content-style;

        color: $color-white;
        background-color: $color-brand-green-dark;

        &:hover {
          background-color: $color-brand-green-dark;
        }
      }
    }
  }
}

.performance-report__multi-month-calendar-container {
  padding: $gvds-spacing--75 $gvds-spacing--75 $gvds-spacing--100;
  border: 1px solid $color--gray4;
  border-radius: 4px;
  background-color: $color-white;
  width: 225px;

  .multi-month-calendar__header {
    padding: $gvds-spacing--50 $gvds-spacing--100;
    margin-top: 3px;

    h3 {
      margin: auto;
    }
  }

  .multi-month-calendar__month_cell {
    @include gvds-form__shared-calendar-content-style;

    cursor: pointer;

    &.selected {
      background-color: $color-brand-green-dark;
      color: $color-white;

      &:hover {
        background-color: $color-brand-green-dark;
      }
    }

    &.disabled {
      pointer-events: none;
    }

    &:hover {
      background-color: $color-brand-green-light;
    }
  }
}

// endregion

// region Performance Report - Table
.performance-report-table__initial-placeholder {
  height: 100px;
}

.performance-report-table__container {
  .performance-report-table__table {
    td.divide-border-right {
      border-right: 1px solid $color--gray3;
    }

    td:first-child {
      width: 20%;
    }

    th.performance-report-table__number-cell {
      text-align: right;
    }

    td.performance-report-table__number-cell {
      text-align: right;
      @include gvds-monospaced-number;
    }

    tr.performance-report-table__month_row {
      td.performance-report-table__month_row__month_cell {
      }
    }

    tr.performance-report-table--not-included {
      td:nth-child(n + 2) {
        color: $color--gray6;
      }

      td.missing-data {
        color: lighten($gvds-color--error, 15%);
      }
    }

    tr.performance-report-table__breakdown_row {
      background-color: $color--gray2;

      td:first-child {
        padding-left: 56px;
      }

      td {
        transition: all ease 0.3s;
        padding-top: 4px;
        padding-bottom: 4px;

        div.caption {
          color: $color--gray6;
        }
      }

      &.hide td {
        height: 0;
        padding: 0;
        border-top: 0;
        border-bottom: 0;
        overflow: hidden;
        line-height: 0;
        transition: all ease 0.3s;

        div {
          height: 0;
        }
      }
    }
  }

  .performance-report-table__market_purchase_header_row {
    td {
      border-top: 2px solid $color--gray9;
    }
  }

  .performance-report-table__nested_details_row {
    td:first-child {
      padding-left: $gvds-spacing--400 !important;
    }

    &.breakdown td:first-child {
      padding-left: $gvds-spacing--600 !important;
    }
  }

  .performance-report-table__subtotal_row {
    @extend .performance-report-table__nested_details_row;
    background-color: $color--gray2;

    .performance-report-table__number-cell {
      @extend .gvds-monospaced-number__bold;
    }
  }

  .performance-report-table__total_row {
    @extend .gvds-text--body__bold;
    background-color: $gvds-color--tealLight;

    td {
      border-top: 2px solid $color--gray9;
    }
  }
}

// endregion

// region Performance Report - Chart
.performance-report-chart {
  margin-bottom: 50px;

  .performance-report-chart-view {
    padding: $gvds-spacing--300;
    border: 1px solid $color--gray4;
  }

  .segment-section {
    padding: $gvds-spacing--300;
    border: 1px solid $color--gray4;
  }

  .performance-report-line-chart-view {
    height: 35rem;
    margin-bottom: 2rem;
  }
}

.performance-report__chart-table-title {
  @include gvds-text--heading2;
}

.performance-report-chart__single-benchmark-chart {
  padding-bottom: $gvds-spacing--300;

  .chart-section {
    flex: 0 0 auto;
    width: 80%;
  }

  .legend-section {
    flex: 0 0 auto;
    width: 20%;
  }
}

.performance-report-chart__benchmark__details {
  border: 1px solid $color--gray9;
  padding: $gvds-spacing--150;
}

// endregion

// region HCMI Metric Report
.hcmi-report-table__number-cell {
  text-align: right;
  @include gvds-monospaced-number;
}

// endregion

// region Data Requests
.data-request-table {
  .data-request-due-message {
    color: $color--gray6;
  }
}

.data-request-progress {
  display: flex;
  align-items: center;

  .progress {
    display: flex;
    align-items: center;
    height: 1rem;
    background-color: $color--gray4;
    border-radius: 0.5rem;
    padding: 0.1rem;
    width: 75%;
    min-width: 35%;

    .progress-bar {
      height: 0.75rem;
      border-radius: 0.375rem;
      background-color: $gvds-color--success;
    }

    &.green .progress-bar {
      background-color: $gvds-color--success;
    }

    &.yellow .progress-bar {
      background-color: $gvds-color--warning;
    }

    &.red .progress-bar {
      background-color: $gvds-color--error;
    }
  }
}

.data-request-no-description-message {
  @include gvds-text--caption;
  color: $color--gray6;
}

// endregion

// region Environmental - Dashboard All Environmental Meters
table.environmental-meter-table {
  .environmental-meter-table__type {
    width: 20%;
  }

  .environmental-meter-table__meter {
    width: 25%;
  }

  .environmental-meter-table__last_record_date {
    width: 15%;
  }

  .environmental-meter-table__last_updated {
    width: 15%;
  }

  .environmental-meter-table__status {
    width: 15%;
  }

  .environmental-meter-table__actions {
    width: 10%;
  }
}

// endregion

// region Environmental - Meter Setup
.environmental-type-table__tracking {
  width: 8%;
  padding: 0.75rem;
}

.environmental-type-table__type {
  width: 15%;
  padding: 0.75rem;
}

.environmental-type-table__meter_count {
  width: 10%;
  padding: 0.75rem;
}

.environmental-type-table__definition {
  width: 67%;
  padding: 0.75rem;
}

.meter-table__actions__container {
  .btn-show-alert-preferences-modal {
    margin-left: 0;
    text-transform: capitalize;
  }
}

.meter-tips-container {
  .item-title {
    @include gvds-text--heading4;
    margin-top: $gvds-spacing--200;
  }
}

// endregion

// region Alert Preferences

.dismiss-alert-date__container {
  display: flex;
  flex-direction: column;
}

// endregion

// region Environmental - Market Purchase
.market-purchase-offset-table {
  &__tracking {
    width: 8%;
    padding: 0.75rem;

    input {
      cursor: pointer;
    }
  }

  &__type {
    width: 15%;
    padding: 0.75rem;
  }

  &__tracker_count {
    width: 10%;
    padding: 0.75rem;
  }

  &__definition {
    width: 67%;
    padding: 0.75rem;
  }
}

.market-purchase-trackers-table {
  margin-bottom: 0;

  &__tracker-id {
    width: 45%;
  }

  &__last-updated {
    width: 40%;
  }

  &__actions {
    width: 15%;
  }
}

.market-purchase-offset-record-form {
  &__no-content {
    @extend .table__no_content;
    height: 100px;
    padding: 15px;
  }
}

.market-purchase-tracker-usages-table-container {
  padding: 3px 0;
  margin: 16px;
}

.market-purchase-tracker-usages-table {
  margin-bottom: 0;

  th {
    font-weight: normal;
    font-size: 14px;
    line-height: 21px;
  }

  th,
  td {
    padding: 2px;
    border-top: none;
  }

  tr:first-child th {
    border-top: none;
    border-bottom: 5px double $black;
  }

  tr:last-child td {
    border-top: 2px solid $black;
    border-bottom: 5px double $black;
    font-weight: bold;
  }

  th:nth-child(2),
  td:nth-child(2) {
    text-align: right;
  }
}

// endregion

// region Environmental - Custom Emission Factors
.custom-emission-factor-table__tracking {
  vertical-align: middle;
  width: 6%;
  padding: 0.75rem;
}

.custom-emission-factor-table__type {
  vertical-align: middle;
  width: 15%;
  padding: 0.75rem;
}

.custom-emission-factor-table__ef_count {
  vertical-align: middle;
  width: 75%;
  padding: 0.75rem;
}

.custom-emission-factor-dialog__input {
  display: flex;
  flex-direction: column;
}

.custom-emission-factor-values-table__ef_value {
  vertical-align: middle;
  width: 15%;
  padding: 0.75rem;
}

// endregion

// region Environmental - Meter Details

.meter-details__data-issue-alert {
  display: flex;
  align-items: center;
  margin-bottom: $gvds-spacing--100;
}

.btn-setup-alert-preferences {
  margin-right: $gvds-spacing--100;
}

.meter-details__possible-issues-banner {
  margin-bottom: $gvds-spacing--200;

  ul li {
    margin-bottom: $gvds-spacing--50;
  }

  .cta-alert-preference {
    @extend .btn-link;

    text-decoration: underline;
    color: $color--gray10;
    margin-top: $gvds-spacing--50;

    &:hover {
      color: $color--gray10;
      cursor: pointer;
    }

    &:visited {
      color: $color--gray10;
    }
  }
}

.meter-details__dismiss-alerts-banner,
.meter-details__possible-issues-turned-off-banner {
  margin-bottom: $gvds-spacing--200;

  .info-card-heading {
    .info-card-heading--title {
      font-weight: 400;
      margin-left: $gvds-spacing--200;
    }
  }
}

// endregion

// region Environmental - Activity Log
.activity-log-changes__container {
  padding-bottom: $gvds-spacing--100;
  padding-right: $gvds-spacing--100;
  gap: 4px;

  .activity-log-changes__label {
    @include gvds-text--inputField;
    line-height: 32px;
  }

  .activity-log-changes__text {
    @include gvds-text--body;
  }

  .activity-log-changes-text__line-through {
    @extend .activity-log-changes__text;
    color: $color--gray6;
    text-decoration: line-through;
  }
}

.environmental-records-activity-log-table tbody td {
  vertical-align: top !important;
  padding: 12px 4px !important;
  line-height: 32px !important;
}

// endregion

// region Operational - Meter Setup
.no-facility-message {
  border: 1px solid rgba(68, 68, 68, 0.1);
  box-shadow: 0 4px 4px rgba(0, 0, 0, 0.25);
  padding: 4px;
  width: 464px;
  height: 54px;
  margin-top: -0.9em;
}

table.operation-type-table {
  margin-bottom: 0;
}

.operation-type-table__tracking {
  width: 8%;
  padding: 0.75rem;
}

.operation-type-table__type {
  width: 15%;
  padding: 0.75rem;
}

.operation-type-table__definition {
  width: 77%;
  padding: 0.75rem;
}

.operational-meter-setup__switch {
  text-align: center;
}

// endregion Operational - Meter Setup

// region People
table.people-setup-table {
  margin-bottom: 0;
}

.people-setup-table__tracking {
  padding: 0.75rem;
  width: 8%;
}

.people-setup-table__subtopic {
  padding: 0.75rem;
  width: 10%;
}

.people-setup-table__no_of_groupings {
  padding: 0.75rem;
  width: 8%;
}

.people-setup-table__definition {
  padding: 0.75rem;
  width: 60%;
}

.people-setup-table__action {
  padding: 0.75rem;
  width: 14%;
}

.people-glossary-table-container {
  border-top: 2px solid $color--gray10;
  border-bottom: 2px solid $color--gray10;
  padding: 3px 0;
}

.people-glossary-table {
  margin-bottom: 0;

  th,
  td {
    padding: 2px;
  }

  .people-grouping-row {
    border-top: 2px solid $color--gray10;
  }

  tr:last-child {
    border-bottom: 2px solid $color--gray10;
  }
}

.people-preview-table {
  border: dashed 2px $gvds-border-color--default;
  padding: 20px;
}

.people-input-table {
  border-collapse: separate;
  border-spacing: 0;

  td {
    border: 1px solid $gvds-border-color--table;
    background: $color-tealUltraLightBg;
    padding: 1px;
  }

  input {
    background: none;
    border: none;

    &:focus {
      background-color: $color-white;
      // follow GVDS form field
      border: 1px solid $focus-border-color;
      box-shadow: 0 0 3px 0 $focus-border-color;
    }
  }

  tr td:last-child {
    background: $color-white;

    &:focus-within {
      border: 1px solid $focus-border-color;
      background-color: $color-white;
    }
  }
}

.people-meter-modal__update-message {
  max-width: 515px;
  text-align: right;
  margin-right: 5px;
}

.people-record-month {
  max-width: 300px;
}

.people-record-preview-table {
  th,
  td {
    padding: 2px;
    border: none;
  }

  th:nth-child(2),
  td:nth-child(2) {
    text-align: right;
  }

  .people-grouping-row {
    border-top: 2px solid $black;
  }

  .people-grouping-row-separator {
    border-top: 2px solid $black;
    height: 3px;
  }

  .people-category-row {
    border-bottom: 1px dotted $gray-85;
  }
}

.people-record-calculation-table {
  th,
  td {
    padding: 2px;
    border: none;
  }

  th:nth-child(2),
  td:nth-child(2) {
    text-align: right;
  }

  .people-grouping-row-separator {
    border-top: 2px solid $black;
    height: 3px;
  }
}

// endregion

// region File Storage
.file-storage-header {
  display: flex;
  padding: $gvds-spacing--100 $gvds-spacing--200;
  border-bottom: 1px solid $gvds-border-color--default;
  margin-bottom: $gvds-spacing--150;
  align-items: center;

  h1 {
    margin-bottom: 0;
  }

  .page-header--back-button-container {
    margin-bottom: $gvds-spacing--100;
  }

  &.no-space-above {
    padding-top: 0;
  }

  &.with-sub-header {
    flex-direction: column;
    align-items: flex-start;
  }
}

.file-storage__no-content {
  @extend .table__no_content;
  flex-direction: column;

  text-align: center;

  ul {
    list-style-position: inside;
  }
}

.file-storage-body {
  padding: $gvds-spacing--100 $gvds-spacing--200;

  .file-storage-bulk-action-bar__container {
    margin: 0 0 $gvds-spacing--200;

    border: 1px solid $gvds-border-color--table;
    border-radius: 4px;
    background-color: $color-white;

    display: flex;
    flex-direction: column;

    .file-storage-bulk-action-bar__content {
      @extend .gvds-table-ctrl--bulk-action-bar__container;
      background-color: transparent;
    }

    .file-storage-bulk-action-bar__note {
      @extend .gvds-table-ctrl--bulk-action-bar__container;
      background-color: transparent;
    }
  }
}

.file-storage-item-container {
  display: flex;
  flex-direction: row;
  align-items: center;

  .file-storage-item-selector {
    flex-grow: 0;
  }
}

.file-storage-search-box {
  padding-right: 0.4em; // to align search with filter
}

.file-storage-item-display {
  flex: 1 1;
  min-width: 0; // https://defensivecss.dev/tip/flexbox-min-content-size
  border: 1px solid $color--gray4;
  border-radius: 4px;
  margin: $gvds-spacing--150 0;

  .file-storage-item-body {
    padding: $gvds-spacing--75 $gvds-spacing--150;
    display: flex;
  }

  .file-storage-item-content {
    flex-grow: 1;
  }

  .file-storage-item-name {
    line-height: 1.5em;
  }

  .file-storage-item-footer {
    display: flex;
    background: $color--gray2;
    color: $color--gray9;
    padding: $gvds-spacing--75 $gvds-spacing--150;

    .detail-separator {
      margin-left: $gvds-spacing--50;
      margin-right: $gvds-spacing--50;
    }

    .file-storage-item-site-name {
      margin-right: $gvds-spacing--50;
    }

    .file-storage-item-upload-info {
      flex-grow: 0;
    }

    .file-storage-item-description {
      flex-basis: 250px;
      flex-grow: 1;
      white-space: nowrap;
      overflow: hidden;
      text-overflow: ellipsis;
      margin-left: 5px;
    }
  }
}

.file-storage-edit-display {
  border: 1px solid $color--gray4;
  margin: $gvds-spacing--150 0;

  .file-storage-edit-body {
    padding: $gvds-spacing--200 $gvds-spacing--250;
    display: flex;

    .file-storage-edit-index {
      font-weight: 800;
      font-size: 16px;
      margin-right: $gvds-spacing--200;
    }

    .file-storage-edit-content {
      flex-grow: 1;
    }
  }
}

.file-storage-link-icon {
  display: inline-block;
  margin-left: $gvds-spacing--50;
  color: $color--gray10;
  transform: translateY(10%);
}

// endregion

// region Login
.login-header {
  font-size: 18pt;
  margin-bottom: 30px;
  text-align: center;
  color: $green;
  font-weight: bold;
}

.auth-submit__button {
  @include gv-text-16;
  font-weight: 700;
  width: 100%;
  color: $white !important;
  height: 40px;
}

.login__sign-up-btn {
  margin-top: 14px;
  width: 100%;
  @include gv-text-16;
}

.login-section {
  background: $color-white;
  @include elevation-1;
  padding: $gvds-spacing--300 $gvds-spacing--300;
  border-radius: 4px;

  @include gvds-text--body;

  .form-group .text-muted {
    display: block;
    margin-top: -12px;
    margin-bottom: 10px;
  }
}

.login__back-to-login-btn {
  margin-top: 14px;
  width: 100%;
}

.auth_header {
  @include gvds-text--heading1;
  text-align: center;
  margin-bottom: 24px;
}

.forgot_pass_message {
  margin-bottom: 24px;
}

.resent_otp_message {
  margin-bottom: 24px;
  color: $gvds-color--successDark;
}

// endregion Login

// region Sign Up
$signup-padding-vertical: 40px;
$signup-padding-between-section: 60px;

.signup-page {
  min-height: 100%;
  padding: 0;

  ul,
  ol {
    padding-left: 1.5em;
  }

  .signup-page__container {
    height: 100vh;
  }
}

.signup-portal-highlighted-feature {
  display: flex;
  flex-direction: row;
  align-items: flex-start;
  justify-content: flex-start;
  margin-top: 16px;
  margin-bottom: 16px;

  .feature-icon {
    display: flex;
    align-items: center;
    justify-content: center;

    margin-right: 16px;
    width: 16px;
  }
}

.signup-info {
  background: $gvds-color--tealLight;
  display: flex;
  justify-content: end;
  padding: 0;

  .signup-info__container {
    padding: $signup-padding-vertical $signup-padding-between-section $signup-padding-vertical $signup-padding-between-section;

    .signup-info__description {
      border-bottom: 1px solid $gvds-border-color--default;
      margin-bottom: $gvds-spacing--300;
      padding-bottom: $gvds-spacing--300;

      img {
        transform: translate(-10px, 0)
      }
    }

    .signup-info__contact-us {
      a {
        text-decoration: none;
      }
    }
  }
}

.signup-form {
  background: $white;
  padding: 0;

  .signup-form__container {
    background: $white;
    padding: $signup-padding-vertical $signup-padding-between-section $signup-padding-vertical $signup-padding-between-section;
  }

  .section-label {
    @include gvds-text--heading3;
    margin-bottom: $gvds-spacing--200;
  }

  section:not(:last-child) {
    margin-bottom: $gvds-spacing--200;
  }

  .login_button {
    margin-right: 0;
  }
}

@media (min-width: $breakpoint-large-desktop) {
  .signup-info__container, .signup-form__container {
    max-width: 640px;
  }
}

.signup-form-submitted {
  margin: 0;
  padding: 0;
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100vh;

  .signup-form-submitted__container {
    @include elevation-1;
    background-color: white;
    padding: 24px;
    border-radius: 3px;
    max-width: 516px;
  }

  .signup-form-submitted__header {
    @include gv-heading-2;
    text-align: center;
    margin-bottom: 24px;
  }

  .signup-form-submitted__body {
    @include gv-text-16;
    border-bottom: solid 1px $gray-85;
    margin-bottom: 24px;
  }

  .signup-form-submitted__footer {
    @include gv-text-16;
  }
}

.signup__container {
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 0 0 $gvds-spacing--200;
  max-height: calc(100vh - $top-nav-height);
  overflow-y: scroll;

  & > :first-child {
    margin-top: $gvds-spacing--200;
  }
}

.billing-packages__container {
  width: 100%;
  max-width: 1400px;
  padding: 0 $gvds-spacing--300;
}

.signup__form {
  width: 100%;
  max-width: 800px;


  .contact-us__display {
    margin-top: $gvds-spacing--100;
    margin-bottom: $gvds-spacing--300;
  }
}

.billing__signup-done__container {
  width: 100%;
  height: 60vh;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

.billing__signup-done__icon {
  color: $gvds-color--success;

  svg {
    $size: 24px;
    height: $size;
    min-height: $size;
    width: $size;
    min-width: $size;
  }
}

// endregion Sign Up

// region Billing - Plan Displays
.billing-sign-up__plans-selection__container {
  background-color: $color--gray2;

  .gvds-top-nav {
    background-color: $color--gray2;
  }
}

.billing-packages__header {
  display: flex;
  flex-direction: row;
  align-items: center;
  padding: $gvds-spacing--200 0;
  margin-bottom: $gvds-spacing--300;

  .billing-packages__title {
    padding-left: $gvds-spacing--300;
  }

  .billing-packages__illustration-container {
    .billing-packages-illustration {
      height: 120px;
      width: auto;
      transform: translate(0px, 1px);
    }
  }
}

.billing-packages__plans-selection {
  display: grid;

  grid-template-columns: repeat(4, 1fr);
  grid-template-rows: auto;
  gap: 0 $gvds-spacing--300;

  grid-template-areas:
    "plan-enterprise-description plan-0-current-status plan-1-current-status plan-2-current-status"
    "plan-enterprise-description plan-0-title plan-1-title plan-2-title"
    "plan-enterprise-description plan-0-description plan-1-description plan-2-description"
    "plan-enterprise-description plan-0-price plan-1-price plan-2-price"
    "plan-enterprise-action plan-0-action plan-1-action plan-2-action"
    "plan-enterprise-other-info plan-0-feature-included plan-1-feature-included plan-2-feature-included";
}

.billing-packages__plan-display__container-with-status {
  display: contents;
}

.billing-packages__plan-display__container {
  display: contents;

  ul {
    list-style-type: none;
    padding-left: 0;
  }

  li {
    display: flex;
    align-items: center;
  }

  > div {
    border-left: 1px solid $color--gray4;
    border-right: 1px solid $color--gray4;
    padding: 0 $gvds-spacing--300 $gvds-spacing--200 $gvds-spacing--300;
    background-color: $color--gray0;

    &:first-child {
      border-radius: 4px 4px 0 0;
      border-top: 1px solid $color--gray4;
      padding-top: $gvds-spacing--300;
      padding-bottom: $gvds-spacing--100;

      &.is-current-plan {
        border-radius: 0;
      }
    }

    &:last-child {
      border-radius: 0 0 4px 4px;
      border-bottom: 1px solid $color--gray4;
      padding-bottom: $gvds-spacing--300;
    }
  }
}

.billing-packages__footer {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  margin-top: $gvds-spacing--400;
}

@for $i from 0 through 2 {
  .plan-#{$i}-current-status {
    grid-area: plan-#{$i}-current-status;

    .current-plan-status {
      background-color: $color--gray4;
      border-radius: 4px 4px 0 0;
      display: flex;
      align-items: center;
      justify-content: center;
      padding: $gvds-spacing--100 $gvds-spacing--200;
      @include gvds-text--inputField;
    }
  }

  .plan-#{$i}-title {
    grid-area: plan-#{$i}-title;
  }

  .plan-#{$i}-description {
    grid-area: plan-#{$i}-description;
  }

  .plan-#{$i}-price {
    grid-area: plan-#{$i}-price;
  }

  .plan-#{$i}-action {
    grid-area: plan-#{$i}-action;
    display: flex;
    flex-direction: column;

    .gvds-button ~ .gvds-button {
      margin-left: 0;
      margin-top: $gvds-spacing--50;
    }

    .select-plan-button-placeholder {
      margin-bottom: 38px;
    }
  }

  .plan-#{$i}-feature-included {
    grid-area: plan-#{$i}-feature-included;

    .plan__feature-included-heading {
      @include gvds-text--heading4;
      margin-bottom: $gvds-spacing--150;
    }

    li {
      margin-bottom: $gvds-spacing--150;
    }
  }
}

.plan-enterprise {
  > div {
    border: none;
    background-color: $color--gray2;

    &:first-child, &:last-child {
      border: none;
    }
  }

  .plan-enterprise-description {
    grid-area: plan-enterprise-description;

    display: flex;
    flex-direction: column;
    justify-content: flex-end;
  }

  .plan-enterprise-action {
    grid-area: plan-enterprise-action;
    grid-row: span 2;

    .learn-more-button {
      height: 38px;
      margin-top: $gvds-spacing--50;
      color: $color--gray9;
      justify-content: center;
    }

    .enterprise-solution-features {
      margin-top: $gvds-spacing--75;
    }

    .enterprise-solution-features__sign-up {
      margin-top: $gvds-spacing--200;
    }
  }
}

.billing-packages-comparison__container {
  background-color: $color--gray0;
  padding-top: $gvds-spacing--400;
  width: 100%;

  .billing-packages-comparison__feature-comparison-table {
    padding: $gvds-spacing--100 $gvds-spacing--400 $gvds-spacing--400 $gvds-spacing--400;

    display: grid;

    grid-template-columns: repeat(4, 1fr);
    grid-template-rows: auto;

    place-items: stretch;

    & > div {
      padding: $gvds-spacing--150 $gvds-spacing--200;
      border-bottom: 1px solid $gvds-border-color--table;

      &:nth-child(8n+1), &:nth-child(8n+2), &:nth-child(8n+3), &:nth-child(8n+4) {
        background-color: $color--gray2;
      }

      &:nth-child(4n+1), &:nth-child(4n+2), &:nth-child(4n+3) {
        border-right: 1px solid $gvds-border-color--table;
      }

      &:nth-child(1), &:nth-child(2), &:nth-child(3), &:nth-child(4), {
        background-color: $color-white;
        border-right: none;
        position: sticky;
        top: 0;
      }
    }

    .for-hotel-only__caption {
      @include gvds-text--caption;

      color: $color--gray6;
      margin-top: $gvds-spacing--50;
    }
  }
}

.view-pricing-plan-modal {
  .modal-content {
    background-color: $color--gray2;
  }

  .modal-body {
    display: flex;
    flex-direction: column;
    align-items: center;
    padding: 0;
  }
}

// endregion

// region System Toolbox Sign Up Review
.admin-signup-form-review {
  border-radius: 4px;
  border: 1px solid $gvds-border-color--default;
  background-color: $color-tealUltraLightBg;
  padding: $gvds-spacing--200;

  position: sticky;
  top: 20px;

  .section-label {
    @include gvds-text--heading3;
    color: $color--gray6;
    margin: $gvds-spacing--50 0;
  }
}

.admin-signup-outcome-option {
  @extend .filter-card;

  cursor: pointer;

  display: flex;
  flex-direction: column;
  background: $white;

  padding: $gvds-spacing--200;
  height: 100%;
  flex: 0 0 133px;

  transition: 0.3s;
}

.admin-signup__continue-button {
  margin-left: 4px;
  margin-right: 12px;
}

// endregion System Toolbox Sign Up Review

// region System Toolbox - Sites

.account-manager__container {
  margin-top: $gvds-spacing--150;
  margin-bottom: $gvds-spacing--300;

  .account-manager__display {
    @include gvds-text--body;

    color: $color--gray9;
    margin-right: $gvds-spacing--100;
  }

  .account-manager__display__not-assigned {
    @include gvds-text--body;

    color: $color--gray6;
    margin-right: $gvds-spacing--100;
  }
}

//endregion System Toolbox - Sites

// region RTF
.rtf-wrapper {
}

.rtf-editor {
  border: 1px solid $gray-93;
  border-radius: 2px;
  background-color: $color--gray0;
  min-height: 200px;

  h3 {
    @extend .guidance-article-h3;

    .public-DraftStyleDefault-block {
      margin: 0;
    }
  }
}

.rtf-editor.disabled {
  background-color: $gray-93;
}

.rtf-editor.error {
  border-color: $error-border-color;
}

// endregion

// region feature display
.feature-row-container {
  display: flex;
  flex-direction: row;
  align-items: self-end;

  .enabled-feature-icon {
    margin-right: $gvds-spacing--50;

    display: inline-flex;
    align-items: center;
    justify-content: center;
  }
}

// endregion

//region Risk Report
.empty-generated-report-placeholder {
  @include gvds-empty-content;

  padding: $gvds-spacing--300;
  max-height: 80%;
  text-align: center;
}

// endregion

//region Content Type Icon Display
.content-type-icon-display {
  svg {
    color: $color--gray10;
    margin-left: $gvds-spacing--50;
  }
}

// endregion

//region Team Management
.add-user-with-role__container {
  display: flex;
  flex-direction: column;
  @media (min-width: $breakpoint-desktop) {
    flex-direction: row;
  }

  .add-user-with-role__action-button {
    margin-left: auto;
    margin-bottom: $gvds-spacing--150;
    @media (min-width: $breakpoint-desktop) {
      margin-left: $gvds-spacing--100;
    }

    .add-user-with-role__action-button__spacer {
      display: none;
      @media (min-width: $breakpoint-desktop) {
        display: block;
        visibility: hidden;
      }

    }
  }
}

.billing-admin__container {
  display: flex;
  flex-direction: row;
  align-items: center;
  margin-bottom: $gvds-spacing--300;

  label {
    margin-bottom: 0;
  }

  .billing-admin-switch {
    margin-left: $gvds-spacing--100;
    margin-top: -2px;
  }

  &.invite-new-user {
    margin-top: 12px;
    margin-bottom: 0;
  }
}

// endregion

// region Manage Sub-Portfolio
.site-option {
  display: flex;
  justify-content: center;
}

input[type="checkbox"].form-check-input,
input[type="checkbox"].form-check-input:focus:not(:checked) {
  .gv-select-black & {
    border-color: $color--gray10;
  }

  .gv-select-selected & {
    border-color: $gvds-color--tealSolid;
  }

  .gv-select-danger & {
    border-color: $gvds-color--error;
  }
}

input[type="checkbox"].form-check-input:focus {
  .gv-select-black & {
    box-shadow: 0 0 0 0.2rem adjust-color($color--gray10, $alpha: -0.75);
  }

  .gv-select-selected & {
    box-shadow: 0 0 0 0.2rem adjust-color($gvds-color--tealSolid, $alpha: -0.75);
  }

  .gv-select-danger & {
    box-shadow: 0 0 0 0.2rem adjust-color($gvds-color--error, $alpha: -0.75);
  }
}

input[type="checkbox"].form-check-input:checked {
  .gv-select-black & {
    background-color: $color--gray10;
  }

  .gv-select-selected & {
    background-color: $gvds-color--tealSolid;
  }

  .gv-select-danger & {
    background-color: $gvds-color--error;
  }
}

// endregion

// region SystemToolbox - Troubleshoot Data
.troubleshoot-data-tool__container {
  background-color: $widget-background-colour;
  border-radius: 4px;
  padding: $gvds-spacing--300 $gvds-spacing--300 $gvds-spacing--200 $gvds-spacing--300;
  margin-bottom: $gvds-spacing--200;
}

// endregion

// region Policy Tracker
.policy-tracker__filter__container {
  border: 1px solid $color--gray4;
  border-radius: 4px;
  padding: $gvds-spacing--150;
  margin-bottom: $gvds-spacing--200;
  display: grid;
  width: 100%;
  grid-template-columns: 154px 24px 1fr auto;

  .policy-tracker__filter__divider {
    padding: 0 $gvds-spacing--150;
    grid-column: 2;
    grid-row: 1 / 4;
  }

  .policy-tracker__filter__location-label {
    @include gvds-text--formLabel;

    margin-top: $gvds-spacing--150;
    grid-column: 1;
    grid-row: 1;
  }

  .policy-tracker__filter__regulation-label {
    @include gvds-text--formLabel;

    margin-top: $gvds-spacing--150;
    grid-column: 1;
    grid-row: 2;
  }

  .policy-tracker__filter__themes-label {
    @include gvds-text--formLabel;

    margin-top: $gvds-spacing--150;
    grid-column: 1;
    grid-row: 3;
  }

  .policy-tracker__filter__location-dropdown-container {
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    width: 100%;

    grid-column: 3;
    grid-row: 1;
  }

  .policy-tracker__filter__regulation-dropdown-container {
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    width: 100%;
    grid-column: 3;
    grid-row: 2;
  }

  .policy-tracker__filter__themes-dropdown-container {
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    width: 100%;
    grid-column: 3;
    grid-row: 3;
  }

  .btn-clear-all {
    grid-column: 4;
    grid-row: 1 / 4;
  }
}

.policy-tracker__summary__container {
  border: 1px solid $color--gray4;
  border-radius: 4px;
  padding: $gvds-spacing--200;
  margin: 0 0 $gvds-spacing--200 0;

  .policy-tracker__summary__chart__container {
    width: 120px;
    height: 120px;
    margin-right: $gvds-spacing--50;
    padding: 0;
  }

  .policy-tracker__summary__chart-details__container {
    @media (min-width: $breakpoint-laptop) {
      padding: 0 $gvds-spacing--150 $gvds-spacing--150 $gvds-spacing--150;
    }

    @media (min-width: $breakpoint-desktop) {
      padding: $gvds-spacing--150;
    }

    .policy-tracker__summary__priority-level-title {
      @include gvds-text--heading4;

      margin-bottom: $gvds-spacing--50;

      @media (min-width: $breakpoint-laptop) {
        padding-left: 0;
      }

      @media (min-width: $breakpoint-desktop) {
        padding-left: $gvds-spacing--100;
      }
    }

    .policy-tracker__summary__priority-level {
      display: flex;
      flex-direction: row;
      justify-content: space-between;

      .priority-level-category {
        white-space: nowrap;
        cursor: pointer;

        @media (min-width: $breakpoint-laptop) {
          padding: 0;
        }

        @media (min-width: $breakpoint-desktop) {
          padding: $gvds-spacing--100 $gvds-spacing--300 $gvds-spacing--100 $gvds-spacing--100;
        }

        &:hover {
          background-color: $color--gray2;
        }

        .priority-level-count {
          @include gvds-text--heading3;

          margin-left: $gvds-spacing--100;
        }
      }
    }

    .policy-tracker__summary__chart-details-divider {
      margin: $gvds-spacing--150 0;

      @media (min-width: $breakpoint-laptop) {
        padding-left: 0;
      }

      @media (min-width: $breakpoint-desktop) {
        padding-left: $gvds-spacing--100;
      }
    }

    .policy-tracker__summary__transition-risk {
      display: flex;
      flex-direction: row;
      align-items: center;
      padding-top: $gvds-spacing--100;

      @media (min-width: $breakpoint-laptop) {
        padding-left: 0;
      }

      @media (min-width: $breakpoint-desktop) {
        padding-left: $gvds-spacing--100;
      }

      .transition-risk-count {
        @include gvds-text--heading3;

        margin-left: $gvds-spacing--100;
      }
    }
  }

  .policy-tracker__summary__most-common-topics__container {
    padding: 0 0 0 $gvds-spacing--150;

    @media (min-width: $breakpoint-laptop) {
      width: 100%;
    }

    @media (min-width: $breakpoint-small-desktop) {
      width: 29%;
    }

    @media (min-width: $breakpoint-desktop) {
      width: 30%;
    }

    .policy-tracker__summary__most-common-topics__title {
      @include gvds-text--heading4;

      margin-bottom: $gvds-spacing--150;

      @media (min-width: $breakpoint-laptop) {
        margin-top: 0
      }

      @media (min-width: $breakpoint-small-desktop) {
        margin-top: $gvds-spacing--100;
        margin-left: $gvds-spacing--50;
      }
    }

    .policy-tracker__summary__most-common-topics__content {
      div {
        @include gvds-text--inputField;

        cursor: pointer;
        padding: $gvds-spacing--50;

        &:hover {
          background-color: $color--gray2;
        }
      }
    }
  }
}

.policy-tracker__summary__divider-container {
  padding: 0 $gvds-spacing--150;
  display: flex;
  justify-content: center;

  .divider {
    @media (min-width: $breakpoint-laptop) {
      border-left: 1px solid $color--gray4;
      border-top: 1px solid $color--gray4;
      width: 100%;
      margin-bottom: $gvds-spacing--150;
    }

    @media (min-width: $breakpoint-small-desktop) {
      border-left: 1px solid $color--gray4;
      height: 100%;
    }
  }
}

.policy-tracker__table__priority-level {
  ul {
    margin-bottom: 0;
  }

  ul li {
    margin-bottom: $gvds-spacing--75;
  }
}

.policy-tracker__table__compliance-obligation-tooltips {
  @media (max-width: $breakpoint-desktop) {
    width: 220px;
  }
}

.policy-tracker__section-trigger {
  display: flex;
  flex-direction: row;
  align-items: center;
  margin-bottom: $gvds-spacing--200;
}

.esg-icons__container {
  display: flex;
  flex-direction: row;

  :not(:last-child) {
    margin-right: $gvds-spacing--50;
  }
}

.text-icon-aligned-horizontally {
  display: flex;
  flex-direction: row;
  align-content: center;
}

.policy-tracker-details__title__container {
  flex-grow: 1;
}

.download-policy-tracker-button__container {
  min-width: 130px;
  display: flex;
}

.policy-tracker-details__main-content__container {
  width: 70%;
  max-width: 70%;
  margin-right: $gvds-spacing--200;

  .policy-tracker__compliance-requirements__container {
    margin-bottom: $gvds-spacing--200;

    .policy-tracker__compliance-requirements__trigger {
      display: flex;
      flex-direction: row;
      align-content: center;
      margin-bottom: $gvds-spacing--200;

      .policy-tracker__compliance__subtitle {
        @include gvds-text--heading4;

        margin-top: 2px;
      }
    }
  }

  .policy-tracker__threshold-eligibility-criteria__container {
    margin-bottom: $gvds-spacing--200;

    .policy-tracker__threshold-eligibility-criteria__trigger {
      @include gvds-text--heading4;

      display: flex;
      flex-direction: row;
      align-content: center;
      margin-bottom: $gvds-spacing--200;
    }
  }
}

.policy-tracker-details__side-content__container {
  width: 30%;
  max-width: 30%;

  .policy-tracker__side-info__container {
    display: flex;
    flex-direction: row;
    margin-bottom: $gvds-spacing--150;

    .policy-tracker__side-info__label {
      @include gvds-text--inputField;

      width: 45%;
      max-width: 45%;
    }

    .policy-tracker__side-info__value {
      width: 55%;
      max-width: 55%;
      color: $color--gray9;

      .esg-theme {
        display: flex;
        flex-direction: row;
        align-content: center;

        .esg-icon {
          margin-top: -5px;
        }
      }
    }
  }
}

.policy-tracker-info-card__container {
  padding: $gvds-spacing--200;
  border: none;
  border-radius: 4px;
  background: $color--gray2;
  margin-bottom: $gvds-spacing--300;

  .title {
    @include gvds-text--heading3;

    margin-bottom: $gvds-spacing--200;
  }
}

.policy-tracker__related-information__container {
  a {
    word-break: break-all;
  }
}

.policy-tracker__sticky-filter-section__container {
  position: absolute;
  top: 47px;
  background-color: $color--gray0;
  border: 1px solid $color--gray4;
  z-index: 99;

  &.with-announcement-bar {
    top: 83px;
  }

  @media (min-width: $breakpoint-laptop) {
    margin-left: -45px;
    width: calc(100% - 45px);
  }

  @media (min-width: $breakpoint-small-desktop) {
    width: calc(100% - 240px);
  }

  .policy-tracker__filter-section-trigger {
    display: flex;
    flex-direction: row;
    align-items: center;
    margin: 0 $gvds-spacing--300;
    cursor: pointer;
  }

  .policy-tracker__filter-section-collapsible {
    margin: 0 $gvds-spacing--300 $gvds-spacing--300 $gvds-spacing--300
  }
}

.policy-tracker__section-with-line-break {
  white-space: pre-line;
}

.policy-tracker__about-modal__content {
  font-weight: 500;
  font-size: 14px;
  line-height: 21px;
  color: $color--gray9
}

// endregion

// region Divider
.divider__container {
  display: flex;
  justify-content: center;

  .divider-horizontal {
    border-top: 1px solid $color--gray4;
    width: 100%;
  }

  .divider-vertical {
    border-left: 1px solid $color--gray4;
    height: auto;
  }
}

// endregion

// region SystemToolbox - Homepage Announcement

.system-toolbox--homepage-announcement__table tbody td {
  vertical-align: top !important;
  line-height: 32px;

  .system-toolbox--homepage-announcement__table__title-display {
    padding-top: $gvds-spacing--75;
  }
}

.system-toolbox--homepage-announcement__empty-list__placeholder {
  @include gvds-empty-content;

  padding: $gvds-spacing--200 $gvds-spacing--300 $gvds-spacing--200 $gvds-spacing--300;
  height: 220px;
  text-align: center;
}

.system-toolbox--preview-homepage-announcement__container {
  height: 290px;
}

.system-toolbox--preview-homepage-announcement__display-empty {
  @include gvds-text--caption;
  height: 200px;
}

// endregion

// region SystemToolbox - Certification
.system-toolbox--certification__setup-title {
  display: flex;
  flex-direction: row;
  align-items: end;
  gap: $gvds-spacing--150;
}

.system-toolbox--certification__name-label {
  @include gvds-text--formLabel;
  color: $color--gray9;
  padding-bottom: 3px;
}

.system-toolbox--certification__category-container,
.system-toolbox--certification__criteria-container {
  display: flex;
  flex-direction: column;
  border: 1px solid $color--gray4;
  border-radius: $gvds-spacing--50;
}

.system-toolbox--certification__content-button-container {
  width: 60px;
  display: flex;
  flex-direction: row;

  &.thin-margin-top {
    margin-top: -4px;
  }

  .gvds-button {
    &:disabled {
      background-color: unset;
    }
  }

  .gvds-button + .gvds-button {
    margin-left: 0;
  }
}

.system-toolbox--certification__add-content-button {
  width: 100%;
  padding: $gvds-spacing--100 $gvds-spacing--200 $gvds-spacing--100 $gvds-spacing--200;
  height: 50px;
}

.system-toolbox--certification__category {
  display: flex;
  flex-direction: row;
  align-items: center;
  border-bottom: 1px solid $color--gray4;
  min-height: 50px;
  padding: $gvds-spacing--100 $gvds-spacing--250 $gvds-spacing--100 $gvds-spacing--250;
  word-break: break-word;

  &.selected {
    background-color: $gvds-color--alpha;
  }

  &:hover {
    background-color: $gvds-color--alpha;
    cursor: pointer;
  }
}

.system-toolbox--certification__criteria {
  border-bottom: 1px solid $color--gray4;
  min-height: 50px;
  padding: $gvds-spacing--100 $gvds-spacing--150;

  &:hover {
    background-color: $gvds-color--alpha;
    cursor: pointer;
  }
}

.system-toolbox--certification__criteria__collapsible-toggle-button {
  height: fit-content;
  margin-top: -2px;
  margin-right: $gvds-spacing--100;
}

.system-toolbox--certification__instruction-banner__container {
  margin-left: 78px;
  margin-right: 58px;
}

.system-toolbox--certification__instruction-banner {
  margin-bottom: 0;
  padding: $gvds-spacing--100;
}

.system-toolbox--certification__criteria-display__number {
  @include gvds-text--heading4;
  padding: $gvds-spacing--50 $gvds-spacing--100 $gvds-spacing--50 $gvds-spacing--100;
  background-color: $color--gray3;
  border-radius: $gvds-spacing--50;
  margin-right: $gvds-spacing--150;
  height: fit-content;
}

.system-toolbox--certification__criteria-display__name {
  display: flex;
  align-items: center;
  padding-bottom: 5px;

  div {
    @include gvds-text--heading4;
  }
}

.system-toolbox--certification__criteria-display__name {
  @include gvds-text--heading4;
}

.system-toolbox--certification__criteria-form__category-field {
  display: flex;
  flex-direction: row;
  padding-bottom: $gvds-spacing--150;

  .system-toolbox--certification__criteria-form__category-field__label {
    @include gvds-text--formLabel;
    width: 100px;
  }
}

.system-toolbox--certification__move-criteria-banner {
  padding: $gvds-spacing--100;
  margin-bottom: $gvds-spacing--150;
}

.system-toolbox--certification__move-criteria-divider {
  margin-top: $gvds-spacing--150;
  margin-bottom: $gvds-spacing--150;
}

.system-toolbox--certification__requirements__content-container {
  padding: $gvds-spacing--100 $gvds-spacing--200 $gvds-spacing--100 $gvds-spacing--400;
  border-bottom: 1px solid $color--gray4;
}

.system-toolbox--certification__requirements__content {
  border: 1px solid $color--gray4;
  border-radius: $gvds-spacing--50;
}

.system-toolbox--certification__requirement {
  border-bottom: 1px solid $color--gray4;
  min-height: 50px;
  padding: $gvds-spacing--100 $gvds-spacing--150;

  &:hover {
    background-color: $gvds-color--alpha;
  }
}

.system-toolbox--certification__requirement-form__information-field {
  display: flex;
  flex-direction: row;
  padding-bottom: $gvds-spacing--150;

  .system-toolbox--certification__requirement-form__information-field__label {
    @include gvds-text--formLabel;
    min-width: 100px;
  }
}

.system-toolbox--certification__requirement-display__number {
  @include gvds-text--body;
  padding: $gvds-spacing--50 $gvds-spacing--100 $gvds-spacing--50 $gvds-spacing--100;
  background-color: $color--gray3;
  border-radius: $gvds-spacing--50;
  margin-right: $gvds-spacing--150;
  height: fit-content;
}

.system-toolbox--certification__requirement-display__name {
  display: flex;
  align-items: center;
  padding-bottom: 5px;

  div {
    @include gvds-text--body;
  }
}

.system-toolbox--certification__requirement-display__banner--subrequirement-label__container {
  margin-left: 53px;
  margin-right: 58px;
}

.system-toolbox--certification__add-requirement-button {
  width: 100%;
  padding: $gvds-spacing--100 $gvds-spacing--200 $gvds-spacing--100 $gvds-spacing--200;
  height: 50px;
}

.system-toolbox--certification__requirement-evidence__container {
  display: flex;
  flex-direction: column;
  border: 1px solid $color--gray4;
  border-radius: $gvds-spacing--50;
}

.system-toolbox--certification__requirement-evidence__label {
  padding: $gvds-spacing--150 $gvds-spacing--150 0 $gvds-spacing--150;
}

.system-toolbox--certification__requirement-evidence__content {
  padding: $gvds-spacing--150;
  border-bottom: 1px solid $color--gray4;

  .delete-evidence-btn {
    @extend .color-red;

    margin-left: $gvds-spacing--200;
  }
}

.system-toolbox--certification__requirement-subrequirement__container {
  display: flex;
  flex-direction: column;
  border: 1px solid $color--gray4;
  border-radius: $gvds-spacing--50;
}

.system-toolbox--certification__requirement-subrequirement__section-toggle {
  display: flex;
  flex-direction: row;
  margin-bottom: $gvds-spacing--100;
}

.system-toolbox--certification__move-requirement-banner {
  padding: $gvds-spacing--100;
  margin-bottom: $gvds-spacing--150;
}

.system-toolbox--certification__move-requirement-divider {
  margin-top: $gvds-spacing--150;
  margin-bottom: $gvds-spacing--150;
}

.system-toolbox--certification__sub-requirement-banner__container {
  padding: $gvds-spacing--150 $gvds-spacing--150 $gvds-spacing--100 $gvds-spacing--150;
}

.system-toolbox--certification__requirement-subrequirement__section {
  padding: 0 $gvds-spacing--150 0 $gvds-spacing--150;
  border-bottom: 1px solid $color--gray4;
}

.system-toolbox--certification__requirement-subrequirement__list-container {
  border-left: 1px solid $color--gray4;
  padding-left: $gvds-spacing--150;
  margin-top: $gvds-spacing--100;
}

.system-toolbox--certification__add-subrequirement-button {
  padding: 0 $gvds-spacing--150 0 $gvds-spacing--150;
  height: 50px;
}

.system-toolbox--certification__add-section-button-container {
  width: 100%;
  padding: $gvds-spacing--100 $gvds-spacing--150 $gvds-spacing--150 $gvds-spacing--150;

  .system-toolbox--certification__add-section-button {
    width: 100%;
  }
}

// endregion

// region Certification - Homepage
@mixin certification-homepage__structure-image-frame {
  border: 2px solid $gvds-color--tealSolid;
  margin: $gvds-spacing--150 $gvds-spacing--200 $gvds-spacing--100 $gvds-spacing--200;
  padding-bottom: $gvds-spacing--150;
  text-align: center;
}


.certification-homepage__title__container {
  display: flex;
  flex-grow: 1;

  .info-tooltip-icon {
    svg {
      height: 20px;
      min-height: 20px;
      width: 20px;
      min-width: 20px;
    }
  }
}

.certification-homepage__about-logo {
  width: 100px;
  height: 100px;
  margin-left: auto;
  object-fit: contain;
}

.certification-homepage__structure-container {
  display: flex;
  flex-direction: row;
  justify-content: center;

  .structure-description {
    @include gvds-text--body;

    margin-right: $gvds-spacing--300;
  }

  .structure-image {
    border: 2px solid $gvds-color--tealSolid;
    text-align: center;
    padding-bottom: $gvds-spacing--150;
    height: fit-content;

    .parent-frame {
      @include certification-homepage__structure-image-frame;

      .child-frame {
        @include certification-homepage__structure-image-frame;

        background-color: $gvds-color--tealLight;

        .child-frame__content-container {
          display: flex;
          flex-direction: row;
          justify-content: center;
          margin: $gvds-spacing--150 $gvds-spacing--150 $gvds-spacing--100 $gvds-spacing--150;

          .child-frame__content {
            border: 2px dashed $gvds-color--tealSolid;
            padding: $gvds-spacing--100;
            text-align: center;
            color: $gvds-color--tealSolid;
            background-color: $color--gray0;
            font-size: 12px;
            font-weight: 500;
          }
        }
      }
    }
  }

  .structure-image-label {
    font-size: 12px;
    font-weight: 500;
    color: $gvds-color--tealSolid;
  }
}

.certification-homepage__readiness-score__container {
  border: 1px solid $color--gray4;
  border-radius: 4px;
  padding: $gvds-spacing--200;
  margin: 0 0 $gvds-spacing--150 0;

  .certification-homepage__readiness-score__label {
    display: flex;
    flex-direction: row;
    align-items: center;
  }

  .progress {
    margin-top: $gvds-spacing--100;
    background-color: $gvds-color--alpha;
    height: 10px;
    border-radius: 5px;
    padding: 1px;

    .progress-bar {
      height: 8px;
      border-radius: 4px;
      background-color: $gvds-color--tealSolid;
    }
  }
}

.certification-homepage__category-filters__container {
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  margin-bottom: $gvds-spacing--150;

  .category-title {
    @include gvds-text--heading4;

    margin-right: $gvds-spacing--150;
  }
}

.certification-homepage__criterion-filter__container {
  display: flex;
  align-items: center;
  margin-bottom: $gvds-spacing--150;

  .certification-homepage__criterion-filter__search-box {
    max-width: 450px;
    margin-right: $gvds-spacing--200;
    padding-right: calc($gvds-spacing--50 + $icon-size + $gvds-spacing--50);
  }
}

.certification-homepage__criterion__container {
  display: flex;
  flex-direction: row;
  align-items: center;

  @include question-card__container;
  margin-bottom: $gvds-spacing--100;

  &.completed {
    @include question-card__container-active;
  }

  &.hoverable {
    cursor: pointer;

    &:hover {
      @include gvds-shadow--small;
    }
  }

  .certification-homepage__criterion__details__container {
    display: flex;
    flex-grow: 1;
    margin-right: $gvds-spacing--200;

    .certification-homepage__criterion__number {
      @include gvds-text--heading4;

      margin-right: $gvds-spacing--150;
    }

    .certification-homepage__criterion__description {
      @include gvds-text--caption;

      //https://www.geeksforgeeks.org/how-to-apply-an-ellipsis-to-multiline-text-in-css/
      word-break: break-word;
      overflow: hidden;
      text-overflow: ellipsis;
      display: -webkit-box;
      -webkit-line-clamp: 2;
      -webkit-box-orient: vertical;
    }
  }

  .certification-homepage__criterion__category {
    border: 1px solid $color--gray4;
    border-radius: 8px;
    padding: $gvds-spacing--100 $gvds-spacing--75;
    margin-right: $gvds-spacing--300;
    text-align: center;
    user-select: none;
  }

  .certification-homepage__criterion__requirements {
    min-width: 102px;
    margin-right: $gvds-spacing--200;

    .progress {
      margin-top: $gvds-spacing--100;
      background-color: $gvds-color--alpha;
      height: 10px;
      border-radius: 5px;
      padding: 1px;

      .progress-bar {
        height: 8px;
        border-radius: 4px;
        background-color: $gvds-color--tealSolid;
      }
    }
  }
}

.certification-homepage__filters__container {
  &.sticky {
    position: absolute;
    top: 48px;
    background-color: $color--gray0;
    padding-top: $gvds-spacing--150;
    z-index: 99;
  }

  &.with-announcement-bar {
    top: 84px;
  }

  @media (min-width: $breakpoint-laptop) {
    width: calc(100% - 150px);
  }

  @media (min-width: $breakpoint-small-desktop) {
    width: calc(100% - 330px + $gvds-spacing--150 + $gvds-spacing--150);
    margin-left: -$gvds-spacing--150;
    padding-left: $gvds-spacing--150;
    padding-right: $gvds-spacing--150;
  }

}

// endregion

// region Certification - Content page
.certification-content-page__instruction-banner {
  margin-top: $gvds-spacing--150;
}

.certification-content-page__content__container {
  margin-top: $gvds-spacing--200;

  .certification-content-page__content__requirement__container {
    display: flex;
    flex-direction: column;
    border: 1px solid $color--gray4;
    border-radius: $gvds-spacing--50;
    margin-bottom: $gvds-spacing--200;

    .certification-content-page__content__requirement__title {
      display: flex;
      flex-direction: row;
      align-items: center;
      padding: $gvds-spacing--200 $gvds-spacing--100 $gvds-spacing--200 $gvds-spacing--150;
      border-bottom: 1px solid $color--gray4;
      flex-wrap: wrap;
    }

    .certification-content-page__content__requirement__grid-container {
      display: grid;
      grid-template-columns: auto 1fr $gvds-spacing--600;
      grid-auto-rows: max($gvds-spacing--600);

      .certification-content-page__content__requirement {
        display: contents;
        min-height: $gvds-spacing--600;

        &.selected > div {
          background-color: $gvds-color--alpha;
          color: $gvds-color--tealSolid;
        }

        &:hover > div {
          background-color: $gvds-color--alpha;
          color: $color--gray10;
          cursor: pointer;
        }

        &.not-applicable {
          > div {
            background-color: $color--gray2;
            color: $color--gray6;
          }

          &.selected > div {
            background-color: $gvds-color--alpha;
          }

          &:hover > div {
            background-color: $gvds-color--alpha;
          }
        }

        .requirement-number {
          @include gvds-text--label;

          border-bottom: 1px solid $color--gray4;
          padding: 10px 0 $gvds-spacing--100 $gvds-spacing--150;
        }

        .requirement-name {
          @include gvds-text--caption;

          border-bottom: 1px solid $color--gray4;
          padding: $gvds-spacing--100 0 $gvds-spacing--100 $gvds-spacing--100;

          word-break: break-word;
          overflow: hidden;
          text-overflow: ellipsis;
          display: -webkit-box;
          -webkit-line-clamp: 2;
          -webkit-box-orient: vertical;
        }

        .requirement-check__container {
          border-bottom: 1px solid $color--gray4;
          padding: 10px $gvds-spacing--250 $gvds-spacing--100 $gvds-spacing--100;
        }
      }
    }
  }

  .certification-content-page__content__details__container {
    .action-buttons__container {
      display: flex;
      flex-direction: row;
      align-items: center;
      border-bottom: 1px solid $color--gray4;
      padding-bottom: $gvds-spacing--100;
      margin-bottom: $gvds-spacing--150;
      flex-wrap: wrap;

      .applicable-completed__container {
        display: flex;
        flex-direction: row;
        align-items: center;

        @media (max-width: $breakpoint-small-desktop) {
          width: 100%;
          margin-bottom: $gvds-spacing--200;
        }

        .mark-as-completed__container {
          display: flex;
          flex-direction: row;
          align-items: center;
          margin-left: $gvds-spacing--300;
        }
      }

      .evidence-comments__container {
        @media (min-width: $breakpoint-small-desktop) {
          margin-left: auto;
        }
      }
    }

    .content__details__title {
      display: flex;
      font-weight: 600;
      font-size: 16px;
      line-height: 20px;
      margin-bottom: $gvds-spacing--150;

      .requirement-number {
        margin-right: $gvds-spacing--100;
      }
    }

    .content__details__instruction-banner {
      margin-bottom: $gvds-spacing--150;
    }

    .content__details__sub-requirement-section__container {
      margin-bottom: $gvds-spacing--100;

      .content__details__sub-requirement-section {
        @include gvds-text--heading4;

        padding-left: $gvds-spacing--100;
      }

      .content__details__sub-requirement {
        display: flex;
        flex-direction: row;
        align-items: center;
        margin-bottom: $gvds-spacing--100;

        .sub-requirement__content {
          white-space: pre-line;
          margin-left: $gvds-spacing--100;
        }
      }
    }
  }
}

.collapsible-section__container {
  border-top: 1px solid $color--gray4;
  padding-top: $gvds-spacing--150;
  margin-bottom: $gvds-spacing--200;

  .collapsible-section__title {
    display: flex;
    flex-direction: row;
    align-items: center;
    cursor: pointer;
  }

  .collapsible-section__content {
    margin-left: $gvds-spacing--150;

    ul {
      margin-bottom: 0;
    }
  }
}

$certification-offcanvas-height-after-tabs: calc(100vh - 115px);

.certification-comments__page-container {
  display: flex;
  flex-direction: column;
  height: $certification-offcanvas-height-after-tabs;
}

.certification-comments__comments-layout {
  flex-grow: 1;
  overflow-y: scroll;
}

.certification-comments__add-comment-layout {
  margin-top: $gvds-spacing--200;
}

.certification-comments__add-comment-container {
  display: flex;
  flex-direction: row;
  align-items: center;
}

.certification-comments__add-comment-input-container {
  flex-grow: 1;

  display: flex;
  flex-direction: row;
  align-items: center;

  background-color: $default-bg-color;
  border: 1px solid $default-border-color;
  border-radius: 4px;
  padding: $gvds-spacing--50;
  margin-left: $gvds-spacing--50;

  &:focus-within {
    border: 1px solid $focus-border-color;
  }

  .certification-comments__add-comment-textarea-container {
    padding-right: $gvds-spacing--50;
    flex-grow: 1;

    textarea {
      @include unstyled-textarea;
    }
  }
}

.certification-comments__display-comment__container {
  display: flex;
  flex-direction: row;

  .gvds-avatar {
    margin-top: $gvds-spacing--25;
  }

  .certification-comments__display-comment__content {
    flex-grow: 1;
    margin-left: $gvds-spacing--25;

    display: flex;
    flex-direction: column;

    textarea {
      @include unstyled-textarea;
      padding-top: 0;
      padding-left: 0;
    }
  }

  .certification-comments__display-comment__header {
    display: flex;
    flex-direction: row;
    align-items: center;
  }
}

.certification-comments__edit-comment-container {
  flex-grow: 1;

  background-color: $default-bg-color;
  border: 1px solid $default-border-color;
  border-radius: 4px;

  .certification-comments__edit-comment-textarea-container {
    textarea {
      @include unstyled-textarea;
    }
  }

  .certification-comments__edit-comment-footer {
    display: flex;
    padding: $gvds-spacing--100 0;
    margin: 0 $gvds-spacing--100;
    border-top: 1px solid $default-border-color;
  }
}

.certification-evidence__page-container {
  display: flex;
  flex-direction: column;
  height: $certification-offcanvas-height-after-tabs;
  overflow-y: auto;
}

.certification-evidence__evidence-list-item__container {
  margin-bottom: $gvds-spacing--100;

  padding: $gvds-spacing--100 0 $gvds-spacing--100 $gvds-spacing--150;
  border-radius: 4px;
  border: 1px solid $default-border-color;

  display: flex;
  flex-direction: row;
  align-items: center;

  .evidence-file-name {
    flex-grow: 1;
    word-break: break-word;
  }
}

// endregion

.joined-bullet {
  font-size: 5px;
  margin: 0 $gvds-spacing--100;
  min-width: 5px;
  width: 5px;
}

.full-page-loading-spinner__container {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 9999;
}

.full-page-loading-spinner__container::before {
  content: "";
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: $color--gray10;
  opacity: 0.15;
}