.accordion {
  .accordion-item {
    border: none;
  }

  .accordion-body {
    padding: 0;
  }
}

.gvds-accordion-header {
  display: flex;
  align-items: center;
  cursor: pointer;
  background-color: $color--gray2;
  border: 1px solid $color--gray4;
  height: 40px;
  padding: $gvds-spacing--100 $gvds-spacing--200;

  &:hover {
    background-color: $accordion-hover-bg;
  }

  .gvds-accordion-header__container {
    display: flex;
    flex-direction: row;
    align-items: center;
    flex-grow: 1;
    gap: $gvds-spacing--75;
    font-size: 14px;
    font-weight: 600;

    .gvds-accordion-header__count {
      margin-left: $gvds-spacing--200;
      color: $color--gray9;
      font-size: 12px;
      font-style: normal;
      font-weight: 500;
    }
  }
}