// region Base Mixin
@mixin gvds-text--dataLarge {
  font-weight: 600;
  font-size: 27px;
  line-height: 125%;
}

@mixin gvds-text--heading1 {
  font-weight: 600;
  font-size: 24px;
  line-height: 125%;
}

@mixin gvds-text--heading2 {
  font-weight: 600;
  font-size: 22px;
  line-height: 125%;
}

@mixin gvds-text--heading3 {
  font-weight: 600;
  font-size: 18px;
  line-height: 125%;
}

@mixin gvds-text--heading4 {
  font-weight: 600;
  font-size: 14px;
  line-height: 125%;
}

@mixin gvds-text--button {
  font-weight: 600;
  font-size: 14px;
  line-height: 125%;
}

@mixin gvds-text--formLabel {
  font-weight: 600;
  font-size: 14px;
  line-height: 125%;
  color: $color--gray10;
}

@mixin gvds-text--inputField {
  font-weight: 500;
  font-size: 14px;
  line-height: 125%;
}

@mixin gvds-text--link {
  font-weight: 500;
  font-size: 14px;
  color: $link-color;
  line-height: 150%;
}

@mixin gvds-text--body {
  font-weight: 400;
  font-size: 14px;
  line-height: 125%;

  strong {
    font-weight: 600;
  }
}

@mixin gvds-text--caption {
  font-weight: 500;
  font-size: 12px;
  line-height: 150%;
}

@mixin gvds-text--label {
  font-size: 12px;
  font-weight: 500;
  line-height: 125%;
}

@mixin gvds-monospaced-number {
  font-variant-numeric: tabular-nums lining-nums;
}

// endregion

// region Convenient classes
.gvds-text--heading1 {
  @include gvds-text--heading1;
}

.gvds-text--heading2 {
  @include gvds-text--heading2;
}

.gvds-text--heading3 {
  @include gvds-text--heading3;
}

.gvds-text--heading4 {
  @include gvds-text--heading4;
}

.gvds-text--formLabel {
  @include gvds-text--formLabel;
}

.gvds-text--inputField {
  @include gvds-text--inputField;
}

.gvds-text--body {
  @include gvds-text--body;
}

.gvds-text--body__bold {
  @include gvds-text--body;
  font-weight: 600;
}

.gvds-text--body__italic {
  @include gvds-text--body;
  font-style: italic;
}

.gvds-text--link {
  @include gvds-text--link;
}

.gvds-text--link__bold {
  @include gvds-text--link;
  font-weight: 600;
}

.gvds-text--caption {
  @include gvds-text--caption;
}

.gvds-text--caption__italic {
  @include gvds-text--caption;
  font-style: italic;
}

.gvds-text--label {
  @include gvds-text--label;
}

.gvds-monospaced-number {
  @include gvds-monospaced-number;
}

.gvds-monospaced-number__bold {
  @include gvds-monospaced-number;
  font-weight: 800;
}

// endregion

.gvds-text--modal-warning-text {
  @include gvds-text--caption;
  color: $color--gray6;

}

.gvds-text--caption-empty-state {
  @include gvds-text--caption;
  color: $color--gray6;
  font-style: italic;
}
