@import "greenview-base-color";

@mixin gv-text-12 {
  font-size: 12.64px;
  font-weight: 400;
  line-height: 15.17px;
}

.gv-text-12 {
  @include gv-text-12;
}

.gv-text-12--bold {
  @include gv-text-12;
  font-weight: 700;
}

.gv-text-12--extra-bold {
  @include gv-text-12;
  font-weight: 800;
}

@mixin gv-text-14 {
  font-size: 14.22px;
  font-weight: 400;
  line-height: 17.06px;
}

.gv-text-14 {
  @include gv-text-14;
}

.gv-text-14--bold {
  @include gv-text-14;
  font-weight: 700;
}

.gv-text-14--extra-bold {
  @include gv-text-14;
  font-weight: 800;
}

@mixin gv-text-16 {
  font-size: 16px;
  font-weight: 400;
  line-height: 21.28px;
}

.gv-text-16 {
  @include gv-text-16;
}

@mixin gv-heading-1 {
  font-weight: 700;
  font-size: 28.83px;
  line-height: 34.6px;
  font-style: normal;
}

.gv-heading-1 {
  @include gv-heading-1;
}

@mixin gv-heading-2 {
  font-weight: 700;
  font-size: 25.63px;
  line-height: 31px;
}

.gv-heading-2 {
  @include gv-heading-2;
}

@mixin gv-heading-3 {
  font-weight: 700;
  font-size: 22.78px;
  line-height: 27.34px;
}

.gv-heading-3 {
  @include gv-heading-3;
}

@mixin gv-heading-4 {
  font-weight: 700;
  font-size: 20.25px;
  line-height: 24.3px;
}

.gv-heading-4 {
  @include gv-heading-4;
}

@mixin gv-heading-5 {
  font-weight: 700;
  font-size: 18px;
  line-height: 21.6px;
}

.gv-heading-5 {
  @include gv-heading-5;
}

@mixin gv-body-1 {
  font-weight: 600;
  font-size: 14px;
  line-height: 21px;
}

.gv-body-1 {
  @include gv-body-1;
}

.gv-body-1--bold {
  @include gv-body-1;
  font-weight: 700;
}

.gv-body-1--extra-bold {
  @include gv-body-1;
  font-weight: 800;
}

@mixin gv-body-2 {
  font-weight: 600;
  font-size: 16px;
  line-height: 21px;
}

.gv-body-2 {
  @include gv-body-2;
}

.gv-body-2--bold {
  @include gv-body-2;
  font-weight: 700;
}

.gv-body-2--extra-bold {
  @include gv-body-2;
  font-weight: 800;
}

@mixin caption {
  font-size: 13px;
  line-height: 19px;
  letter-spacing: 0.01em;
}

@mixin clickable {
  cursor: pointer;
}

.clickable {
  @include clickable;
}

@mixin elevation-0 {
  box-shadow: none;
}

@mixin elevation-1 {
  box-shadow: 0 2px 2px $key-light-shadow, 0 2px 8px $ambient-light-shadow;
}

@mixin elevation-2 {
  box-shadow: 0 4px 4px $key-light-shadow, 0 4px 16px $ambient-light-shadow;
}

@mixin elevation-3 {
  box-shadow: 0 8px 8px $key-light-shadow, 0 8px 32px $ambient-light-shadow;
}

/* Single Select Base Styles */
.single-select {
  font-size: 14.22px;

  .react-select__control {
    border-color: $gray-56;

    &:hover {
      border-color: $black;
      cursor: pointer;

      .react-select__placeholder {
        color: $black;
      }
    }

    &--is-focused {
      box-shadow: none;
      border-color: $blue;

      .react-select__indicator {
        color: $blue;
      }

      .react-select__placeholder {
        color: $black;
      }

      &:hover {
        border-color: $blue;
        cursor: pointer;
      }
    }

    &--is-disabled {
      background-color: $gray-93;
      border-color: $gray-85;

      .react-select__indicator {
        color: $gray-85;
      }

      .react-select__single-value {
        color: $gray-56;
      }

      &:hover {
        .react-select__placeholder {
          color: $gray-56;
        }

        .react-select__indicator {
          color: $gray-85;
        }

        .react-select__single-value {
          color: $gray-56;
        }

        background-color: $gray-93;
        border-color: $gray-85;
        pointer-events: auto;
        cursor: not-allowed;
      }
    }
  }

  .react-select__option {
    background-color: $white;
    color: $black;

    &:hover {
      cursor: pointer;
    }

    &--is-focused {
      background-color: $gray-93;
    }

    &--is-selected {
      background-color: $gray-98;
      font-weight: 700;

      &:after {
        font-family: "Font Awesome 5 Free";
        content: "\f00c";
        padding-left: 8px;
      }
    }

    &--is-disabled {
      color: $gray-85;

      &:hover {
        pointer-events: auto;
        cursor: not-allowed;

      }
    }

  }

  .react-select__placeholder {
    color: $gray-56;
  }

  .react-select__single-value {
    color: $black;
  }

  .react-select__indicator {
    color: $gray-56;
  }

  .react-select__indicator-separator {
    width: 0;
  }

  &.invalid {
    .react-select__control {
      border-color: $red;
    }

    .react-select__placeholder {
      color: $red;
    }

    .react-select__indicator {
      color: $red;
    }
  }

}

.single-select-invalid-feedback {
  font-weight: 400;
  font-size: 12px;
  display: block;
  color: $red;
  margin-top: 0.25rem;

  form & {
    margin-top: -0.75rem;
  }
}

/* End of Single Select Base Styles */

.multi-line-text-display {
  white-space: pre-wrap;
}