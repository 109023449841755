$announcement-bar-height: 36px;

.screen-container {
  display: flex;
  flex-direction: column;
  height: 100%;
}

// region Page Containers
$page-vertical-padding: $gvds-spacing--150;

.gvds-page-container {
  height: 100%;

  &.with-announcement-bar {
    height: calc(100% - $announcement-bar-height);
  }
}

.gvds-page-container__side-nav-dominant {
  display: flex;
  flex-direction: row;

  .gvds-side-nav {
    max-height: 100%;
  }

  .logo {
    display: flex;
    align-items: center;
    justify-content: flex-start;
    flex-grow: 1;

    img {
      width: auto;
      height: 24px;
      margin-left: 8px;
    }
  }

  .gvds-page-container__beside-side-nav {
    max-width: calc(100% - $side-nav-width);
    flex-grow: 1;
    height: 100%;
    display: flex;
    flex-direction: column;

    @include gvds-shadow--large;

    &.is-side-nav-minimised {
      max-width: calc(100% - $side-nav-width-minimised);
    }
  }

  .gvds-content-container {
    display: flex;
    flex-direction: row;
    max-height: calc(100% - $top-nav-height);

    height: 100%;
    flex-grow: 1;
    overflow-y: auto !important; // some React Joyride issue https://github.com/gilbarbara/react-joyride/issues/563
    padding: $page-vertical-padding $gvds-spacing--400;
    background-color: $empty-space-background-color;

    &.no-top-nav {
      max-height: 100%;
    }

    > div {
      width: 100%;

      &::after {
        content: "";
        display: block;
        height: $gvds-spacing--400;
        width: 100%;
      }
    }

    &.local-page {
      background-color: $color--gray2;
      padding: $page-vertical-padding $gvds-spacing--200;

      .local-page--content {
        background-color: $color-white;
        padding: $gvds-spacing--150 $gvds-spacing--400;
        border-radius: $gvds-spacing--100;
        height: fit-content;
        min-height: 100%;
        display: flex;
        flex-direction: column;
      }

      > div {
        &::after {
          content: "";
          display: block;
          height: $gvds-spacing--100;
          width: 100%;
        }
      }
    }

    &.fullspace-page {
      background-color: $color--gray2;
      padding: 0;
      overflow-y: clip !important;

      > div {
        &::after {
          content: none;
        }
      }

      .no-content-container {
        padding: $page-vertical-padding $gvds-spacing--400;
        background-color: $empty-space-background-color;

        display: flex;
        flex-direction: row;

        > div {
          &::after {
            content: "";
            display: block;
            height: $gvds-spacing--400;
            width: 100%;
          }
        }
      }
    }
  }
}

// endregion

// region Shared containers and components
.page-loading-container {
  flex-grow: 1;
  display: flex;
  align-items: center;
  justify-content: center;
}

.page-wrapper--unavailable-container {
  @include gvds-empty-content;

  min-height: 10rem;
  max-height: 80%;
  padding: $gvds-spacing--100;

  .message {
    text-align: center;
    width: 100%;
    word-wrap: break-word;
  }
}

// endregion

// region Sticky

@mixin sticky-top {
  position: sticky;
  top: -$page-vertical-padding;
}

@mixin sticky-bottom {
  position: sticky;
  bottom: -$page-vertical-padding;
}

.floating-action-container {
  @include sticky-bottom;
  z-index: $gvds-z-index--sticky-page-container;
  background-color: white;
  padding-top: $gvds-spacing--200;
  padding-bottom: $gvds-spacing--200;

  &.with-border {
    border-top: 1px solid $color--gray4;
  }
}

// endregion

// region Portalling
#portalling-target {
  position: relative;
  z-index: $gvds-z-index--portalling-target;
}

// endregion

.gvds-announcement-bar {
  height: $announcement-bar-height;
  background-color: $gvds-accent-color--lime;
  z-index: $gvds-z-index--sticky-page-container;

  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;

  .gvds-announcement-bar__content {
    flex-grow: 1;
    display: flex;
    flex-direction: row;
    justify-content: center;
  }

  .gvds-announcement-bar__message {
    @include gvds-text--inputField;
  }

  .gvds-announcement-bar__cta {
    @include clickable;
    @include gvds-text--button;

    text-decoration: underline;
    margin-left: $gvds-spacing--100;
  }

  .gvds-announcement-bar__close {
    margin-right: $gvds-spacing--200;
    cursor: pointer;
  }
}