$toast-icon-dimension: 24px;

.toasts-container {
  position: fixed;
  top: 76px;
  right: $gvds-spacing--200;
  z-index: $gvds-z-index--toast;
  max-width: 30%;
}

.toast-display {
  @include gvds-shadow--large;
  padding: $gvds-spacing--150;
  font-size: 12px;
  color: $color--gray10;
  border: none;
  margin-bottom: $gvds-spacing--100;

  animation: slideIn 0.3s ease-in-out;
  animation-fill-mode: forwards;

  .toast-display-header {
    display: flex;
    align-items: center;
    padding: 0;

    background: inherit;
    border: none;

    color: inherit;
    font-weight: 600;


    button.btn-close {
      margin-left: auto;
      margin-right: $gvds-spacing--50;
    }

    .toast-display-header--icon {
      display: flex;
      align-items: center;
      margin-right: $gvds-spacing--100;

      svg {
        width: $toast-icon-dimension;
        height: $toast-icon-dimension;
      }
    }
  }

}

@keyframes slideIn {
  from {
    transform: translateX(100%);
  }
  to {
    transform: translateX(0);
  }
}


.toast-display-fail {
  background: $color-toast-error;

  .toast-display-header--icon {
    svg {
      color: $gvds-color--error;
    }
  }
}

.toast-display-success {
  background: $color-toast-success;

  .toast-display-header--icon {
    svg {
      color: $gvds-color--success;
    }
  }
}
