// region Floating Assistant
.floating-assistant-menu__container {
  z-index: $gvds-z-index--floating-assistant;
  position: fixed;
  right: $gvds-spacing--200;
  bottom: $gvds-spacing--200;
  top: auto;
}

.floating-assistant-menu {
  position: absolute;
  inset: auto 0 0 auto;

  @include gvds-shadow--large;
  background-color: $white;
  padding-top: $gvds-spacing--200;
  padding-left: $gvds-spacing--200;

  border: 1px solid $color--gray4;
  border-radius: 4px;
  width: 340px;
  max-height: 66vh;

  display: flex;
  flex-direction: column;

  .floating-assistant-menu__header {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
    padding-right: $gvds-spacing--200;
    margin-bottom: $gvds-spacing--200;

    h2 {
      margin-bottom: 0;
    }
  }

  .floating-assistant-menu__content {
    overflow-y: auto;
    flex-grow: 1;
    margin-right: $gvds-spacing--100;
    padding-right: $gvds-spacing--100;
    box-sizing: border-box;

    // ref https://javascript.plainenglish.io/how-to-create-horizontal-vertical-scroll-shadows-26aa7a7f00a0,
    //     https://github.com/mjchang/medium/blob/master/scroll-shadow/src/VerticalCSSScrollShadow.js
    background-image: linear-gradient(to top, $color-white, $color-white),
    linear-gradient(to top, $color-white, $color-white),
    linear-gradient(to top, $gvds-color--tealLight, transparent),
    linear-gradient(to bottom, $gvds-color--tealLight, transparent);
    background-position: bottom center, top center, bottom center, top center;
    background-repeat: no-repeat;
    background-size: 100% 20px, 100% 20px, 100% 10px, 100% 10px;
    background-attachment: local, local, scroll, scroll;
  }

  hr {
    background-color: $gray-85;
  }

  .floating-assistant-menu__footer {
    display: flex;
    flex-direction: column;

    padding-bottom: $gvds-spacing--200;
    padding-right: $gvds-spacing--200;
    margin-top: $gvds-spacing--100;
  }

  .floating-assistant-menu__go-help-center {
    color: $gvds-color--tealSolid;
    font-size: 14px;
    font-weight: 600;
  }
}

.guided-tour-menu {
  display: flex;
  flex-direction: column;
  align-items: start;
  align-self: stretch;

  background: $gvds-color--alpha;
  border-radius: $gvds-spacing--50;

  padding: $gvds-spacing--200;
  margin-bottom: $gvds-spacing--200;

  .guided-tour-menu-text {
    @include gvds-text--body;
    margin-bottom: $gvds-spacing--50;
  }
}

.relevant-help-article-menu {
  margin-bottom: $gvds-spacing--100;

  .relevant-help-article-menu__header {
    @include gvds-text--label;
    margin-bottom: $gvds-spacing--100;
  }

  .relevant-help-article-menu__list-container {
    display: flex;
    flex-direction: column;

    .help-article__container:not(:first-child) {
      margin-top: $gvds-spacing--200;
    }
  }
}

// endregion

// region Guided Tours
.react-joyride__spotlight {
  background-color: rgba(255, 255, 255, 0.4) !important;
}

.guided-tour-tooltip__container {
  background-color: $color-white;
  padding: $gvds-spacing--200;
  border-radius: 8px;
  min-width: 300px;
  max-width: 400px;

  .gvds-icon-container.inline-icon {
    height: 14px;
    min-height: 14px;
    width: 18px;
    min-width: 18px;
    position: relative;

    svg {
      position: absolute;
      top: 50%;
      transform: translateY(-50%);
    }
  }
}

.guided-tour-tooltip__header {
  display: flex;
  margin-bottom: $gvds-spacing--100;
}

.guided-tour-tooltip__body {
  display: flex;
}

.guided-tour-tooltip__dedicated-links {
  margin-top: $gvds-spacing--150;
}

.guided-tour-tooltip__footer {
  margin-top: $gvds-spacing--200;

  width: 100%;
  display: flex;
  align-items: center;
}

.guided-tour-tooltip__title {
  @include gvds-text--heading3;
}

.guided-tour-tooltip__close {
  margin-left: auto;
}

.guided-tour-tooltip__step-display {
  @include gvds-text--caption;
}

// endregion
