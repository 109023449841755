.page-header {
  margin-bottom: $gvds-spacing--200;
  padding: 0;

  > div:not(:last-child) {
    margin-bottom: $gvds-spacing--150;
  }

  div.page-header--back-button-container {
    margin-bottom: $gvds-spacing--75;
  }

  > .page-header--title h1 {
    margin-bottom: 0;
  }
}

button.page-header--back-button {
  margin-left: -12px;
}

.page-header--title {
  display: flex;
  flex-direction: row;
  align-items: center;
}

.page-header--description {
  color: $color--gray9;
  font-size: 14px;
  font-weight: 400;
  line-height: 20px;
}

.page-header--status {
  display: flex;
  flex-direction: row;
  align-items: center;
}