$info-card-icon-dimension: 24px;

.gvds-info-card {
  padding: $gvds-spacing--200;
  border: none;
  color: $color--gray10;
  border-radius: 4px;
  background: $gvds-color--tealLight;
  margin-bottom: $gvds-spacing--100;

  .info-card-heading {
    font-size: 14px;
    font-weight: 600;
    flex-direction: row;
    display: flex;
    margin-bottom: 0;
    min-height: $gvds-spacing--300;

    .info-card-heading--icon {
      display: flex;
      align-items: center;

      svg {
        width: $info-card-icon-dimension;
        height: $info-card-icon-dimension;
        color: $color--gray10;
      }
    }


    .info-card-heading--title {
      display: flex;
      align-items: center;
      margin-left: $gvds-spacing--150;
    }
  }

  .info-card-content {
    margin-left: calc($info-card-icon-dimension + $gvds-spacing--150);
    font-size: 14px;
    font-weight: 400;

    &.no-icon {
      margin-left: $gvds-spacing--150;
    }

    ul, ol {
      padding-left: 20px;
      margin-bottom: 0;
    }

    ul li ul {
      padding-left: 18px;
    }

    .info-card-content-footer--container {
      padding-top: $gvds-spacing--100;
    }


  }
}
