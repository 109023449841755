// region Reusable classes
@mixin table-data__main-text {
  color: $color--gray10;
  font-size: 14px;
  font-weight: 500;
}

@mixin table-data__description-text {
  color: $color--gray9;
  font-size: 14px;
  font-weight: 400;
}

@mixin table-data__truncate-to-max-lines {
  // from https://stackoverflow.com/a/19049457
  overflow: hidden;
  text-overflow: ellipsis;
  display: -webkit-box;
  -webkit-line-clamp: 4;
  -webkit-box-orient: vertical;
}

.table-data__truncate-to-max-lines {
  @include table-data__truncate-to-max-lines;
}

.table-data__subtext {
  font-size: 12px;
  color: $color--gray9;
}

// endregion Reusable classes

.gvds-table-container {
  max-width: 100%;
}

$table-background-color: $color-white;
$table-scrollable-indicator-shadow-color: $gvds-color--tealLight;

.gvds-table-view-control__container {
  background-color: $table-background-color;

  .gvds-table-ctrl--container {
    padding: $gvds-spacing--100;
    margin-bottom: 0;
  }
}

.gvds-table-viewport {
  overflow-x: auto;

  // ref https://javascript.plainenglish.io/how-to-create-horizontal-vertical-scroll-shadows-26aa7a7f00a0,
  //     https://github.com/mjchang/medium/blob/master/scroll-shadow/src/HorizontalCSSScrollShadow.js
  background-image: linear-gradient(to right, $table-background-color, $table-background-color),
  linear-gradient(to right, $table-background-color, $table-background-color),
  linear-gradient(to right, $table-scrollable-indicator-shadow-color, transparent),
  linear-gradient(to left, $table-scrollable-indicator-shadow-color, transparent);

  background-position: left center, right center, left center, right center;
  background-repeat: no-repeat;
  background-color: $table-background-color;
  background-size: 20px 100%, 20px 100%, 10px 100%, 10px 100%;
  background-attachment: local, local, scroll, scroll;
}

.gvds-table-container table.table {
  margin-bottom: 0;

  thead, tbody {
    & > tr > *:first-child {
      padding-left: $gvds-spacing--150;
    }
  }

  thead {
  }

  thead th {
    padding: $gvds-spacing--100 $gvds-spacing--50;
    border-radius: 4px;
    vertical-align: middle;

    &:hover {
      background-color: $gvds-color--alpha;

      .gvds-table-cell__sorting-icon {
        visibility: visible;
      }
    }

    .gvds-table-header__header-cell {
      display: flex;
      align-items: center;
    }

    .gvds-table-header__header-cell-text {
      // from https://stackoverflow.com/a/19049457
      overflow: hidden;
      text-overflow: ellipsis;
      display: -webkit-box;
      -webkit-line-clamp: 2;
      -webkit-box-orient: vertical;
    }

    .gvds-table-cell__sorting-icon {
      visibility: hidden;
      margin-left: auto;

      &.active {
        visibility: visible;
      }
    }
  }

  .gvds-table-cell__row-number-header {
    width: 40px;
    min-width: 40px;
  }

  .gvds-table-cell__actions-header {
    padding-left: 10px;
  }

  .gvds-table-cell__multi-select {
    width: 40px;
    min-width: 40px;
  }

  .gvds-table-cell__row-number {
    text-align: right;
    margin-right: $gvds-spacing--100;

    &:after {
      content: ".";
    }
  }

  .gvds-table-cell__actions-container {
    display: flex;
    flex-wrap: nowrap;
  }

  tbody {
    & > tr:hover {
      background-color: $color--gray3;

      &.is-clickable {
      }
    }

    & > tr.is-selected {
      background-color: $gvds-color--alpha;
    }

    & > tr.is-clickable {
      cursor: pointer;
    }

    td {
      vertical-align: middle;
      padding: $gvds-spacing--100 $gvds-spacing--50;
    }
  }
}

// region Borders
.gvds-table-container {
  .gvds-table-view-control__container {
    border-radius: 4px 4px 0 0;
    border-top: 1px solid $gvds-border-color--table;
    border-left: 1px solid $gvds-border-color--table;
    border-right: 1px solid $gvds-border-color--table;
  }

  .gvds-table-ctrl--bulk-action-bar__container {
    border-top: 1px solid $gvds-border-color--table;
  }

  .gvds-table-viewport {
    border-radius: 4px;
    border: 1px solid $gvds-border-color--table;
  }

  .gvds-table-view-control__container ~ .gvds-table-viewport {
    border-radius: 0 0 4px 4px;
  }

  tbody tr:last-child td {
    border-bottom: 0;
  }
}

// within accordion
.accordion .gvds-accordion-header ~ div .accordion-body .gvds-table-container .gvds-table-viewport {
  border-radius: 0;
}

.accordion:last-child .gvds-accordion-header ~ div .accordion-body .gvds-table-container .gvds-table-viewport {
  border-radius: 0 0 4px 4px;
}

// endregion Borders

// region Expandable Rows


.action-row-in-expanded-row {
  background-color: $color-white;
  padding: $gvds-spacing--100;
  border: 1px solid $color--gray4;
  border-top: none;
}

// endregion