.report-config {
  border: 1px solid $color--gray4;
  border-radius: 4px;
  background-color: $color-white;

  .report-config-options {
    padding: $gvds-spacing--150 $gvds-spacing--150 0 $gvds-spacing--150;

    .report-config-risk-category-option {
      display: flex;
      margin-bottom: $gvds-spacing--75;

      .report-config-risk-category-name {
        line-height: 24px;
      }
    }

    .gvds-single-select__option {
      display: flex;
      align-items: center;
    }

    .gvds-single-select__single-value {
      display: flex;
      align-items: center;
    }

  }

  .report-config-section {
    border-top: 1px solid $color--gray4;
  }
}

.report-config-footer {
  padding: $gvds-spacing--100;
  border-top: 1px solid $color--gray4;

  display: flex;
  flex-direction: row;
  justify-content: flex-end;
  align-items: center;

  .report-config-footer__error-msg {
    @include gvds-text--caption;
    color: $gvds-color--error;
    margin-right: $gvds-spacing--200;
  }
}

.report-download-area {
  flex: 33.3% 1 1;
  max-width: 425px;

  border-radius: 4px;
  box-shadow: 0 2px 5px 1px $card-shadow;
  background-color: $color-white;
  padding: $gvds-spacing--200 $gvds-spacing--250;

  display: flex;
  flex-direction: column;

  &:not(:first-child) {
    margin-left: 1.5em;
  }

  .report-download-extra-config {
    border-left: 3px solid $color--gray4;
    padding-left: $gvds-spacing--150;

    margin-bottom: $gvds-spacing--250;
  }
}
