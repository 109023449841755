$banner-icon-dimension: 24px;

.gvds-banner {
  padding: $gvds-spacing--200;
  border: none;
  color: $color--gray10;
  border-radius: $gvds-spacing--50;
  margin-bottom: $gvds-spacing--100;
  display: flex;
  flex-direction: row;
  gap: $gvds-spacing--200;

  .banner-icon__container {
    display: flex;

    svg {
      width: $banner-icon-dimension;
      height: $banner-icon-dimension;
    }
  }

  .banner-content {
    font-size: 14px;
    font-weight: 400;
    align-content: center;

    .banner-heading {
      font-size: 14px;
      font-weight: 600;
      margin-bottom: $gvds-spacing--50;
    }

    ul, ol {
      padding-left: 20px;
      margin-bottom: 0;
    }

    ul li ul {
      padding-left: 18px;
    }

    .banner-content-footer--container {
      padding-top: $gvds-spacing--100;
    }
  }
}

.gvds-banner-error {
  background: $color-banner-error;

  .banner-icon__container {
    svg {
      color: $gvds-color--error;
    }
  }
}

.gvds-banner-success {
  background: $color-banner-success;

  .banner-icon__container {
    svg {
      color: $gvds-color--success;
    }
  }
}

.gvds-banner-warning {
  background: $color-banner-warning;

  .banner-icon__container {
    svg {
      color: $gvds-color--warning;
    }
  }
}

.gvds-banner-informational {
  background: $color-banner-informational;

  .banner-icon__container {
    svg {
      color: $gvds-color--info;
    }
  }
}

.gvds-banner .gvds-banner-footer-button {
  @extend .gvds-text-button;

  color: $color--gray10;
  text-decoration: underline;

  .gvds-icon-container {
    transform: translateX(-4px);
    transition: all;
  }

  &:hover {
    text-decoration: none;
    color: $color--gray10;

    .gvds-icon-container {
      transform: translateX(-2px);
    }
  }

  &:focus {
    color: $color--gray10;
  }
}
