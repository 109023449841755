.gvds-tag {
  display: inline-flex;
  align-items: center;

  padding: $gvds-spacing--50 $gvds-spacing--100;
  border-radius: 4px;
  gap: $gvds-spacing--50;

  @include gvds-text--label;
  color: $color--gray10;
  margin-right: $gvds-spacing--100;
  margin-bottom: $gvds-spacing--75;

  .gvds-icon-container {
    margin-right: $gvds-spacing--50;
  }

  &.tag-- {
    &celeste {
      background-color: $gvds-accent-color--celeste;
    }

    &lime {
      background-color: $gvds-accent-color--lime;
    }

    &lavender {
      background-color: $gvds-accent-color--lavender;
    }
  }

}

table .gvds-tag {
  margin-bottom: 0;
}

.form-check-label .gvds-tag {
  margin-bottom: 0;
  margin-top: -3px;
}