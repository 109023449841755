$module-nav-bg-color-on-hover: rgba(0, 145, 154, 0.12);
$module-nav-text-color-on-active: #2f958f;
$margin-right-for-right-side-button: $gvds-spacing--150;
$module-nav-scrollable-indicator-shadow-color: darken($gvds-color--alpha, 30%);

.gvds-side-nav {
  width: $side-nav-width;
  min-width: $side-nav-width;
  background-color: $side-nav-bg-color;
  padding-top: 0;
  padding-bottom: 0;

  max-height: 100vh;

  display: flex;
  flex-direction: column;

  -webkit-transition: all 0.3s ease-in-out;
  -moz-transition: all 0.3s ease-in-out;
  -o-transition: all 0.3s ease-in-out;
  transition: all 0.3s ease-in-out;

  -ms-overflow-style: none; /* IE and Edge */
  scrollbar-width: none; /* Firefox */

  &::-webkit-scrollbar {
    display: none;
  }

  .gvds-side-nav__content_container {
    width: $side-nav-width-usable;
    min-width: $side-nav-width-usable;

    display: flex;
    flex-direction: column;
    height: 100%;
  }
}

.gvds-side-nav--top-and-module-container {
  display: flex;
  flex-direction: column;
  flex-grow: 1;
  overflow-x: hidden;
}

// region Side Nav minimised
.gvds-side-nav.minimised {
  width: $side-nav-width-minimised;
  min-width: $side-nav-width-minimised;
  overflow-x: clip;

  .gvds-side-nav__content_container {
    width: $side-nav-width-minimised;
    min-width: $side-nav-width-minimised;
  }

  .gvds-side-nav-top {
    padding-left: $module-nav-padding;

    .logo {
      img {
        width: 24px;
        height: 24px;
        margin-left: 0;
      }
    }
  }

  .gvds-module-nav {
    padding-left: $module-nav-padding;
  }

  .gvds-side-nav-bottom {
    flex-direction: column;

    border-top: none;
    padding-left: 0;
    padding-right: 0;
    padding-bottom: $gvds-spacing--150;

    .profile--dropdown-toggle-container {
      max-width: $side-nav-width-minimised;
    }

    .profile--container {
      padding-top: $gvds-spacing--100;
      border-top: 1px solid $color--gray4;
    }
  }
}

// endregion

// region Side Nav Top
.gvds-side-nav-top {
  display: flex;
  flex-direction: row;
  align-items: center;

  a {
    text-decoration: none;
    line-height: 125%;
  }

  .logo {
    height: calc($top-nav-height - 2 * $gvds-spacing--100);
    margin: $gvds-spacing--100 $gvds-spacing--50 $gvds-spacing--100 $gvds-spacing--100;
    padding: $gvds-spacing--50 $gvds-spacing--100 $gvds-spacing--50 0;
    cursor: pointer;

    font-size: 16px;
    color: $gvds-color--tealSolid;
    font-weight: 600;

    border-radius: 4px;

    img {
      margin-right: $gvds-spacing--100;
    }

    &:hover {
      background-color: $module-nav-bg-color-on-hover;
    }
  }

  .expand-icon-container {
    @include gvds-shadow--large;

    position: absolute;
    right: 0;
    top: 16px;
    background-color: $color--gray2;

    $border-radius: 24px;
    border-top-left-radius: $border-radius;
    border-bottom-left-radius: $border-radius;
  }

  .side-nav-control {
    margin-right: $margin-right-for-right-side-button;

    $side-nav-control-size: 24px + 2 * $gvds-spacing--50;
    width: $side-nav-control-size;
    height: $side-nav-control-size;

    &:hover {
      @include gvds-shadow--small;
      background-color: $gvds-color--alpha;
    }
  }
}

// endregion

// region Module Nav
.gvds-module-nav {
  padding-left: $module-nav-padding;
  padding-right: $module-nav-padding;

  flex-grow: 1;
  display: flex;
  flex-direction: column;

  overflow-y: scroll;
  overflow-x: clip;

  -ms-overflow-style: none; /* IE and Edge */
  scrollbar-width: none; /* Firefox */

  &::-webkit-scrollbar {
    display: none;
  }

  // ref https://javascript.plainenglish.io/how-to-create-horizontal-vertical-scroll-shadows-26aa7a7f00a0,
  //     https://github.com/mjchang/medium/blob/master/scroll-shadow/src/VerticalCSSScrollShadow.js
  background-image: linear-gradient(to top, $side-nav-bg-color, $side-nav-bg-color),
  linear-gradient(to top, $side-nav-bg-color, $side-nav-bg-color),
  linear-gradient(to top, $module-nav-scrollable-indicator-shadow-color, transparent),
  linear-gradient(to bottom, $module-nav-scrollable-indicator-shadow-color, transparent);
  background-position: bottom center, top center, bottom center, top center;
  background-repeat: no-repeat;
  background-size: 100% 20px, 100% 20px, 100% 10px, 100% 10px;
  background-attachment: local, local, scroll, scroll;

  .gvds-module-nav__selectable {
    display: flex;
    flex-direction: row;
    align-items: center;
    flex-grow: 1;
    border-radius: 4px;
  }

  .gvds-module-nav__l1-row {
    color: $text-on-nav-color;
    cursor: pointer;
    font-size: 14px;
    padding: $gvds-spacing--50 0;
    font-weight: 500;

    display: flex;
    flex-direction: row;

    a {
      text-decoration: none;
      color: $text-on-nav-color;
      line-height: 125%;
    }

    &.is-expanded {
      margin-bottom: 0;
    }

    .gvds-module-nav__selectable {
      padding: $gvds-spacing--50 $module-nav--l1-selectable-horizontal-padding;
    }

    &:hover .gvds-module-nav__selectable {
      background-color: $module-nav-bg-color-on-hover;
    }

    .selected {
      color: $module-nav-text-color-on-active;
      background-color: $module-nav-bg-color-on-hover;
      font-weight: 600;
    }

    .nav-icon {
      width: 1.8em;
      display: flex;
      align-items: center;
      justify-content: center;
      margin-right: $gvds-spacing--100;
    }
  }

  .gvds-module-nav__l2-container {
    border-left: 2px solid $color--gray4;
    margin-left: $gvds-spacing--250;

    max-height: 0;
    overflow: clip;
    transition: max-height 0.1s ease-in-out;

    &.expanded {
      max-height: 100vh;
      overflow: visible;
    }

    .gvds-module-nav__l2-row {
      color: $text-on-nav-color;
      cursor: pointer;
      font-size: 14px;
      padding: $gvds-spacing--25 0;
      padding-left: $gvds-spacing--250;
      font-weight: 500;

      a {
        text-decoration: none;
        color: $text-on-nav-color;
        line-height: 125%;
      }

      .gvds-module-nav__selectable {
        padding: $gvds-spacing--100 $gvds-spacing--100;
      }

      &:hover .gvds-module-nav__selectable {
        background-color: $module-nav-bg-color-on-hover;

        &.disabled {
          background-color: darken($module-nav-bg-color-on-hover, 20%);
        }
      }

      .selected {
        color: $module-nav-text-color-on-active;
        font-weight: 600;
        background-color: $module-nav-bg-color-on-hover;
      }
    }
  }
}

.gvds-system-toolbox-nav {
  @extend .gvds-module-nav;

  .gvds-module-nav__l1-row .gvds-module-nav__selectable {
    padding: $gvds-spacing--100;
  }
}

.gvds-side-nav--return-from-system-toolbox {
  text-align: left;
  color: $text-on-nav-color;
  display: flex;
  padding: 0;
  font-size: 14px;
  font-weight: 500;
  align-items: center;

  margin: $gvds-spacing--200 0 $gvds-spacing--150 $gvds-spacing--100;

  .back-icon {
    margin-right: $gvds-spacing--50;
  }
}

// endregion

// region Side Nav Bottom
$system-toolbox-width: 32px;
$profile-max-width: $side-nav-width-usable - $system-toolbox-width - ($module-nav-padding + $gvds-spacing--100) - $margin-right-for-right-side-button;

.gvds-side-nav-bottom {
  padding-top: $gvds-spacing--150;
  padding-left: $module-nav-padding + $gvds-spacing--100;
  padding-right: $margin-right-for-right-side-button;
  padding-bottom: $gvds-spacing--150;
  border-top: 1px solid $color--gray4;
  margin-top: auto;

  display: flex;
  flex-direction: row;
  align-items: center;
  width: 100%;

  .profile--dropdown-toggle-container {
    max-width: $profile-max-width;

    -webkit-transition: all 0.3s ease-in-out;
    -moz-transition: all 0.3s ease-in-out;
    -o-transition: all 0.3s ease-in-out;
    transition: all 0.3s ease-in-out;
  }

  .profile-dropdown-toggle {
    cursor: pointer;
    width: 100%;
  }

  .profile--container {
    display: flex;
    flex-direction: row;
    align-items: center;
  }

  .profile--icon {
    border: 2px solid white;
    background-color: #FFFFFF;
    height: $gvds-spacing--300;
    min-width: $gvds-spacing--300;
    border-radius: 50%;
    font-weight: 700;
    font-size: 10px;
    text-align: center;
    align-items: center;
    display: flex;
    justify-content: center;

    &:hover {
      border-color: $white;
    }

    &.accent1 {
      background-color: $gvds-accent-color--purple;
      color: $color-white;
    }

    &.accent2 {
      background-color: $gvds-accent-color--pink;
      color: $color-white;
    }

    &.accent3 {
      background-color: $gvds-accent-color--navy;
      color: $color-white;
    }

    &.accent4 {
      background-color: $gvds-accent-color--lavender;
      color: $color--gray10;
    }

    &.accent5 {
      background-color: $gvds-accent-color--celeste;
      color: $color--gray10;
    }

    &.accent6 {
      background-color: $gvds-accent-color--lime;
      color: $color--gray10;
    }
  }

  .profile--name {
    margin-left: $gvds-spacing--100;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
  }

  .profile-dropdown-menu {
    .dropdown-item {
      display: flex;
      flex-direction: row;
      align-items: center;

      .icon-container {
        margin-left: $gvds-spacing--200;
        display: flex;
        align-items: center;
      }
    }

    .logout-button {
      color: $gvds-color--error;
    }
  }

  .system-toolbox-trigger:hover, .system-toolbox-trigger.active {
    @include gvds-shadow--small;
    background-color: $gvds-color--alpha;
  }
}

// endregion