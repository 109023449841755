$dashboard-gvds-table-viewport-max-height: calc(80vh - 56px);

@mixin shared-truncated-legend-style {
  word-break: break-word;
  overflow: hidden;
  text-overflow: ellipsis;
  display: -webkit-box;
  -webkit-line-clamp: 2;
  -webkit-box-orient: vertical;
}

@mixin shared-chart-legend-container-style {
  display: flex;
  flex-direction: row;

  .chart-container {
    .x-axis-label {
      @include gvds-text--caption;

      color: $color--gray10;
      justify-self: center;
      margin-top: $gvds-spacing--200 * -1;
    }
  }

  .legend-container {
    margin-left: $gvds-spacing--300;
    margin-top: $gvds-spacing--250;
    max-width: 240px;

    &.expanded-view {
      margin-top: 65px;
    }

    .legend-label {
      @include gvds-text--label;
      @include shared-truncated-legend-style;
    }

    .legend-percentage {
      @include gvds-text--label;

      margin-left: $gvds-spacing--50;
    }
  }
}

@mixin chart-mini-view-box {
  background: $color-white;
  border: 1px solid $color--gray4;
  border-radius: 4px;
  padding: $gvds-spacing--200;

  .header-container {
    display: flex;
    flex-direction: row;
    align-items: center;

    .title-container {
      display: flex;
      flex-direction: row;
      align-items: center;
      margin-bottom: $gvds-spacing--25;
      gap: $gvds-spacing--50;

      .title {
        @include gvds-text--heading4;

      }
    }

    .subtitle-container {
      display: flex;
      flex-direction: row;
      flex-wrap: wrap;
      align-items: center;
      gap: $gvds-spacing--50;

      .subtitle {
        @include gvds-text--label;

        color: $color--gray9;
      }
    }
  }
}

@mixin expanded-chart-view-header__shared_style {
  display: flex;
  flex-direction: row;
  align-items: center;
  gap: $gvds-spacing--50;
}

// region Dashboard Shared styles
.dashboard--chart__popover-tooltip-container {
  display: grid;
  grid-template-columns: 1fr auto;
  column-gap: $gvds-spacing--50;
  row-gap: $gvds-spacing--50;

  .popover-tooltip-label {
    @include gvds-text--label;

    color: $color--gray9;
    margin-top: 1px;
  }

  .popover-tooltip-value {
    @include gvds-text--caption;

    color: $color--gray10;

    strong {
      font-weight: 700;
    }
  }
}

.dashboard--chart__popover-tooltip-table {
  td {
    @include gvds-text--caption;
    @include gvds-monospaced-number;

    color: $color--gray10;

    strong {
      font-weight: 700;
    }
  }

  td:not(:first-child) {
    padding-left: $gvds-spacing--50;
  }
}

.data-gaps-icon {
  margin-left: $gvds-spacing--50;

  svg {
    color: $gvds-color--warning;
  }
}

.chart-axis-label {
  @include gvds-text--label;

  color: $color--gray0;
  font-family: inherit;
}

.dashboard--sort-chart-data__single-select,
.dashboard--view-type__single-select {
  @include gvds-text--inputField;

  color: $color--gray10;
  margin-right: $gvds-spacing--150;
}

.dashboard--bar-chart-total-value {
  @include gvds-text--label;

  color: $color--gray9;
}

.dashboard-minified-header-control__dropdown {
  .other-action-dropdown-toggle {
    margin-left: 0;
  }
}

.dashboard__y-axis-tick {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: right;

  .y-axis-tick-value {
    @include gvds-text--label;

    color: $color--gray9;
    word-break: break-all;
    overflow: hidden;
    text-overflow: ellipsis;
    display: -webkit-box;
    -webkit-line-clamp: 1;
    -webkit-box-orient: vertical;
  }
}

.joined-bullet.dashboard-joined-bullet {
  $icon-size: 8px;

  font-size: 5px;
  margin: 0 $gvds-spacing--50;
  min-width: 5px;
  width: 5px;

  height: $icon-size;
  min-height: $icon-size;

  svg {
    width: $icon-size;
    height: $icon-size;
  }
}

.dashboard-separator-bullet {
  $icon-size: 8px;

  font-size: 5px;
  margin: 0 $gvds-spacing--100 0 $gvds-spacing--50;
  min-width: 5px;
  width: 5px;

  height: $icon-size;
  min-height: $icon-size;

  svg {
    width: $icon-size;
    height: $icon-size;
    color: var(--bullet-color);
  }
}

.dashboard-chart-header__action-buttons {
  display: flex;
  flex-direction: row;
  align-items: center;
  margin-left: auto;
}

// endregion

// region Dashboard Layout

.dashboard-content__container {
  overflow-y: auto;
  height: 100%;
  background-color: $color--gray2;
  padding: $gvds-spacing--300 $gvds-spacing--400;

  & > div:first-child {
    margin-top: 0;
  }

  .row {
    margin-bottom: $gvds-spacing--200;
  }

  .dashboard-widget--col {
    display: flex;
    flex-direction: column;
  }
}

// endregion

// region Dashboard Menu

.dashboard-menu__container {
  position: sticky;
  top: 0;
  z-index: 100;

  background-color: $color--gray0;
  border-bottom: 1px solid $color--gray4;

  padding-top: $gvds-spacing--75;
  padding-right: $gvds-spacing--300;
  padding-left: $gvds-spacing--300;
  padding-bottom: $gvds-spacing--75;
}

.dashboard-menu__content-row {
  display: flex;
  flex-direction: row;
  align-items: center;
}

.dashboard-menu__loading-spinner-container {
  width: 100%;
  height: 28px;

}

// endregion

// region Dashboard Selector

.dashboard-selector__trigger {
  @include gvds-text--caption;
  display: flex;
  flex-direction: row;
  align-items: center;
  cursor: pointer;
  color: $gvds-color--tealSolid;
  gap: $gvds-spacing--50;
  padding-left: $gvds-spacing--100;
  border-radius: 4px;
  margin-right: $gvds-spacing--100;

  &:hover {
    background-color: $gvds-color--alpha;
  }
}

.dashboard-selector__modal {
  margin: 0;

  .modal-content {
    border: 1px solid $color--gray3;
    border-radius: 4px;
    background-color: $color--gray0;
    min-width: 200px;
    @include gvds-shadow--large;
  }
}

.dashboard-selector__modal__dialog {
  --bs-modal-border-radius: 4px;
  min-width: 230px;
  width: min-content;
  margin: 0;
  transform: none;
}

.dashboard-selector__modal__backdrop {
  background-color: transparent;
}

.dashboard-selector__modal__option {
  @include gvds-text--inputField;
  display: flex;
  flex-direction: row;
  min-width: 230px;
  padding: $gvds-spacing--100 $gvds-spacing--150;
  align-items: center;
  cursor: pointer;
  white-space: nowrap;

  &:hover {
    background-color: $gvds-color--alpha;
  }

  &.selected {
    @include gvds-text--heading4;
  }
}

// endregion

// region Dashboard Filter Content
$dashboard-filter-input-height: 27.5px;
$dashboard-filter-selected-count-display-size: 24px;

.dashboard-filter__content__container {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  gap: $gvds-spacing--100;
  align-items: center;
  margin-top: $gvds-spacing--75;
  margin-bottom: $gvds-spacing--75
}

.dashboard-filter__text-display {
  @include gvds-text--inputField;
  margin-left: $gvds-spacing--50;
  line-height: $dashboard-filter-input-height;
  margin-right: $gvds-spacing--50;
}

.dashboard-filter__text-display-with-indicator {
  @extend .dashboard-filter__text-display;
  display: flex;
  align-items: center;
  gap: $gvds-spacing--150;

  .dashboard-filter__applied-filter-indicator {
    width: 8px;
    height: 8px;
    background-color: $gvds-color--tealSolid;
    border-radius: 50%;
  }
}

.dashboard-filter__dropdown-input__trigger {
  @include clickable;
  width: max-content;
  display: flex;
  flex-direction: row;
  align-content: center;
  height: 100%;

  border: 1px solid $color--gray4;
  padding: $gvds-spacing--50 $gvds-spacing--100 $gvds-spacing--50 $gvds-spacing--50;
  border-radius: 4px;

  .gvds-icon-container {
    height: $dashboard-filter-input-height;
  }
}

.dashboard-filter__filter-input__content {
  @extend .dashboard-filter__text-display;
  display: flex;
  flex-direction: row;
  gap: $gvds-spacing--50;
  align-items: center;
}

.dashboard-filter__filter-input__selected-count {
  @include gvds-text--label;
  padding: 1px;
  background-color: $gvds-color--alpha;
  color: $gvds-color--tealSolid;
  border-radius: $dashboard-filter-selected-count-display-size;
  height: $dashboard-filter-selected-count-display-size;
  width: $dashboard-filter-selected-count-display-size;
  text-align: center;
  align-content: center;
}

.dashboard-filter__months-grid {
  display: grid;
  grid-template-columns: repeat(3, 1fr);

  .months-row {
    display: grid;
    grid-template-columns: repeat(3, 1fr);
    margin-bottom: $gvds-spacing--100;
  }

  .month-button {
    @include gvds-form__shared-calendar-content-style;
    color: $color--gray10;

    &:hover {
      border-color: $gvds-color--tealSolid;
    }

    &.selected {
      background: $gvds-color--tealSolid;
      color: $color--gray0;
      border-color: $gvds-color--tealSolid;
    }

    &.disabled {
      background: $color--gray2;
      color: $color--gray4;
      border-color: $color--gray4;
    }
  }
}

// endregion

// region Dashboard Filter - Continuous Time Period Control
.dashboard-filter__continuous-time-period-control__display {
  display: flex;
  flex-direction: row;
}

.dashboard-filter__continuous-time-period-control__trigger {
  @extend .dashboard-filter__dropdown-input__trigger;
  border-top-right-radius: 0;
  border-bottom-right-radius: 0;
  border-right: none;
}

.dashboard-filter__continuous-time-period-control__dropdown__menu {
  padding: $gvds-spacing--300;
  border-radius: 4px;

  .dashboard-filter__continuous-time-period-control__dropdown__heading {
    display: flex;
    flex-direction: row;
    margin-bottom: $gvds-spacing--150;
    align-items: center;

    .gvds-button {
      padding: $gvds-spacing--50;

      .gvds-button__icon {
        margin-right: 0
      }
    }
  }

  .dashboard-filter__continuous-time-period-control__dropdown__content {
    min-width: 550px;
  }

  .dashboard-filter__continuous-time-period-control__dropdown__footer {
    display: flex;
    margin-top: $gvds-spacing--300;
  }
}

.dashboard-filter__continuous-time-period-control__quickfill__container {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  gap: $gvds-spacing--100;
  align-items: center;
  margin-top: $gvds-spacing--150;
  margin-bottom: $gvds-spacing--150;

  .quickfill-label {
    @include gvds-text--inputField;
    color: $color--gray10;
    margin-right: $gvds-spacing--100;
  }

  .quickfill-button {
    @include gvds-text--inputField;
    color: $color--gray10;
    padding-top: $gvds-spacing--75;
    padding-right: $gvds-spacing--150;
    padding-bottom: $gvds-spacing--75;
    padding-left: $gvds-spacing--150;
    border: 1px solid $color--gray4;
    border-radius: 8px;

    &.active {
      color: $gvds-color--tealSolid;
      border: 1px solid $gvds-color--tealSolid;
    }
  }
}

// endregion

//region Dashboard Filter - Continuous Time Month Inclusion Control

.dashboard-filter__continuous-time-month-inclusion-control__trigger {
  @extend .dashboard-filter__dropdown-input__trigger;
  border-top-left-radius: 0;
  border-bottom-left-radius: 0;
  min-width: 110px;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
}

.dashboard-filter__continuous-time-month-inclusion-control__dropdown__menu {
  $year-tabs-width: 140px;
  $dropdown-menu-padding: $gvds-spacing--300;
  $content-height: 272px;

  padding: $dropdown-menu-padding;
  border-radius: 4px;

  .dashboard-filter__continuous-time-month-inclusion-control__tabs__container {
    display: flex;
    flex-direction: row;
    border-radius: 4px;
    border: 1px solid $color--gray4;

    .dashboard-filter__continuous-time-month-inclusion-control__tabs {
      width: $year-tabs-width;
      margin-bottom: 0;
      border-bottom: 0;
      border-top-left-radius: 4px;
      border-bottom-left-radius: 4px;
      overflow-y: auto;
      height: $content-height;
      align-content: flex-start;


      .nav-item {
        @include gvds-text--formLabel;
        color: $color--gray10;
        background-color: $color--gray0;
        border-radius: 0;
        box-shadow: none;
        height: 44px;
        width: 100%;
        padding: 0;
        margin: 0;
        flex-shrink: 0;
        position: relative;

        .nav-link {
          background-color: $color--gray0;
          padding-top: $gvds-spacing--100;
          padding-bottom: $gvds-spacing--100;
          padding-left: $gvds-spacing--300;
          border-bottom: 1px solid $color--gray4;
          margin: 0;
          border-radius: inherit;
          height: inherit;
          width: 100%;
          text-align: left;
          border-left: 4px solid $color--gray2;
          position: relative;

          &.active {
            color: $color--gray10;
            border-left: 4px solid $gvds-color--tealSolid;
            box-shadow: none;
          }
        }
      }

      // nav and tab content vertical divider
      &::after {
        content: '';
        position: absolute;
        left: calc($year-tabs-width + $dropdown-menu-padding);
        width: 1px;
        height: $content-height;
        background-color: $color--gray4;
        z-index: 1;
      }
    }

    .nav-item:has(.active) {
      background-color: $color--gray0;
      position: relative;
      z-index: 2;
    }

    .tab-content {
      padding: $gvds-spacing--200;
      background-color: $color--gray0;
      border-radius: 0 4px 4px 0;
    }
  }

  .dashboard-filter__continuous-time-month-inclusion-control__action-button {
    display: flex;
    justify-content: center;
    border-top: 1px solid $color--gray3;
    margin-top: $gvds-spacing--100;
    padding-top: $gvds-spacing--200;
  }

  .dashboard-filter__continuous-time-month-inclusion-control__month-grid__action-button {
    display: flex;
    flex-direction: row;
    gap: $gvds-spacing--200;
    margin-bottom: $gvds-spacing--200;
  }


  .dashboard-filter__continuous-time-month-inclusion-control__dropdown__heading {
    @include gvds-text--heading4;
    display: flex;
    flex-direction: row;
    gap: $gvds-spacing--50;
    margin-bottom: $gvds-spacing--200;
    align-items: center;
  }


  .dashboard-filter__continuous-time-month-inclusion-control__dropdown__footer {
    display: flex;
    margin-top: $gvds-spacing--200;
  }
}

//endregion

// region Dashboard Filter - Metric Selector

.dashboard-filter__single-select__operational-metric {
  width: 160px;
}

// endregion

// region Dashboard Filter - Unit Selector

.dashboard-filter__single-select__unit {
  width: 160px;
}

// endregion

// region Dashboard Filter - Inventory Filter
.dashboard-filter__inventory-filter__trigger-display {
  @extend .dashboard-filter__text-display;
  display: flex;
  flex-direction: row;
  gap: $gvds-spacing--50;
  align-items: center;
}

.dashboard-filter__inventory-filter__dropdown__menu {
  width: 300px;
}

.dashboard-filter__inventory-filter__search-box__container {
  padding-right: $gvds-spacing--200;
  padding-left: $gvds-spacing--200;
}

.dashboard-filter__inventory-filter__action-button__container {
  display: flex;
  flex-direction: row;
  gap: $gvds-spacing--200;
  margin-top: $gvds-spacing--100;
  padding-right: $gvds-spacing--200;
  padding-left: $gvds-spacing--200;
  padding-bottom: $gvds-spacing--75;
  border-bottom: 1px solid $color--gray3;
}

.dashboard-filter__inventory-filter__options__container {
  max-height: 300px;
  overflow-y: auto;
  padding: $gvds-spacing--100 $gvds-spacing--200;
}

.dashboard-filter__inventory-filter__option {
  padding: $gvds-spacing--75 0;
}

.dashboard-filter__inventory-filter__option__parent-option {
  display: flex;
  align-items: center;
  margin-bottom: $gvds-spacing--100;

  .form-check {
    margin-bottom: 0;
  }

  .option-collapse-toggle {
    color: $color--gray10;
    padding: 0;
  }
}

.dashboard-filter__inventory-filter__option__child-options {
  border-left: 1px solid $color--gray3;
  margin-left: $gvds-spacing--100;
  padding-left: $gvds-spacing--200;
  margin-bottom: $gvds-spacing--100;

  .form-check {
    margin-bottom: $gvds-spacing--100;
  }
}

.dashboard-filter__inventory-filter__option-checkbox__container {
  @include clickable;
  flex-grow: 1;
}

// endregion

// region Dashboard Filter - Classification Filter
$chevron-separator-width: 6px;
$chevron-separator-border-width: 1px;

.dashboard-filter__classification-filter__container {
  display: flex;
  flex-direction: row;
}

.dashboard-filter__classification-group-filter__single-select {
  width: 160px;

  .gvds-single-select__control {
    border-right: none;
    border-top-right-radius: 0;
    border-bottom-right-radius: 0;
    //Currently needs !important for overriding default active border shadow when single select is focused or open,
    //since the active border shadow not go well with the chevron separator
    border-color: $color--gray4 !important;
    box-shadow: none !important;
  }
}

.dashboard-filter__classification-filter__sub-filter-separator {
  position: relative;
  width: $chevron-separator-width;
  align-self: stretch;
  clip-path: polygon(
                  0 0,
                  $chevron-separator-border-width 0,
                  100% 50%,
                  $chevron-separator-border-width 100%,
                  0 100%,
                  calc(100% - #{$chevron-separator-border-width}) 50%
  );

  background: $color--gray4;
}

.dashboard-filter__classification-value-filter__trigger {
  @extend .dashboard-filter__dropdown-input__trigger;
  border-left: none;
  border-top-left-radius: 0;
  border-bottom-left-radius: 0;
  margin-left: -$chevron-separator-width;
  padding-left: calc(#{$chevron-separator-width} * 2);
}

.dashboard-filter__classification-value-filter__dropdown-menu {
  width: 300px;
}

.dashboard-filter__classification-value-filter__all-options-container {
  max-height: 300px;
  overflow-y: auto;
}

.dashboard-filter__classification-value-filter__option-checkbox-container {
  padding: $gvds-spacing--100 $gvds-spacing--150;
}

// endregion

// region Dashboard Filter - Comparison Time Filter
$year-picker-width: 90px;

.dashboard-filter__comparison-time-filter__container {
  display: flex;
  flex-direction: row;

  .react-datepicker-wrapper {
    width: $year-picker-width;
  }

  .react-datepicker__input-container {
    width: $year-picker-width;
    min-width: $year-picker-width;
  }

  .dashboard-filter__comparison-time-filter__year-picker--left {
    border-top-right-radius: 0;
    border-bottom-right-radius: 0;
    border-right: none;
  }

  .dashboard-filter__comparison-time-filter__year-picker--right {
    border-radius: 0;
    border-right: none
  }
}

.dashboard-filter__comparison-time-filter__trigger {
  @extend .dashboard-filter__dropdown-input__trigger;
  border-top-left-radius: 0;
  border-bottom-left-radius: 0;
  min-width: 110px;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
}

.dashboard-filter__comparison-time-filter__dropdown-menu {
  padding: $gvds-spacing--200;
}


.dashboard-filter__comparison-time-filter__dropdown-heading {
  @include gvds-text--heading4;
  display: flex;
  flex-direction: row;
  gap: $gvds-spacing--50;
  margin-bottom: $gvds-spacing--100;
  align-items: center;
}

.dashboard-filter__comparison-time-filter__month-grid__action-button {
  display: flex;
  flex-direction: row;
  gap: $gvds-spacing--200;
  margin-bottom: $gvds-spacing--100;
}

.dashboard-filter__comparison-time-filter__dropdown-footer {
  display: flex;
  margin-top: $gvds-spacing--200;

  + .dashboard-filter__comparison-time-filter__dropdown-footer {
    margin-top: 0;
  }
}

// endregion

.dashboard-section-header {
  margin-top: $gvds-spacing--200;
  margin-bottom: $gvds-spacing--150;
  gap: $gvds-spacing--75;

  display: flex;
  align-items: center;
}

// region Collection Summary Cards

.collection-summary-cards__container {
  border-radius: 4px;
  background-color: $color--gray0;
  border: 1px solid $color--gray4;
  padding: $gvds-spacing--200;
  align-content: center;

  min-height: 360px;
  height: 100%;
}

.collection-summary-cards__grid {
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  grid-template-rows: repeat(3, auto);
  grid-gap: 1px;
  color: $color--gray10;
  background-color: $color--gray3;

  height: 100%;
}

.collection-summary-one-card__container {
  display: flex;
  flex-direction: column;
  background-color: $color--gray0;
  padding-bottom: $gvds-spacing--250;
  padding-top: $gvds-spacing--200;
  min-height: 120px;

  &:nth-child(-n+2) {
    margin-top: -$gvds-spacing--200;
  }

  &:nth-last-child(-n+2) {
    margin-bottom: -$gvds-spacing--200;
  }

  &:nth-child(even) {
    padding-left: $gvds-spacing--200;
  }

  &:nth-child(odd) {
    padding-right: $gvds-spacing--200;
  }

  .collection-summary-one-card__title {
    display: flex;
    flex-direction: row;
    align-items: center;
    gap: $gvds-spacing--50;
    margin-bottom: $gvds-spacing--100;
    min-height: 35px;
  }

  .collection-summary-one-card__value {
    display: flex;
    flex-direction: row;
    align-items: center;
    gap: $gvds-spacing--75;
    margin-bottom: $gvds-spacing--100;
  }

  .collection-summary-one-card__number {
    @include gvds-text--heading3;
    max-width: 145px;
    overflow: hidden;
    text-overflow: ellipsis;
  }

  .collection-summary-one-card__value__unit {
    @include gvds-text--label;
    padding-right: $gvds-spacing--200;
  }

  .collection-summary-one-card__change {
    display: flex;
    flex-direction: row;
    align-items: center;
    width: max-content;
    padding-right: $gvds-spacing--50;
    padding-left: $gvds-spacing--50;
    min-height: 26px;

    .gvds-icon-container {
      margin-left: -$gvds-spacing--100;
    }

    &:hover {
      cursor: default;
    }
  }
}

// endregion

// region Main Summary Card

.main-summary-card__container {
  display: flex;
  flex-direction: column;
  background-color: $color--gray0;
  padding: $gvds-spacing--200 $gvds-spacing--200 $gvds-spacing--250;
  border: 1px solid $color--gray4;
  border-radius: 4px;
  margin-bottom: $gvds-spacing--200;
  min-height: 86px;
  height: min-content;


  .main-summary-card__title {
    display: flex;
    flex-direction: row;
    align-items: center;
    gap: $gvds-spacing--50;
    margin-bottom: $gvds-spacing--100;
    min-height: 26px;
  }

  .main-summary-card__value {
    display: flex;
    flex-direction: row;
    align-items: center;
    gap: $gvds-spacing--75;
    margin-bottom: $gvds-spacing--100;
  }

  .main-summary-card__number {
    @include gvds-text--heading2;
    max-width: 250px;
  }

  .main-summary-card__value__unit {
    @include gvds-text--label;
    padding-right: $gvds-spacing--200;
  }

  .main-summary-card__change {
    margin-left: auto;
    display: flex;
    flex-direction: row;
    align-items: center;
    width: max-content;
    padding-right: $gvds-spacing--50;
    padding-left: $gvds-spacing--50;
    min-height: 26px;

    .gvds-icon-container {
      margin-left: -$gvds-spacing--100;
    }

    &:hover {
      cursor: default;
    }
  }
}

// endregion

// region Dashboard Expanded View Modal

.dashboard--expanded-view__modal {
  .modal-header {
    padding: $gvds-spacing--300;

    .modal-title {
      flex-grow: 1;
    }
  }
}

.dashboard--expanded-view__footer-container {
  width: 100%;
  padding: $gvds-spacing--100 0;
}

.dashboard--expanded-view__table-container {
  width: 100%;

  .gvds-table-container .gvds-table-viewport {
    th {
      @include gvds-text--heading4;

      background-color: $color--gray3;
      padding: $gvds-spacing--100 $gvds-spacing--150;
      white-space: nowrap;
      border-radius: 0;

      &:hover {
        background-color: $color--gray3;
      }
    }

    td:first-child {
      @include gvds-text--heading4;

      padding: $gvds-spacing--100 $gvds-spacing--150;
    }

    td {
      @include gvds-text--body;
      @include gvds-monospaced-number;

      padding: $gvds-spacing--100 $gvds-spacing--150;
      white-space: nowrap;
    }

    tr:nth-child(even) {
      background-color: $color--gray2;
    }

    tr {
      &:hover {
        background-color: $color--gray3;
      }
    }
  }
}

// endregion


// region Classification Donut Chart
.dashboard--classification-donut-chart__mini-view-box {
  @include chart-mini-view-box;

  display: flex;
  flex-direction: column;
  flex-grow: 1;

  .content-container {
    display: flex;
    flex-direction: row;
    height: 100%;
    margin: $gvds-spacing--200 0;

    .chart {
      flex-shrink: 0;
      width: 300px;
      height: 150px;
    }

    .legend {
      .legend-label {
        @include gvds-text--label;

        word-break: break-word;
        overflow: hidden;
        text-overflow: ellipsis;
        display: -webkit-box;
        -webkit-line-clamp: 2;
        -webkit-box-orient: vertical;
      }

      .legend-percentage {
        @include gvds-text--label;

        margin-left: $gvds-spacing--50;
      }
    }
  }

  .footer-container {
    display: flex;
    flex-direction: row;
    align-items: center;

    .footer-label {
      @include gvds-text--label;

      color: $color--gray9;
      margin-right: $gvds-spacing--50;
    }
  }
}

.dashboard--classification-donut-chart__chart-popover {
  display: flex;

  .chart-popover-label {
    @include gvds-text--label;

    flex-grow: 1;
    margin-top: 1px;
    margin-right: 1px;
  }

  .chart-popover-value {
    @include gvds-text--caption;

    flex-shrink: 0;
  }
}

.dashboard--classification-donut-chart__expanded-chart-view__header-container {
  display: flex;
  flex-direction: row;
  align-items: center;

  .expanded-chart-view__title {
    @include gvds-text--heading2;

    display: flex;
    align-items: center;
    gap: $gvds-spacing--50;
  }

  .expanded-chart-view__subtitle-container {
    @include expanded-chart-view-header__shared_style;

    .subtitle {
      @include gvds-text--body;

      color: $color--gray9;
    }
  }
}

.dashboard--classification-donut-chart__expanded-chart-view__content-container {
  display: flex;
  flex-direction: row;
  justify-content: center;

  .gvds-table-container {
    width: 100%;
  }

  .expanded-chart-view__chart-legend-container {
    display: flex;
    flex-direction: row;
    width: 70%;

    .chart {
      flex-shrink: 0;
      width: 420px;
      height: 240px;
    }

    .legend {
      min-width: 240px;

      .legend-label {
        @include gvds-text--label;
        @include shared-truncated-legend-style;
      }

      .legend-percentage {
        @include gvds-text--label;

        margin-left: $gvds-spacing--50;
      }
    }
  }
}

.dashboard--classification-donut-chart__expanded-chart-view__footer-container {
  display: flex;

  .footer-label {
    @include gvds-text--body;

    color: $color--gray9;
    margin-right: $gvds-spacing--50;
  }

  .footer_value {
    @include gvds-text--heading4;
  }
}

// endregion

// region Summary Site Ranking Chart
.dashboard--summary-site-ranking__mini-view-box {
  @include chart-mini-view-box;

  .site-count-label {
    @include gvds-text--label;

    color: $color--gray9;
  }

  .content-container {
    display: flex;
    flex-direction: row;
    justify-self: center;
    margin: $gvds-spacing--200 0;
    width: 70%;

    .chart-legend-container {
      @include shared-chart-legend-container-style;
    }
  }
}

.dashboard--summary-site-ranking__expanded-chart-view__header-container {
  display: flex;
  flex-direction: row;
  align-items: center;

  .expanded-chart-view__title-container {
    @include expanded-chart-view-header__shared_style;

    .expanded-chart-view__title {
      @include gvds-text--heading2;
    }

    .site-count-label {
      @include gvds-text--body;

      color: $color--gray9;
    }
  }

  .expanded-chart-view__subtitle {
    @include expanded-chart-view-header__shared_style;
    @include gvds-text--body;

    flex-wrap: wrap;
    color: $color--gray9;
  }

  .expanded-chart-view__sort-select {
    @include gvds-text--body;

    margin-right: $gvds-spacing--100;
  }
}

.dashboard--summary-site-ranking__expanded-chart-view__content-container {
  display: flex;
  flex-direction: row;
  justify-content: center;

  .gvds-table-container {
    width: 100%;

    .gvds-table-viewport {
      max-height: $dashboard-gvds-table-viewport-max-height
    }
  }

  .chart-legend-container {
    @include shared-chart-legend-container-style;
  }
}

// endregion

// region Performance Timeline Chart
.dashboard--performance-timeline__mini-view-box {
  @include chart-mini-view-box;

  .content-container {
    display: flex;
    flex-direction: column;
    justify-self: center;
    margin: $gvds-spacing--200 0;
    width: 100%;

    .chart-minimap-container {
      margin-bottom: $gvds-spacing--100;
    }

    .chart-legend-container {
      @include shared-chart-legend-container-style;
    }
  }
}

.dashboard--performance-timeline__period-grouping-button-group {
  margin-right: $gvds-spacing--150;
}

.dashboard--performance-timeline__expanded-chart-view__header-container {
  display: flex;
  flex-direction: row;
  align-items: center;

  .expanded-chart-view__title-container {
    @include expanded-chart-view-header__shared_style;

    .expanded-chart-view__title {
      @include gvds-text--heading2;

      color: $color--gray10;
    }

    .site-count-label {
      @include gvds-text--body;

      color: $color--gray9;
    }
  }

  .expanded-chart-view__subtitle {
    @include expanded-chart-view-header__shared_style;
    @include gvds-text--body;

    flex-wrap: wrap;
    color: $color--gray9;
  }
}

.dashboard--performance-timeline__expanded-chart-view__content-container {
  display: flex;
  flex-direction: column;
  align-items: center;

  .gvds-table-container {
    width: 100%;

    .gvds-table-viewport {
      max-height: $dashboard-gvds-table-viewport-max-height
    }
  }

  .chart-minimap-container {
    margin-bottom: $gvds-spacing--100;
  }

  .chart-legend-container {
    @include shared-chart-legend-container-style;
  }
}

// endregion

// region Change Over Time Site Ranking Chart
.dashboard--change-over-time-site-ranking__mini-view-box {
  @include chart-mini-view-box;

  .header-container {
    .title-container {
      @media (max-width: $breakpoint-desktop) {
        flex-wrap: wrap;
      }

      .title {
        display: flex;
        align-items: center;
        gap: $gvds-spacing--50;

        @media (max-width: $breakpoint-desktop) {
          width: 100%;
        }
      }

      .title-joined-bullet {
        @media (max-width: $breakpoint-desktop) {
          margin-left: 0;
          margin-right: $gvds-spacing--50;
        }
      }
    }
  }

  .site-count-label {
    @include gvds-text--label;

    color: $color--gray9;
  }

  .content-container {
    display: flex;
    flex-direction: row;
    justify-self: center;
    margin: $gvds-spacing--200 0;
    width: 80%;

    .chart-container {
      width: 100%;
    }
  }
}

.dashboard--change-over-time-site-ranking__expanded-chart-view__header-container {
  display: flex;
  flex-direction: row;
  align-items: center;

  .expanded-chart-view__title-container {
    @include expanded-chart-view-header__shared_style;

    @media (max-width: $breakpoint-desktop) {
      flex-wrap: wrap;
    }

    .expanded-chart-view__title {
      @include gvds-text--heading2;

      color: $color--gray10;

      @media (max-width: $breakpoint-desktop) {
        width: 100%;
      }
    }

    .site-count-label {
      @include gvds-text--body;

      color: $color--gray9;
    }
  }

  .expanded-chart-view__subtitle {
    @include expanded-chart-view-header__shared_style;
    @include gvds-text--body;

    flex-wrap: wrap;
    color: $color--gray9;
  }
}

.dashboard--change-over-time-site-ranking__expanded-chart-view__content-container {
  display: flex;
  flex-direction: row;
  justify-content: center;

  .chart-container {
    width: 95%;
  }

  .gvds-table-container {
    width: 100%;
  }
}

// endregion

// region Performance Comparison Chart
@mixin performance-comparison-content-container {
  display: flex;
  flex-direction: row;
  justify-self: center;
}

.dashboard--performance-comparison__mini-view-box {
  @include chart-mini-view-box;

  margin-bottom: $gvds-spacing--100;

  .content-container {
    @include performance-comparison-content-container;

    width: 70%;

    .chart-container {
      width: 100%;
      height: 400px;
    }

    .legend-container {
      margin-top: $gvds-spacing--200;
    }
  }
}

.dashboard--performance-comparison__expanded-chart-view__header-container {
  display: flex;
  flex-direction: row;
  align-items: center;

  .expanded-chart-view__title-container {
    @include expanded-chart-view-header__shared_style;

    .expanded-chart-view__title {
      @include gvds-text--heading2;

      color: $color--gray10;
    }
  }

  .expanded-chart-view__subtitle {
    @include expanded-chart-view-header__shared_style;
    @include gvds-text--body;

    color: $color--gray9;
  }
}

.dashboard--performance-comparison__expanded-chart-view__content-container {
  @include performance-comparison-content-container;

  width: 100%;

  .chart-container {
    width: 94%;
    height: 400px;
  }

  .legend-container {
    margin-top: $gvds-spacing--200;
    width: 8%;
  }

  .gvds-table-container {
    width: 100%;
  }
}

// endregion
