//Override existing style
.react-datepicker__header {
  background-color: $color--gray2;
  border-bottom: none;
}

.react-datepicker__year-wrapper {
  max-width: 210px;

  .react-datepicker__year-text {
    @include gvds-form__shared-calendar-content-style;

    &:hover {
      background-color: $color-brand-green-light;
    }

    &--disabled {
      pointer-events: none;
    }

    &--selected {
      @include gvds-form__shared-calendar-content-style;
      color: $color-white;
      background-color: $color-brand-green-dark;

      &:hover {
        background-color: $color-brand-green-dark;
      }
    }
  }
}

//End of override

.gvds-form__single-yearpicker-input {
  @include gvds-form__shared-picker-input-style;
}

.gvds-form__start-end-yearpicker__container {
  @include gvds-form__start-end-picker-container-style;

  .start-year__container {
  }

  .end-year__container {
  }

  .invisible-year-label {
    visibility: hidden;
  }
}