$button-group-container-height: 36px;
$button-group-container-padding: $gvds-spacing--50;

.gvds-button-group__container {
  display: flex;
  align-items: center;

  height: $button-group-container-height;
  background-color: $color--gray2;
  border-radius: $gvds-spacing--50;
  padding: $button-group-container-padding;

  .gvds-button-group {
    @include gvds-button__shared-style;

    height: $button-group-container-height - ($button-group-container-padding * 2);
    background-color: $color--gray2;
    color: $color--gray6;
    border-radius: $gvds-spacing--50;
    cursor: pointer;

    &.selected {
      background-color: $gvds-color--tealSolid;
      color: $color--gray0;
    }

    &.disabled {
      color: $color--gray4;
      cursor: default;
    }
  }
}