$top-nav-height: 48px;
$top-nav-bg-color: #ffffff;
$top-nav-border-bottom-color: $color--gray4;
$side-nav-width: 240px;
$side-nav-icon-size: 24px;
$module-nav-padding: $gvds-spacing--100;
$module-nav--l1-selectable-horizontal-padding: $gvds-spacing--100;
$side-nav-width-minimised: calc($side-nav-icon-size + 2 * ($module-nav-padding + $module-nav--l1-selectable-horizontal-padding));
$side-nav-bg-color: $gvds-color--tealLight;
$text-on-nav-color: $color--gray10;
$empty-space-background-color: #ffffff;

$side-nav-width-usable: $side-nav-width;
