$breakpoint-large-desktop: 1440px;
$breakpoint-desktop: 1280px;
$breakpoint-small-desktop: 1024px;
$breakpoint-laptop: 720px;

.gvds-layout__spacer {
  flex-grow: 1;
}

$gvds-spacing--25: 2px;
$gvds-spacing--50: 4px;
$gvds-spacing--75: 6px;
$gvds-spacing--100: 8px;
$gvds-spacing--150: 12px;
$gvds-spacing--200: 16px;
$gvds-spacing--250: 20px;
$gvds-spacing--300: 24px;
$gvds-spacing--400: 32px;
$gvds-spacing--500: 40px;
$gvds-spacing--600: 48px;

// see _variables.scss
$gvds-z-index--sticky-page-container: 200;
$gvds-z-index--portalling-target: 999999;
$gvds-z-index--dropdown: 999999;
$gvds-z-index--form-in-modal: 999999;

// (default) offcanvas i.e. file storage: 1045
$gvds-z-index--modal-backdrop: 1054;
$gvds-z-index--modal: 1055;
$gvds-z-index--toast: 1058;
$gvds-z-index--floating-assistant: 1068;
// (default) popover: 1070
// (default) tooltip: 1080

$gvds-z-index--workspace-nav-selector-backdrop: 300;
$gvds-z-index--workspace-nav-selector-modal: 1040;

