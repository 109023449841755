.gvds-link {
  color: $gvds-color--tealSolid;
  align-items: center;

  &:visited {
    color: $gvds-color--tealSolid;
  }

  &:hover {
    text-decoration: underline;
    color: $gvds-color--tealSolid;
  }
}

.gvds-link.gvds-link__media-title {
  @include gvds-text--link;
  text-align: left;

  & ~ & {
    margin-top: $gvds-spacing--100;
  }

}
